import PlacesInput from 'components/global/PlacesInput'
import ClickToAllowPopup from 'components/popups/ClickToAllowPopup';
import HowToAllowPerPopup from 'components/popups/HowToAllowPerPopup';
import React, { memo, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const Search = ({ 
    setLocationType , locationType , setDistance , refetch ,
    setLocation , isRefetching , isLoading , distance
}) => {
    const [showClickToAllowPopup , setShowClickToAllowPopup] = useState(false);
    const [showHowToAllowPopup , setShowHowToAllowPopup] = useState(false);


    const setLocationState = (position) => {
        const userLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        setLocation(userLatLng);
    }

    const resetLocation = () => {
        setLocation({
            lat: '',
            lng: '',
        });
    }

    const handleLocationTypeChange = async (e) => {
        try {
            if(e.target.value === 'nearby'){
                const result = await navigator.permissions.query({ name: 'geolocation' });
                if(result.state === 'granted'){
                    setLocationType(e.target.value);
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocationState(position)
                        },
                        (error) => {
                            resetLocation();
                            setLocationType('search')
                        }
                    );
                }else if (result.state === 'prompt') {
                    setShowClickToAllowPopup(true);
                    setLocationType(e.target.value);
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocationState(position);
                            setShowClickToAllowPopup(false);
                        },
                        (error) => {
                            setShowClickToAllowPopup(false);
                            resetLocation();
                            setLocationType('search')
                        }
                    );
                } else if (result.state === 'denied') {
                    setShowHowToAllowPopup(true);
                    resetLocation();
                    setLocationType('search')
                }
            }else {
                setLocationType(e.target.value);
                resetLocation();

            }
        } catch (error) {
            toast.error('Geolocation is not supported by this browser.');
        }
    }


    return (
        <div>
            <div className='flex sm:items-center sm:gap-8 gap-3 sm:flex-row flex-col'>
                <div className='flex items-center gap-2'>
                    <input 
                    type="radio" 
                    name="locationType" 
                    id="locationTypeSearch" 
                    value='search'
                    checked={locationType === 'search'}
                    onChange={handleLocationTypeChange}
                    />
                    <label
                    htmlFor='locationTypeSearch' 
                    className='font-semibold text-gray-600'
                    >
                        Seach Location
                    </label>
                </div>
                <div 
                className='flex items-center gap-2'
                >
                    <input 
                    type="radio" 
                    name="locationType" 
                    id="locationTypeNearby"
                    value='nearby'
                    checked={locationType === 'nearby'}
                    onChange={handleLocationTypeChange}
                    />
                    <label 
                    className='font-semibold text-gray-600'
                    htmlFor='locationTypeNearby'
                    >
                        Use Nearby Location
                    </label>
                </div>
            </div>
            <form 
            className='flex gap-4 shadow-bg p-4 mt-4 md:flex-row flex-col'
            onSubmit={e => {
                e.preventDefault();
                refetch();
            }}
            >
                <div className='flex-[0.45]'>
                    <PlacesInput 
                    label='Your Location'
                    setLocation={setLocation}
                    disabled={locationType === 'nearby'}
                    />
                </div>
                <div className='flex-[0.45] flex flex-col gap-1.5'>
                    <label className='font-semibold text-gray-600'>
                        Distance Under
                    </label>
                    <select
                    className='input'
                    onChange={e => setDistance(e.target.value)}
                    value={distance}
                    >
                        {
                            [5,10,15,20,50,100,200].map(item => (
                                <option key={item} value={item}>
                                    {item} KM
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className='flex-[0.1] flex items-end justify-end h-full'>
                    <div className='h-[80px] flex items-end justify-end'>
                        <button 
                        className="btn-primary py-2 px-6 w-full"
                        disabled={isLoading || isRefetching}
                        >
                            {
                                isLoading || isRefetching
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Search'
                            }
                        </button>
                    </div>
                </div>
            </form>
            {
                showClickToAllowPopup && <ClickToAllowPopup setShowClickToAllowPopup={setShowClickToAllowPopup} />
            }
            {
                showHowToAllowPopup && <HowToAllowPerPopup setShow={setShowHowToAllowPopup} />
            }
        </div>
    )
}

export default memo(Search)