import * as Yup from 'yup';

const updatePasswordValidations = Yup.object({
    oldPassword : Yup.string()
    .min(8, 'Password should have at least 8 characters')
    .required('Old password is required') ,
    newPassword : Yup.string()
    .min(8, 'New password should have at least 8 characters')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character'
    )
    .required('New password is required') ,
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

export default updatePasswordValidations;