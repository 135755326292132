import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './utilities.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-accordion-comp/dist/styles.css";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';

import store from 'redux/store';
import { Provider } from 'react-redux';
import DrawerContextProvider from 'context/DrawerContext';
import AuthPopupContextProvider from 'context/AuthPopupContext';

import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalEventListeners from 'utils/GlobalEventListeners';
const queryClient = new QueryClient();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
            console.error('Error registering Service Worker:', error);
        });
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthPopupContextProvider>
        <QueryClientProvider client={queryClient}>
        <GlobalEventListeners />
        <DrawerContextProvider>
                
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </DrawerContextProvider>
        </QueryClientProvider>
    </AuthPopupContextProvider>
);