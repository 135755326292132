import React from 'react'

const TagSvg = () => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_34_336)">
<path d="M39.4011 0.809925C39.2851 0.520948 39.0621 0.287831 38.7786 0.159068C38.495 0.0303059 38.1728 0.0158127 37.8788 0.118604C37.5849 0.221395 37.3419 0.433556 37.2004 0.71095C37.0589 0.988345 37.0298 1.30961 37.1191 1.60792C38.9771 6.94792 35.2891 10.9359 32.4771 13.0719L31.3411 11.4439C30.9591 10.8979 30.1051 10.4439 29.4411 10.4359L23.0651 10.4639C22.2862 10.4874 21.5282 10.721 20.8711 11.1399L2.0831 24.3139C1.64609 24.6215 1.34873 25.0896 1.25615 25.6159C1.16356 26.1422 1.28328 26.6837 1.5891 27.1219L10.1271 39.3379C10.7671 40.2479 11.7891 40.1379 12.7011 39.5019L31.4891 26.3259C32.0291 25.9439 32.6531 25.1199 32.8731 24.4899L34.8691 18.1999C35.0891 17.5719 34.9551 16.6139 34.5731 16.0679L33.8811 15.0759C37.6571 12.1819 41.5771 7.06792 39.4011 0.809925ZM30.6591 19.5259C30.313 19.7682 29.9225 19.9399 29.51 20.0312C29.0975 20.1226 28.671 20.1317 28.255 20.0582C27.839 19.9846 27.4415 19.8298 27.0853 19.6026C26.7291 19.3754 26.4212 19.0802 26.1791 18.7339C25.689 18.0332 25.4969 17.1667 25.645 16.3245C25.7931 15.4823 26.2693 14.7333 26.9691 14.2419C27.5177 13.8576 28.1727 13.6542 28.8425 13.6603C29.5124 13.6664 30.1636 13.8816 30.7051 14.2759C30.1611 14.6039 29.7871 14.7959 29.7171 14.8259C29.4692 14.9441 29.2688 15.143 29.1487 15.3901C29.0286 15.6371 28.996 15.9176 29.0562 16.1856C29.1164 16.4535 29.2658 16.6931 29.48 16.8651C29.6942 17.037 29.9604 17.1311 30.2351 17.1319C30.4071 17.1319 30.5831 17.0919 30.7491 17.0139C31.1371 16.8299 31.5531 16.6119 31.9871 16.3539C32.0869 16.954 32.0152 17.5702 31.7803 18.1313C31.5454 18.6925 31.1567 19.1759 30.6591 19.5259Z" fill="url(#paint0_linear_34_336)"/>
</g>
<defs>
<linearGradient id="paint0_linear_34_336" x1="-5.59245" y1="10.8265" x2="40.1483" y2="11.4862" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
<clipPath id="clip0_34_336">
<rect width="40" height="40" fill="white" transform="translate(0.625)"/>
</clipPath>
</defs>
</svg>

    )
}

export default TagSvg