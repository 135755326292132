import Breadcrumbs from 'components/global/Breadcrumbs'
import React from 'react'

const VendorPolicy = () => {
    const crumbs = [
        { label: "Home", path: "/" },
        { label: "Vendor Policy",  }
    ]

    return (
        <div className='flex items-center justify-center flex-col px-3 ' >
            <div className='bg-white rounded-md md:py-10 sm:py-6 py-4 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-center text-gradient pt-4">
                    Vendor Policy
                </h1>
            </div>
            <div className='xl:w-[60%] lg:w-[70%] md:w-[80%] w-[95%] mx-auto'>
                <div className="WordSection1">
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        LEGAL DISCLAIMER
                        </span>
                    </u>
                    </b>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    :{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY
                    ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE AMENDED PROVISIONS
                    PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
                    INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A
                    COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                    <br />
                    THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
                    OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT
                    REQUIRE PUBLISHING THE RULES AND REGULATIONS, TERMS OF SERVICES, PRIVACY
                    POLICY AND USER AGREEMENT FOR ACCESS OR TERMS OF USAGE OF{" "}
                    <b>
                        <u>www.Rent N Drive.co.in</u>
                    </b>
                    (HEREINAFTER REFERRED TO AS THE “SITE”).
                    <br />
                    YOUR USE OF THIS SITE, OR YOUR PROVISION TO USE OF ANY PERSONAL
                    INFORMATION CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND ANY SUBSEQUENT
                    CHANGES TO THESE TERMS; DO NOT USE THE SITE OR PROVIDE INFORMATION IF YOU
                    DO NOT AGREE WITH ALL OF THE TERMS.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        PRIVACY NOTE:
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Though we make every effort to preserve the user privacy, we may need to
                    disclose personal information when required by law wherein we have a
                    good-faith belief that such action is necessary to comply with an
                    appropriate law enforcement investigation, current judicial proceeding, a
                    court order or legal process served on our Site, or as required by law. We
                    may also need to disclose the personal information to a third party for
                    the purpose of delivery of the services to the users.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        General Description:
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Site may collect a user's name, postal address, telephone number,
                    mobile number, e- mail address. Besides the above, Members intending to
                    create an account may be asked to submit personal information including
                    driving license, proof of identity etc. besides information pertaining to
                    the Member’s credit card/debit card or bank account details. The Site may
                    also be required to collect the PAN card details and any such detail which
                    is relevant to carry out the transaction. This information is requested if
                    the user is entering into a transaction and is further used to deliver the
                    services as has been requested by the user. The data so collected from the
                    user shall also be used to manage the Site, and detect any fraud or site
                    abuses.
                    <br />
                    Other personal information that we collect includes the following:
                    <br />
                    Vehicle Use Data: Vehicles will contain hardware that gathers and
                    transmits information about vehicle use. This is done as a security
                    measure against accident or theft and also to provide you with valuable
                    services and information, such as other drivers' data.
                    <br />
                    Location Tracking: To prevent theft, and to allow us to locate you in case
                    of emergency, accident, lock-out, etc., we track the location of your
                    vehicle. Your location information will be confined to Rent N Drive
                    service, and we will never impermissibly make your location or movements
                    public. As part of our service, the location of your vehicle may be
                    released to insurance companies, the police, or similar parties in the
                    course of an investigation and/or accident claim, and to provide
                    assistance in emergencies. Information regarding the location of each
                    vehicle on Rent N Drive’s site is also transmitted to Rent N Drive and can
                    be tracked by your Rent N Drive-enabled smartphone.
                    <br />
                    Driver and Vehicle Information: As a customer of Rent N Drive’s Site, you
                    authorize us to access your driver’s record, vehicle history report from
                    all applicable entities and authorities. This authorization continues for
                    as long as you are a Rent N Drive customer. Moreover, you authorize all
                    DMVs, RMVs, auto mechanics and all other entities and interested parties
                    to release information to us regarding your driving record and vehicle
                    history.
                    <br />
                    You have the right to request that Rent N Drive discontinue use of your
                    personal information. To withdraw your consent to our collection and
                    processing of your information at any time, you may do so by closing your
                    account.
                    <br />
                    Collection and Use of Non-Personal Information:
                    <br />
                    We also collect non-personal information – data in a form that does not
                    permit direct association with any specific individual, including IP
                    addresses, usage details and identifying technologies. We may use,
                    transfer, collect and disclose non-personal information for any purpose.
                    If we do combine non-personal information with personal information, the
                    combined information will be treated as personal information for as long
                    as it remains combined.
                    <br />
                    Aggregate Information: We may share non-personally identifiable
                    information (such as referring/exit pages, anonymous usage data, and URLs,
                    platform types, number of clicks, etc.) with interested third parties to
                    help them understand the usage patterns for certain Rent N Drive services.
                    <br />
                    Third-party ad servers or ad networks may serve advertisements on the Rent
                    N Drive Site. These third-party ad servers or ad networks may
                    automatically receive your IP address when they serve ads to your Internet
                    browser. They may also use other technologies (such as cookies,
                    JavaScript, or web beacons) to measure the effectiveness of their
                    advertisements and to personalize the advertising content. We do not
                    provide any personally identifiable information to these third-parties
                    without your consent. However, please note that if an advertiser asks Rent
                    N Drive to show an advertisement to a certain audience and you respond to
                    that advertisement, the advertiser or ad-server may conclude that you fit
                    the description of the audience they are trying to reach. The Rent N Drive
                    Privacy Policy does not apply to, and we cannot control the activities of,
                    third-party advertisers.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Normal Browsing
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    As with many websites, we collect certain information. When you browse,
                    read, or download information from the Site, data is collected by the
                    Site, including but not limited to your IP address, URL request, browser
                    type, and date and time of your request. We collect the IP addresses of
                    visitors, time of access, browser type, and web pages visited. We do not
                    collect any other information without your knowledge and permission.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Information You Provide
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Site requests personally identifiable information when you seek a
                    query from the Contact Us Tab on the Site and is subject to the Terms of
                    Use. Such information may be used or requested to perform research,
                    improve usability of the site, administer mailing lists or online
                    communities, or other activities related to the services provided by the
                    Site. This information may include, but is not limited to, person’s names,
                    email addresses, location. The above mentioned data is collected by the
                    Site only with the intention to conduct the business and for the
                    facilitation of the correct and timely delivery of the products and
                    improvement of the services. The collected data is preserved with the Site
                    safely and is not sold/disclosed to a third party, whatsoever, beyond the
                    terms of the privacy policy.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    You can request deletion of your account and personal data associated with
                    the account by either writing to us or through the “Manage profile”
                    section on our app.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Disclosure of Information
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Available log records and all data stored on our servers may be accessed
                    by our system administrators. In the event that we are required by law
                    (including a court order) to disclose the information you submit. We
                    prefer to independently object to overly broad requests for access to
                    information about users of our site, but we cannot assure you that we will
                    be able to do this in all cases. If you do not challenge the disclosure
                    request yourself, we may be legally required to turn over your
                    information.
                    <br />
                    The data so collected by the Site may be disclosed to other companies in
                    our group including our agents and subcontractors who help us to deliver
                    the goods to you, collect payments from you, analyse the data and provide
                    us with the marketing or customer service assistance. The data may also be
                    shared for the purpose of the fraud protection, credit risk reduction, for
                    conducting cases. In the event the business or a part of it is sold to any
                    other entity or the business under goes a merger/amalgamation/ demerger,
                    the relevant data may be transferred to such other new entity.
                    <br />
                    Besides the above, We reserve the right to transfer any personal
                    information we have about you in the event that we sell or transfer all or
                    a portion of our business or assets.
                    <br />
                    We partner with outside companies to provide you with additional services
                    related to Rent N Drive, such as ride-sharing or parking benefits. When
                    you purchase, register, or otherwise express interest in a product or
                    service offered by a third party through Rent N Drive or sponsored on our
                    site, you consent to our sharing your personal information with those
                    parties. Participation in rewards programs, discount offers, contests, or
                    other programs that involve third parties, authorizes Rent N Drive to
                    share your personal information with those parties. A third party’s use of
                    your information is bound by contracts and agreements between Rent N Drive
                    and the third party. We only share information reasonably needed to
                    provide additional services, and such shared information may be bound by
                    other privacy agreements. By sharing this information, you agree that Rent
                    N Drive is not liable or responsible for the actions or inactions of third
                    parties.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Security
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Site’s primary goal in collecting personal information is to provide
                    you, the member, with a potentially fulfilling experience. The information
                    collected by the Site is protected by the use of appropriate technical and
                    security measures to prevent unauthorized or unlawful access to or
                    accidental loss of or destruction or damage to your information. However,
                    it is brought to your notice that when you use our services or post on the
                    Site, some of the Personal Information you share is visible to other users
                    and can be read, collected, or used by them. You are responsible for such
                    Personal Information you choose to submit in these instances. For example,
                    if you list your name and email address on the site, that information is
                    public. Please take care when using these features. Further, you are
                    responsible for maintaining the confidentiality of your account and
                    password and for restricting access to your computer, and you agree to
                    accept responsibility for all activities that occur under your account or
                    password.
                    <br />
                    <br />
                    It is however expressly clarified that we will not be responsible for any
                    third party access to information provided by you on the Site by any third
                    party arising as a result of any unauthorized access or use.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Log Files
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Like most standard web site servers we use log files. This includes IP
                    (internet protocol) addresses, browser type, ISP (internet service
                    provider), referring / exit pages, platform type, date / time stamp and
                    number of clicks to analyze trends, administer the site, track user's
                    movement in the aggregate, and gather broad demographic information for
                    aggregate use. IP addresses, etc. are not linked to personally
                    identifiable information. The same may be used to track the behaviour of
                    the customers or identify the area of interest of the customer, which
                    might further be useful for advertisement, promotions etc.
                    <br />
                    <br />
                    <b>
                        <u>Cookies</u>
                    </b>
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    As set forth above under “Normal Browsing” anonymous users may receive
                    session cookies while visiting our Site while their browser is open.
                    “Users” are subject to the Site’s Terms of Use. Registered Users receive
                    session cookies when they are signed into our Site. To facilitate our
                    functions, we may use cookies to recognize when you return to our website,
                    if any.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Posting
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Posting or updating content is a public action undertaken by Member who
                    are subject to the Rent N Drive Membership – Terms and Conditions as set
                    out on the Site. For the avoidance of doubt, identification of all
                    contributed content may include, but is not limited to, display of your
                    name. All content may be retained for restorative, archival, or research
                    purposes by the Site. Editing or deleting content may alter the displayed
                    state of the content, but will not permanently delete the content from the
                    Site.
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Other Websites
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    When you are on the Site and are asked for personal information by the
                    Site, you are sharing that information with the Site only. However,
                    material on the Site may link to independently run websites outside the
                    domain of the Site. The Site is not responsible for the privacy practices
                    or content of such websites. We encourage you to read the privacy policies
                    of any websites you visit from this website as users and other members may
                    be able to access certain information that you provide to them (e.g. your
                    e-mail address and content that you post on the blog). The Site is not in
                    a position to monitor or control any particular user's or community
                    member's use of that content.
                    <br />
                    <br />
                    THE SITE IS NOT ENGAGED IN THE OCCUPATION OF STORING, PROCURING OR USAGE
                    OF THE INFORMATION (USER NAMES, PASSWORDS OR EMAILS) THAT YOU MAY ACCESS
                    BY THE USE OF YOUR SOCIAL NETWORKING ACCOUNT FOR THE PURPOSES OF LIKE,
                    TWEETING, +1 BUTTON, OR MENTIONS AND SUBSEQUENTLY CAN’T BE HELD LIABLE FOR
                    THE SAME.
                    <br />
                    THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY
                    ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE AMENDED PROVISIONS
                    PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
                    INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A
                    COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    This document sets out the terms and conditions for renting vehicles from
                    M/s SAK Rent N Drive Luxury Private Limited, a company incorporated under
                    the Companies Act, 2013, with CIN{" "}
                    </span>
                    <b>
                    <span
                        lang="EN-IN"
                        style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                        }}
                    >
                        U79120PN2023PTC223156
                    </span>
                    </b>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    (hereinafter referred to as "Rent N Drive" which expression shall unless
                    repugnant to the context or meaning include its heirs and successor).
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Definitions and Interpretations
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Definitions Unless the context otherwise requires, the following
                    capitalized words as used in these Terms shall have the meaning as
                    respectively assigned to such terms hereunder: “Applicable Laws” shall
                    mean and include, all applicable statutes, enactments, acts of legislature
                    or the Parliament, laws, ordinances, rules, by-laws, regulations,
                    notifications, guidelines, policies, directions, directives and orders of
                    any Governmental Authority (as defined below), tribunal, board, court or a
                    recognised stock exchange of India; “Governmental Authority” means any
                    governmental or statutory authority, government department, agency,
                    commission, board, tribunal, court or other entity, authority or body
                    authorized to exercise legislative, judicial, regulatory or administrative
                    functions of, or pertaining to, a government or any state or other
                    subdivision thereof or any municipality, district or other subdivision
                    thereof having jurisdiction pursuant to Applicable Laws; “User” shall mean
                    any person who has formally enrolled to use Rent N Drive’s vehicles and/or
                    services, pursuant to the completion of usage formalities and acceptance
                    of the terms and conditions as set out herein. “Non-Reserving User” shall
                    have the meaning as specified in; “Reserving User” shall have the meaning
                    as specified in Clause; “Rent N Drive Vehicle” shall mean a vehicle
                    provided by Rent N Drive for rental to its Users on the terms and
                    conditions as set out herein. “Scheme” shall mean the Rent N Drive
                    car-club scheme as floated by Rent N Drive pursuant to which the Rent N
                    Drive Vehicles are provided to Users for self drive purpose. “Terms” shall
                    mean these terms and conditions as may be updated, altered, modified,
                    novated, substituted or replaced from time to time; “Website” shall have
                    the meaning as set out in Recital 1 above.
                    <br />
                    Interpretations In these Terms (including its Recitals, Clauses and
                    Schedules), except where the context requires otherwise, these Terms will
                    be interpreted as follows:
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    words denoting the singular include the plural and vice versa and words
                    denoting a particular gender include all other genders;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the headings are inserted for convenience only, and shall not affect the
                    construction or interpretation of any provision of these Terms;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    References to Recitals, Clauses, Sub-Clauses thereof and Schedules, unless
                    a contrary intention appears, are to the recitals, clauses, sub-clauses
                    and schedules to these Terms, respectively;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    a reference to consent or approval or similar connotation, unless
                    expressly stated otherwise, shall be in writing, and references to writing
                    include any mode of reproducing words in a legible and non-transitory
                    form;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    a reference to any document (including these Terms) is to that document as
                    amended, consolidated, supplemented, renovated or replaced from time to
                    time;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    a reference to a statute or statutory provision includes, to the extent
                    applicable, at any relevant time:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    that statute or statutory provision as from time to time consolidated,
                    modified, re-enacted or replaced by any other statute or statutory
                    provision; and
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    any subordinate legislation or regulation / rules made under the relevant
                    statute or statutory provision;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Where a word or phrase is defined, other parts of speech and grammatical
                    forms and the cognate variations of that word or phrase shall have
                    corresponding meanings;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the rule of construction, if any, that a contract should be interpreted
                    against the party responsible for the drafting and preparation thereof,
                    shall not apply;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the expressions “hereof”, “herein” and similar expressions shall be
                    construed as references to these Terms as a whole and not limited to the
                    particular section or provision in which the relevant expression appears;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the words “include” and “including” will be read without limitation;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    references to rupees are lawful currency of India;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    schedules form an integral part these terms shall construed have same
                    force effect as if expressly set out in body terms.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Upon accepting the terms and conditions as set out here in after, the User
                    agrees and acknowledges that:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Rent N Drive has established a website having the registered domain
                    address as www.Rent N Drive.co.in (“Website”) where the terms and
                    conditions of use of vehicles provided by Rent N Drive have been
                    displayed. It is the responsibility of the user to ask representatives of
                    Rent N Drive.co.in and obtain a soft copy of the agreement if they are
                    unable to view or access the terms &amp; conditions as set out in website/
                    Mobile applications.
                    <br />
                    <br />
                    The User has read and understood the terms and conditions as set out
                    herein and agrees to abide and be bound by such terms and conditions
                    including those relating to rental of cars, fee schedule and privacy
                    policy and confidentiality terms. Rent N Drive reserves the right to
                    change the terms of this Agreement from time to time with the provision of
                    notice to the User which shall be considered given when these terms and
                    conditions are updated on the Website, IOS / Android apps. The User agrees
                    that the amended terms and conditions of this Agreement shall be effective
                    and binding on the date when such change is posted on the Website.
                    <br />
                    <br />
                    The provision of vehicles to the User is subject to the acceptance by the
                    User of all the terms and conditions as set out herein. In the event that
                    the User does not accept any term and/or condition as provided herein or
                    as may be amended, the User will not be authorized to use any vehicles or
                    services provided by Rent N Drive. For the avoidance of doubt it is
                    clarified that use of any vehicles or services provided by Rent N Drive
                    would signify an acceptance by the User of all the terms and conditions
                    including as set out herein including any terms and conditions as may be
                    amended, substituted or novated from time to time.
                    <br />
                    <br />
                    Moreover, this agreement doesn’t provide the exclusive right to the User
                    for use of vehicle and in all situations, Rent N Drive shall be authorized
                    to exercise required controls during the rental period.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Enrolment as User
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    By accepting these Terms and Conditions, the User has agreed to enroll as
                    a User under the Scheme to avail the facility for rental of Rent N Drive
                    Vehicles.
                    <br />
                    <br />
                    The User further acknowledges and understands that he/she shall not at any
                    time challenge any of the provisions of these Terms or the contents of the
                    Website on the premise that the terms and conditions have not been read by
                    the User or that the User is not aware of the enrolment formalities or
                    that the User has not understood any provision of these Terms or the
                    procedures, formalities, benefits rights and/or obligations as contained
                    on the Website.
                    <br />
                    <br />
                    There is no difference between User &amp; Customer. User is a person who
                    registered with Rent N Drive.co.in and customer is a person who hired the
                    vehicle.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Persons eligible to enroll as Users
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Any person desirous of enrolling as a User with the Rent N Drive will need
                    to satisfy the following eligibility criteria prior to enrollment:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Such person must be minimum 21 years of age.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Such person must be an Indian citizen and must possess a valid Light Motor
                    Vehicle (Non Transport) Indian license at least 1 year old.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Such person must have a good driving record and should not have been found
                    guilty of any offence involving moral turpitude or any offence under Motor
                    Vehicles Act for which imprisonment for more than 6 months have been
                    prescribed;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Enrollment Formalities
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the purpose of enrollment, the User shall be required to provide the
                    necessary particulars, as indicated by Rent N Drive’s representatives.
                    <br />
                    <br />
                    The User shall be required to provide the following documents to Rent N
                    Drive’s representatives:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    A photo of a valid driving license
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Credit / Debit card details, if this happens to be the preferred mode if
                    payment
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Any other documents as may be notified by Rent N Drive from time to time.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Rent N Drive reserves the right to refuse acceptance of any person as a
                    User without assigning any reason thereto. It is clarified that in the
                    event that any person is rejected as a User, he/ she shall not be
                    permitted to use or rent any Rent N Drive Vehicle.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Booking of Rent N Drive Vehicles
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Users may choose from the vehicles which are available on the Website
                    for the purpose of booking a Rent N Drive Vehicle. At the time of booking,
                    the Users will be required to:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Select the Rent N Drive Vehicle as per their requirements;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Provide the start and end date and time, indicating the duration for which
                    the Rent N Drive Vehicle will be required for use by the User;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Pick and return of vehicle address will be the same.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Pay the Fees for use of the Vehicle through any of the payment systems
                    that Rent N Drive is using at that point of time.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    In case a User wishes to cancel a reservation or shorten the period for
                    which a vehicle has been reserved, he must do so in advance, as outlined
                    in the Fee Policy
                    <br />
                    <br />
                    If a User wishes to extend a reservation, such User is required to
                    communicate request for extension to Rent N Drive prior to the timeline
                    outlined in the Fee Policy. Rent N Drive shall have the sole discretion to
                    allow such extension or refuse the same. A request for extension will only
                    be permitted where:
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the Rent N Drive Vehicle is available for use for the extension period
                    (for example, it is not reserved by another User); and
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Upon payment of Fees in advance for such extended period
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Scope of vehicle rental services provided by Rent N Drive
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Upon payment of the fees in advance, Rent N Drive is responsible to
                    provide the following services to the User:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Provide the vehicle Name(alongwith vehicle registration number) booked by
                    the User, at the preferred time and location, as specified at the time of
                    making the booking
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In case there is a delay or due to any unfortunate event Rent N Drive has
                    to cancel the booking of the User, Rent N Drive is liable to compensate /
                    provide a refund to the User for the part where the service could not be
                    provided
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In the unfortunate event when there is a breakdown of the vehicle, while
                    being used by the User, and it is clearly established that the breakdown
                    was due to a mechanical / electrical failure of the vehicle and not due to
                    any consequential or driving behaviour related damage caused by the User,
                    Rent N Drive will try and provide the best resolution possible to the
                    User. This resolution will include providing a replacement vehicle, if the
                    vehicle is within 2 hours of driving distance from any of Rent N Drive’s
                    hubs, arranging for an RSA (road side assistance) and/or any other help
                    that might be needed by the User.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Persons permitted to use/rent Rent N Drive Vehicles
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Rent N Drive Vehicles shall be provided on rental basis only to
                    eligible Users. Notwithstanding anything stated herein, Rent N Drive shall
                    have the sole discretion to refuse to provide any Rent N Drive Vehicle to
                    any Users.
                    <br />
                    <br />
                    Users shall ensure that the Rent N Drive Vehicles shall not be driven by,
                    or provided for use to, any person whose eligibility has not been deemed
                    as valid by Rent N Drive.
                    <br />
                    <br />
                    Any User to whom a Rent N Drive Vehicle is provided by Rent N Drive on
                    rental basis (“Reserving User”) may allow another User to drive such Rent
                    N Drive Vehicle (“Non-Reserving User”) subject to the following
                    conditions:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The Reserving User shall provide the details of the Non-Reserving User to
                    Rent N Drive;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The Reserving User shall not be allowed to provide the Rent N Drive
                    Vehicle to a Non-Reserving User for any commercial purpose;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The Reserving User shall continue to be responsible and liable to Rent N
                    Drive to ensure that all terms and conditions as set out in these Terms
                    are complied with by the Non-Reserving User.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The Reserving User shall further ensure that the information pertaining to
                    the Non-Reserving User shall remain updated in the records of Rent N Drive
                    till such time as the Rent N Drive Vehicle is being used by such
                    Non-Reserving User.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The Reserving User shall continue to remain liable to Rent N Drive for due
                    compliance of all terms and conditions as set out in these Terms including
                    for payment of all fees and charges associated with the usage of the Rent
                    N Drive vehicles.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Prohibited Use of Rent N Drive Vehicles
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Users shall not be permitted to use the Rent N Drive Vehicles under the
                    following conditions and/or for the purposes mentioned hereunder. These
                    examples are not intended to be exhaustive. Any unreasonable or
                    inappropriate use of a Rent N Drive Vehicle, as determined by Rent N Drive
                    in its sole discretion, may be deemed a violation of these Terms and
                    Conditions:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For any speed race or competition;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the purpose of towing, pushing, or propelling any trailer or any other
                    vehicle;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the primary business purpose of transporting people or operating a
                    taxi service;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    By any person who is under the influence of (i) alcohol or (ii) any drug
                    or medication under the effects of which the operation of a vehicle is
                    prohibited or not recommended;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Illegal Nature:-For the purpose of commission of any crime or other
                    illegal or unlawful activity;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In an imprudent, negligent, abusive manner or for any abnormal use of the
                    Rent N Drive Vehicle;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    By any person who has provided Rent N Drive with false information
                    (including, without limitation, any personal information such as name,
                    age, or address) or whose representations are determined to be false;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the purpose of driving outside the territorial boundaries of India or
                    any prohibited areas according to Rent n Drive. Any illegal activity as
                    per state law/local bodies law inside vehicle strictly provided, any legal
                    consequences arise, rent n drive will not be responsible for the
                    act.&nbsp;{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Driving while using a mobile phone or any other mobile communication
                    device in any manner whatsoever, including while sending an SMS, emailing,
                    using a cell phone with or without a hands-free device, otherwise using a
                    mobile communication device or engaging in similar activities that may be
                    prohibited under Applicable Laws;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the purpose of transportation of any goods including any flammable,
                    poisonous, or otherwise hazardous substances;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    For the purpose of transporting objects that could – due to their size,
                    shape, or weight – adversely impact the vehicle’s handling safety or that
                    could damage the interior of the Rent N Drive Vehicle or, by virtue of
                    such object/s protruding outside, affect the safety of vehicles driving in
                    the vicinity of the Rent N Drive Vehicle.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Carrying more than the authorized number of persons (equal to the number
                    of seat-belts) in the Rent N Drive Vehicle.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Use of tobacco, drugs or psychotropic substances inside Rent N Drive
                    Vehicles is absolutely prohibited. Users are subject to payment of
                    penalties if evidence of smoking, chewing, or dipping is found in Rent N
                    Drive Vehicle.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Hazardous Nature: - Burning any substance including tobacco, incense etc.
                    inside Rent N Drive Vehicles is absolutely prohibited.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Animals are prohibited in Rent N Drive Vehicles. Users are subject to
                    payment of penalties in the event it is determined that the Rent N Drive
                    Vehicles have been used for transportation of animals.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    A Rent N Drive Vehicle cannot be taken inside Leh/Ladakh region and
                    Kaza/Nako region.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Any kind of activity by any person which will affect the normal conditions
                    of the vehicle
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <span style={{ textDecoration: "none" }}>&nbsp;</span>
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                    <b>
                    <u>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Obligations of the User
                        </span>
                    </u>
                    </b>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    By accepting the Terms as set out herein, the User hereby authorizes Rent
                    N Drive to obtain the User's driving records from the jurisdiction in
                    which the User is licensed.
                    <br />
                    <br />
                    User shall be responsible for providing and maintaining their account
                    information with Rent N Drive including details such as their current
                    email, mobile number, address, and such other information as may be
                    necessary for the purposes of this agreement.
                    <br />
                    <br />
                    Users shall be responsible for keeping any Rent N Drive-related password
                    and/or PIN numbers in strict confidence and to not make passwords or PINs
                    available to third-parties,. Should a PIN or password be reduced to
                    written or electronic form, the User will be responsible for any
                    associated costs and/or damages in connection with unauthorized use by
                    third-parties. If a User has reason to believe that a third-party may have
                    obtained unauthorized knowledge of a password and/or PIN, User agrees to
                    change the compromised password and/or PIN as per the procedure provided
                    on the Website.
                    <br />
                    <br />
                    When using a vehicle, every User must follow the owner's manual
                    instructions provided in the vehicle’s glove compartment. If a problem
                    arises that prevents or limits the use of the vehicle or that may
                    compromise people's safety, every User must immediately notify Rent N
                    Drive and follow the instructions provided by Rent N Drive.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Controls exercised by Rent N Drive during the duration of vehicle rental:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    It may be noted that during the period of vehicle rental, the effective
                    control of vehicle shall remain with Rent N Drive. The control may be
                    exercised in the event of violation of any of the terms and conditions set
                    out in this agreement. Further, it may be noted that all vehicles of Rent
                    N Drive are fitted with GPS trackers, speed trackers and can be remotely
                    accessed.
                    <br />
                    <br />
                    Therefore, in the event of any breach of terms and conditions of this
                    agreement, Rent N Drive can exercise the controls and shut down the
                    vehicle by way of remote access.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Vehicle Pick-Up and Return
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Prior to taking possession of a Rent N Drive Vehicle, it shall be the
                    responsibility of every User to do an exterior walk-around and an interior
                    look-over of the vehicle
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    User's shall be permitted to test drive the Rent N Drive Vehicle to ensure
                    that there is no damage or abnormality encountered in the operation of the
                    vehicle. In case such an abnormality or damage is found, User shall be
                    required to intimate Rent N Drive of any such damage or abnormality
                    encountered on the vehicle or in the operation of the vehicle prior to
                    taking possession of such vehicle. Users are not allowed to take test
                    drive before booking.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    It shall be the responsibility of the User to pick up the selected Rent N
                    Drive Vehicle and return it secured, clean, and in good working order, at
                    the same place, by no later than the end time of the reservation.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Fuel Charges
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Rent N Drive shall make the payment of fuel to the third party suppliers
                    (i.e. relevant supplier of fuel or petrol stations) on behalf of the User;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Rent N Drive shall charge the actual expenses incurred towards fuel cost
                    from the Users and there shall be no mark-up on the same;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Rent N Drive doesn’t holds or intend to hold any title of such fuel
                    expenses and also doesn’t intend to use it for its own purpose. Thus, the
                    same is exclusively procured on behalf of User.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Vehicle Condition
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The User is required to report to Rent N Drive immediately any condition
                    that impairs the driving functionality and/or safety of the vehicle, such
                    as performance changes, inappropriate noises, smells or driving feel,
                    including but not limited to warning lamps, indicators, poor driving feel
                    or external or internal damage that renders the vehicle unsafe, including
                    but not limited to, missing or inoperable signal or driving lighting,
                    broken or missing rear-view mirrors and windshield glass etc.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Before performing jump start on any vehicle, the User must inform Rent N
                    Drive. The User shall bear the liability for any losses arising out of a
                    jump start
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The User must leave the vehicle’s key, key fob, or other starting device
                    to the vehicle and the parking pass or toll payment pass in its proper
                    position in the vehicle at the conclusion of the reservation or hand it
                    over to the authorized representative of Rent N Drive. In the event that
                    the User fails to leave the key/fob/starting device, parking pass, or toll
                    payment pass (if applicable) in the vehicle, the same shall be informed to
                    Rent N Drive immediately. If the User fails to do so and this causes
                    inconvenience to another User, such User shall be charged the hourly rate
                    and late fees (as outlined in the Fee Policy) for the vehicle until the
                    key/key fob/starting device/parking pass/toll payment pass is returned and
                    the User will remain responsible for the vehicle during such period. The
                    User shall also be charged a fee at Rent N Drive’s sole discretion, to
                    replace any of the items missing from the vehicle.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Reserving Users are responsible for all charges and costs incurred related
                    to the Rent N Drive Vehicle for the entire period of the reservation and
                    until the vehicle is returned, secured, closed, locked and serviceable
                    (all accessories off, key out of ignition and in proper place, all
                    windows, doors, hatches, sunroofs and other openings closed, toll payment
                    pass and parking pass and other accessories in the vehicle) to its
                    designated return location. Rent N Drive vehicles must be returned no
                    later than the end time of the reservation and the User will be required
                    to terminate the reservation by placing a call to Rent N Drive’s provided
                    number to notify of such intent.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In the event that a Rent N Drive Vehicle is returned late, the Reserving
                    User will be responsible for paying late fees, inconvenience fee and any
                    other charges applicable as per Rent N Drive’s Fee Policy, from the
                    scheduled end time of the booking till the time the vehicle is recovered
                    by Rent N Drive. The Users will be responsible for any and all costs,
                    charges, fees and expenses incurred by Rent N Drive or any third party as
                    a result of a breach of any of these Terms.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The owner is responsible for any costs that can be attributed to
                    mechanical failure due to normal usage for which the vehicle was designed
                    for and customer will be charged or obliged to get it repaired if the
                    damage is not due to normal usage.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Stolen vehicles Information about stolen vehicles or stolen parts of the
                    Rent N Drive Vehicle must be immediately reported by the User to the
                    nearest police station with a detailed written complaint based on actual
                    facts and a copy of the acknowledged complaint should be provided by the
                    User to Rent N Drive. Users shall ensure that such information about lost
                    vehicles shall not contain any discrepancy, inconsistencies or distortions
                    from actual facts as the same would be detrimental to a valid insurance or
                    other claim by Rent N Drive. The User will be liable for any loss due to a
                    discrepant, inconsistent or distorted complaint by the User.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Safety
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Seat Belts and Child Restraints - User is responsible for:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Complying with all applicable seat belt and child restraint laws;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Protecting all children by properly using any child passenger restraint
                    system that complies with the Motor Vehicle Safety Standards in the
                    jurisdiction where the trip is initiated and where the Rent N Drive
                    vehicle is driven during such trip.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Each time the User parks a Rent N Drive Vehicle (either at the end of the
                    reservation or during the time of reservation), the User shall be
                    responsible for securing the vehicle in conditions such as closing of
                    windows and locking the car.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Breakdown or Incidents
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    All breakdowns or incidents pertaining to Rent N Drive Vehicles must be
                    reported to Rent N Drive.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In case of an incident involving property damage or involving any third
                    party, the User must fill out an official police report form, and, if
                    possible, provide a jointly agreed-upon statement, complete Rent N Drive’s
                    incident report form, and obtain the following information:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Date, time, and place of incident;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The license plate numbers of any other vehicles involved, their make and
                    year, their identification number (serial number), and the insurance
                    certificate's number (with name, address and phone number of the insurance
                    agent);
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The names, addresses, driver's license numbers of the persons involved in
                    the incident;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The name, address, and driver's license number of the owner of the
                    vehicle;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The name, addresses, phone number of witnesses, passengers, other involved
                    persons;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Circumstances of the incident describing immediate surrounding environment
                    and car position prior to the incident; and
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Traffic Violations
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    All traffic violations by a User shall be notified to the authorized
                    representative of Rent N Drive as soon as possible within the prescribed
                    deadline for the violation.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The User shall further notify Rent N Drive of any traffic violation
                    notices found on a vehicle at the time of vehicle’s delivery to User.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    All traffic violations will be the responsibility of the User if they
                    occur during the time period during which the User is responsible for the
                    Rent N Drive vehicle. In the event of any fines or penalties imposed by
                    any Governmental Authority for traffic violations, the same shall be borne
                    by the Reserving Users.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    The User shall not leave a vehicle in a zone which has parking
                    restrictions. If the User leaves the vehicle in such a restricted zone,
                    the User must immediately notify Rent N Drive, and shall be responsible
                    for any and all violation notices or towing charges incurred by Rent N
                    Drive.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Roadside Assistance
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Rent N Drive will help you to get roadside assistance support as and when
                    required.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Payments Policy
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    The Users will be required to pay the Fees for the duration specified by
                    the Users at the time when such booking is being made, or at the time of
                    physical delivery of the Rent N Drive Vehicle to the User. The Fees shall
                    be such as specified on the Website, for each Rent N Drive Vehicle and may
                    be accessed by the User.
                    <br />
                    <br />
                    Payments by the User may be made with the use of credit card / debit card
                    or other netbanking facilities. User is under an obligation to ensure that
                    the account from which the amounts are to be collected have sufficient
                    funds or credit available to cover any charges. The User is solely
                    responsible for any associated bank or credit card charges or fees. The
                    User may be charged a processing fee for a declined credit or debit card
                    payment.
                    <br />
                    <br />
                    In the event the User defaults on any payments, Rent N Drive is entitled
                    to charge remainder fees and default interest at the rate of 24% per
                    annum. In addition, Rent N Drive may utilize third parties to collect
                    amounts owed to Rent N Drive by a User. Rent N Drive reserves the right to
                    report the delay to credit rating agencies
                    <br />
                    <br />
                    With all fees mentioned above, Rent N Drive reserves the right to prohibit
                    a User from making a subsequent reservation until all outstanding fees in
                    the User's account have been paid in full. In the event a fee is incurred,
                    Users will receive an email invoice from Rent N Drive that will have
                    detailed payment instructions.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Revocation of License
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Should any User's driver’s license expire or be revoked, authorization to
                    drive Rent N Drive vehicles shall expire immediately. Rent N Drive shall
                    be notified of such revocation by the User by e-mail forthwith upon such
                    revocation.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Suspension/Termination
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Rent N Drive may immediately suspend or terminate the use of its service
                    by any User who does not meet Rent N Drive’s driving eligibility
                    requirements or for any unreasonable or inappropriate use of a Rent N
                    Drive vehicle, as determined by Rent N Drive in its sole discretion or for
                    violation of any of provisions mentioned in this agreement.
                    <br />
                    <br />
                    Rent N Drive reserves the right, at its sole discretion, to suspend or
                    terminate the use of its service by any User for inappropriate noises or
                    driving feel, including but not limited to warning lamps, indicators,
                    inappropriate sounds or smells, or performance changes.
                    <br />
                    <br />
                    Rent N Drive reserves all the right to cancel any booking and recall the
                    vehicle at any point in time. Likewise. Rent N Drive reserves sole
                    discretion in serving any individuals &amp; organisations.
                    <br />
                    Rent N Drive reserves the right, at its sole discretion, to suspend or
                    terminate the use of its service by any User and shall charge a damage
                    fee, a cleaning fee, or other applicable fees, if Rent N Drive is not
                    notified of a problem at the start of reservation
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Indemnification
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    User Indemnification and holds Rent N Drive, its Parent and affiliates and
                    their respective Directors, Officers, Employees, Shareholders, Agents,
                    Attorneys, Assigns and Successors-in-interest harmless for all losses,
                    liabilities, damages, injuries, claims, demands, costs, Attorney fees and
                    other expenses incurred by Rent N Drive arising from a breach of the Terms
                    as specified herein:
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Penalty
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Every User who violates the law or any of the provisions of this
                    agreements or the rules setup by Rent N Drive and could face legal action
                    and shall be responsible for all damages, liability, and fines as
                    mentioned in the Fee Policy
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Data Protection
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    Rent N Drive captures, stores, processes and uses the Customer’s personal
                    data, including, but not limited to, the usage and vehicle data as they
                    relate to the Customer, to the extent this is necessary for the
                    administration and implementation of this Agreement and the Customer’s use
                    of the Rent N Drive vehicle.
                    <br />
                    <br />
                    Should third-party services be used, Rent N Drive is entitled to forward
                    to the third-party service provider the Customer’s personal data, to the
                    extent this is required in order to fulfill customership or use
                    requirements.
                    <br />
                    <br />
                    Rent N Drive is entitled to provide Customer’s personal data to
                    third-parties for the purposes of providing individualized offers,
                    services, and other customized information to Customers.
                    <br />
                    <br />
                    Rent N Drive shall implement and maintain reasonable procedures for
                    protecting sensitive personal information in compliance with applicable
                    law.
                    <br />
                    <br />
                    Rent N Drive shall be entitled to disclose information of the Userto Rent
                    N Drive’s parent company and to all companies controlled by Rent N Drive
                    or any of its affiliates and to any government body as required by the
                    law/ or by directive/ or request from any government body or to any third
                    party deemed fit and proper by Rent N Drive, in its absolute discretion.
                    <br />
                    <br />
                    Please refer to the Privacy Policy as contained on the Website which shall
                    be applicable to information and/or data provided by the Users.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Dispute Resolution(Vendor and user)
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    In the event of any disputes, differences, controversies and questions
                    directly or indirectly arising at any time hereafter between a vendor and
                    User or their respective representatives or assigns under, out of, in
                    connection with, or in relation to, these Terms (or the subject matter of
                    these Terms) including, without limitation, all disputes, differences,
                    controversies and questions relating to the validity, interpretation,
                    construction, performance and enforcement of any provision of these Terms,
                    (hereinafter referred to as a “Dispute”), the same shall be referred to
                    binding arbitration at the request of the User or Rent N Drive, in
                    writing, in accordance with the Arbitration and Conciliation Act, 1996 or
                    any statutory modification or alteration thereof for the time being in
                    force. The arbitration tribunal shall comprise a sole arbitrator to be
                    appointed by Rent N Drive whose decision in relation to any such Dispute
                    shall be final and binding on the Parties hereto.
                    <br />
                    <br />
                    The arbitration proceedings shall be conducted in the English/Hindi
                    language. The seat of arbitration shall be in Pune.
                    <br />
                    <br />
                    The costs of arbitration shall be fixed by the arbitral tribunal and the
                    tribunal in the final award shall specify
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the party entitled to costs;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the party who shall pay the costs;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the amount of such costs; and
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    the manner in which the costs shall be paid. For the purpose of this
                    Clause, ‘costs of arbitration’ shall mean the fees and expenses of the
                    arbitrator, legal fees and expenses, any administrative fees and any other
                    expense incurred in connection with the arbitral proceedings and the
                    arbitral award.
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    No party or person involved in any way in the creation, coordination or
                    operation of the arbitration of any Dispute may disclose the existence,
                    content or results of the Dispute or any arbitration conducted under this
                    Agreement in relation to that Dispute save as required in order to enforce
                    this Clause and / or any arbitral award made pursuant to these Terms.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Governing Law
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    These Terms shall be governed by and interpreted and construed in
                    accordance with the substantive laws of India, without regard to the
                    conflict of laws provisions thereof. The courts in Pune shall have
                    exclusive jurisdiction on all matters pertaining to this Agreement.
                    <br />
                    <br />
                    User must not aid or encourage the filing of any third-party claim or
                    lawsuit against Rent N Drive, and User must cooperate fully with Rent N
                    Drive and Rent N Drive’s insurer in the investigation and defense of any
                    claim or lawsuit.
                    <br />
                    <br />
                    <br />
                    User must immediately notify and deliver to Rent N Drive every summons,
                    complaint, document, or notice of any kind received by User in any way
                    relating to an accident, theft, or other circumstances related to the Rent
                    N Drive vehicle.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Severability
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    If any term, provision, covenant or condition of this Agreement is held
                    invalid or unenforceable for any reason, the remainder of the provisions
                    will continue in full force and effect as if these Terms had been executed
                    with the invalid portion eliminated. The parties shall substitute for the
                    invalid provision a valid provision that most closely approximates the
                    intent and economic effect of the invalid provision.
                    <br />
                    <br />
                    Customer represents that he or she has not relied on any representation,
                    assertion, guarantee, warranty, collateral contract or other assurance,
                    except those set out in these Terms, made by or on behalf of any other
                    party or any other person or entity whatsoever, prior to the execution of
                    this Agreement.
                    <br />
                    <br />
                    Waiver Failure to exercise and delay in the exercise of any right, power
                    or privilege hereunder by the Company shall not operate as a waiver
                    thereof nor shall any single or partial exercise of any right, power or
                    privilege preclude any other or further exercise thereof or the exercise
                    of any other right, power or privilege. The rights and remedies provided
                    in these Terms are cumulative and not exclusive of any rights or, remedies
                    otherwise provided by Applicable Law.
                    <br />
                    <br />
                    Assignment or Transfer The rights granted to the User under these Terms
                    are not assignable or transferable, in whole or part. Any attempt to
                    transfer any rights without the written consent of Rent N Drive shall be
                    void and shall haveno force and effect. Rent N Drive shall have the sole
                    authority to assign this Agreement to an affiliate or to another entity in
                    connection with any other corporate transaction.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Cookies
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    <br />
                    <br />
                    Rent N Drive use various technologies, including “cookies”, to collect
                    non-identifiable information. To enhance Rent N Drive’s Services, Rent N
                    Drive shall use cookies, sent by Rent N Drive or its third party vendors,
                    or other technologies. Users may control the effect of cookies through
                    his/her browser settings, however some features of Rent N Drive’s Service
                    may not work properly if the use of cookies is disabled.
                    <br />
                    <br />
                    Rent N Drive shall also use Web beacon or other technologies, often in
                    conjunction with cookies, to enhance its Service on a number of pages of
                    Rent N Drive’s website. A non-identifiable notice of a visitor’s visit to
                    a page on Rent N Drive’s site is generated and recorded, and which may be
                    processed by Rent N Drive or by Rent N Drive’s suppliers. To disable some
                    of these features, Users may disable cookies in the web browser’s
                    settings. Web beacon and other technologies will still detect visits to
                    these pages, but the notices they generate are disregarded and cannot be
                    associated with other non-identifiable cookie information.
                    <br />
                    <br />
                    Links to third party websites: Rent N Drive’s website may contain links to
                    third-party websites, products, and services. Information collected by
                    third parties may include things as location data or contact details, as
                    governed by the privacy practices. Rent N Drive encourages its Users to
                    learn about the privacy practices of those third parties.
                    <br />
                    <br />
                    Information Security: Rent N Drive take precautions – including
                    administrative, technical, and physical measures – to safeguard its Users
                    personal information against loss, misuse or theft, as well as against
                    destruction, alteration, disclosure and unauthorized access but does not
                    guarantee the complete security of such personal Information.
                    <br />
                    <br />
                    When a User uses Rent N Drive’s services or post on the Site, some of the
                    Personal Information the User share shall be visible to other users and
                    can be read, collected, or used by them. The User shall be held
                    responsible for such Personal Information the User chooses to submit in
                    these instances. The Users shall be further responsible for maintaining
                    the confidentiality of its account and password and every User shall agree
                    to accept the responsibility for all activities that occur under his/her
                    account or password.
                    <br />
                    <br />
                    <br />
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Contact Information
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    In case of any questions or concerns about the Policy or data processing,
                    Users shall contact at:
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Address: Sr. No. 140/1B4, Ghule Nagar, Manjari Farm, Mundhwa Road, Haveli,
                    Pune - 412307
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Phone number: +91 8007784794
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    Email:
                    </span>
                    <span lang="EN-IN">
                    <a href="mailto:rentndriveluxury@gmail.com">
                        <span
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        rentndriveluxury@gmail.com
                        </span>
                    </a>
                    </span>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    ></span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p className="MsoNormal" style={{ textAlign: "justify" }}>
                    <span
                    lang="EN-IN"
                    style={{
                        fontSize: "14.0pt",
                        lineHeight: "25px",
                        fontFamily: 'poppins'
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                </div>


            </div>
        </div>
    )
}

export default VendorPolicy