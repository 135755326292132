import React, { useEffect, useState } from 'react'

const useNotifyPermission = () => {
    const [notificationPermission, setNotificationPermission] = useState('default');

    useEffect(() => {
        const requestNotificationPermission = () => {
            if ('Notification' in window) {
                if (Notification.permission === 'granted') {
                    setNotificationPermission('granted');
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(permission => {
                        setNotificationPermission(permission);
                    });
                }
            }
        };
        requestNotificationPermission();
    }, []);
    
    return notificationPermission;
}

export default useNotifyPermission