import Breadcrumbs from 'components/global/Breadcrumbs'
import React from 'react'
const crumbs = [
    { label: "Home", path: "/" },
    { label: "Refund Policy",  }
]

const RefundPolicy = () => {
    return (
        <div className='flex items-center justify-center flex-col px-3' >
            <div className='bg-white rounded-md md:p-10 sm:p-6 py-4 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-center text-gradient pt-4">
                    Refund Policy
                </h1>
            </div>
            <div className='xl:w-[60%] lg:w-[70%] md:w-[80%] w-[95%] mx-auto'>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "center",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <strong>
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                    </strong>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <strong>
                    <u>
                        <span style={{ fontFamily: '"poppins"' }}>
                        LEGAL DISCLAIMER
                        </span>
                    </u>
                    </strong>
                    <span style={{ fontFamily: '"poppins"' }}>:&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>
                    THIS ******** IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION **********
                    ACT, 2000 *** RULES THERE ***** AS APPLICABLE *** THE AMENDED PROVISIONS
                    PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED ** THE
                    *********** TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A
                    COMPUTER SYSTEM AND DOES *** REQUIRE ANY PHYSICAL ** DIGITAL SIGNATURES.
                    </span>
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
                    OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT
                    ******* ********** THE RULES *** REGULATIONS, TERMS OF SERVICES, PRIVACY
                    POLICY AND USER ********* *** ACCESS ** TERMS OF USAGE OF&nbsp;
                    </span>
                    <strong>
                    <u>
                        <span style={{ fontFamily: '"poppins"' }}>
                        www.**** N Drive.co.in
                        </span>
                    </u>
                    </strong>
                    <span style={{ fontFamily: '"poppins"' }}>
                    &nbsp;(HEREINAFTER REFERRED TO AS THE “SITE”).
                    </span>
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    **** USE OF THIS SITE, ** YOUR ********* TO USE OF ANY PERSONAL
                    INFORMATION CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND ANY SUBSEQUENT
                    CHANGES TO THESE TERMS; DO NOT USE *** SITE ** PROVIDE INFORMATION IF YOU
                    ** NOT AGREE WITH ALL OF THE TERMS.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>
                    In *** event *** User defaults ** any payments, Rent N Drive is entitled
                    to charge remainder fees and default interest at the rate of 24% per
                    annum. In addition, Rent N Drive may utilize third parties to collect
                    ******* owed to **** N Drive by a User. **** N Drive reserves *** right to
                    report the delay ** credit rating agencies
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    With all fees mentioned above, **** N Drive reserves the right to prohibit
                    a **** from making a subsequent *********** ***** all outstanding **** **
                    the User's account **** been paid in full. In the event a *** is incurred,
                    Users will receive an email invoice from Rent N Drive that will have
                    detailed payment instructions.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>
                    Suspension/Termination
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    Rent N ***** *** immediately suspend or terminate the use of its service
                    by any **** who does not meet Rent N Drive’s ******* eligibility
                    requirements or for any unreasonable or inappropriate use of a Rent N
                    Drive vehicle, as determined by Rent N Drive in its sole discretion ** for
                    violation of any of provisions mentioned in **** agreement.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    Rent N Drive reserves the right, at its **** discretion, to suspend or
                    terminate the use of its service by any User for inappropriate noises or
                    driving feel, ********* but not limited to warning lamps, indicators,
                    ************* sounds ** smells, or performance changes.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    **** N ***** ******** all the right to cancel any booking and recall the
                    vehicle at any point in time. Likewise. Rent N Drive reserves sole
                    discretion in serving any individuals &amp; organisations.
                    </span>
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    Rent N ***** ******** the right, at its sole discretion, to suspend or
                    terminate the use of its ******* by any User and shall charge a ******
                    fee, a cleaning fee, ** ***** applicable fees, if Rent N Drive ** not
                    ******** of a problem at the ***** of reservation
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>Indemnification</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    User Indemnification and holds Rent N Drive, its Parent and affiliates and
                    their respective Directors, Officers, Employees, Shareholders, Agents,
                    Attorneys, Assigns and Successors-in-interest harmless for all losses,
                    liabilities, damages, injuries, claims, demands, costs, Attorney fees and
                    other expenses incurred by Rent N Drive ******* from a breach of the Terms
                    ** specified herein:
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>Penalty</span>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    Every User who violates *** law or any of the ********** of this
                    agreements or the rules setup by Rent N ***** and could face ***** action
                    and ***** be responsible for all damages, liability, *** fines as
                    mentioned in the Fee Policy
                    </span>
                    <br />
                    <br />
                    <br />
                    <strong>
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                    </strong>
                </p>
                <p
                    style={{
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <strong>
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                    </strong>
                </p>

            </div>
        </div>
    )
}

export default RefundPolicy