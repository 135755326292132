import OurMissionImg from 'assets/images/ourMission.png';
import BulbSvg from 'assets/svgs/BulbSvg';
import RocketSvg from 'assets/svgs/RocketSvg';
import AboutUs from 'components/about/AboutUs';
import CompanyGoals from 'components/about/CompanyGoals';
import Kiran from 'components/about/Kiran';
import OurMission from 'components/about/OurMission';
import Sumant from 'components/about/Sumant';
import Breadcrumbs from 'components/global/Breadcrumbs';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about" },
]

const About = () => {
    return (
        <div>
            <div className='container mx-auto md:px-12 sm:px-0 px-3 pt-4'>
                <Breadcrumbs crumbs={crumbs} />
                <AboutUs />
                <Sumant />
                <Kiran />
                <CompanyGoals />
                <OurMission />
            </div>
            
        </div>
    )
}

export default About