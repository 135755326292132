import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Textarea (props) {
    const { label, name , formik , ...rest } = props
    return (
        <div className='form-row'>
            { label && <label htmlFor={name}>{label}</label> }
            <Field 
            as='textarea' 
            id={name} 
            name={name} 
            {...rest} 
            className={`${formik.touched[name] && formik.errors[name] ? 'border-red' : null } resize-none h-[120px]`}
            />
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default Textarea
