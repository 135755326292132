import { useState } from "react";
import Cam from "./Cam";


const UploadSelfie = ({ setImage , image }) => {
    const [showCam , setShowCam] = useState(false);

    return (
        <div 
        className='flex-1 w-full h-full border-2 hover:border-primary rounded-lg relative flex items-center justify-center'
        
        >
            {
                image 
                ? 
                    <img src={image} alt={'Selfie'} className='w-full h-full object-cover rounded-lg' />
                : 
                    <div className='flex flex-col gap-3 sm:h-[300px] h-[250px] items-center justify-center'>
                        <label className="label">
                            Upload Your Selfie*
                        </label>
                        <button 
                        className="bg-primary text-white rounded-full text-sm  py-2 px-4" 
                        onClick={() => {
                            setShowCam(true)
                        }}
                        type='button'
                        >
                            Click Photo
                        </button>
                    </div>
            }
            {
                image && <div 
                className='absolute top-2 right-2 bg-white w-[40px] h-[40px] rounded-full flex items-center justify-center text-black border border-primary cursor-pointer hover:bg-primary hover:text-white'
                onClick={() => setShowCam(true)}
                >
                    <i className="uil uil-pen"></i>
                </div>
            }

            {
                showCam && <Cam
                setImage={setImage} 
                setShowCam={setShowCam} 
                />
            }
        </div>
    )
}

export default UploadSelfie