import React from 'react'

const PhoneSvg = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2734 19.6017C8.33388 19.6156 0.789818 11.9819 0.800304 2.12856C0.800304 1.48426 1.32227 0.959961 1.96541 0.959961H5.04129C5.61802 0.959961 6.10853 1.38406 6.19358 1.95496C6.39643 3.32402 6.79468 4.6569 7.37616 5.91283L7.49617 6.17148C7.57678 6.34553 7.59412 6.54227 7.5452 6.72774C7.49628 6.91321 7.38417 7.0758 7.2282 7.18745C6.27514 7.86787 5.91162 9.23687 6.6538 10.3041C7.5882 11.6479 8.75442 12.8145 10.0979 13.7493C11.1663 14.4903 12.5353 14.1268 13.2145 13.1749C13.326 13.0185 13.4888 12.906 13.6745 12.8568C13.8603 12.8077 14.0573 12.825 14.2316 12.9058L14.4891 13.0246C15.7451 13.6072 17.078 14.0056 18.447 14.2084C19.0179 14.2934 19.442 14.7839 19.442 15.3618V18.4365C19.442 18.5896 19.4118 18.7413 19.3532 18.8827C19.2946 19.0241 19.2087 19.1526 19.1003 19.2608C18.992 19.369 18.8634 19.4548 18.722 19.5133C18.5805 19.5718 18.4288 19.6018 18.2757 19.6017H18.2734Z" fill="url(#paint0_linear_34_431)"/>
<defs>
<linearGradient id="paint0_linear_34_431" x1="-2.47551" y1="5.98832" x2="19.5009" y2="6.31467" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
</defs>
</svg>

        </div>
    )
}

export default PhoneSvg