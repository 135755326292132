import React from 'react'
import AvailabilityDisplay from './AvailabilityDisplay'

const PickupInstructions = ({ carDetails }) => {
    return (
        <div>
            <h3 className='text-lg font-semibold'>Pickup Instructions</h3>
            {
                carDetails?.pickupInstructions?.length > 0 
                ?
                    <p className='text-sm mt-1 '>{carDetails?.pickupInstructions || ''}</p>
                : 
                    <p className='text-red-500 text-[13px] mt-2'>No Pickup Instructions</p>
            }
            {/* <ul className='mt-2 flex flex-col gap-2'>

                <li className='flex items-center gap-4 text-sm text-gray-600'>
                    <h6 className='min-w-[100px] font-medium'>Min Duration : </h6>
                    <p>
                        {carDetails?.minRentalDuration} Hr
                    </p>
                </li>
                <li className='flex items-center gap-4 text-sm text-gray-600'>
                    <h6 className='min-w-[100px] font-medium'>Max Duration : </h6>
                    <p>
                        {carDetails?.maxRentalDuration} Days
                    </p>
                </li>
                <li className='flex items-center gap-4 text-sm text-gray-600'>
                    <AvailabilityDisplay 
                    carDetails={carDetails}
                    />
                </li>
                
                
            </ul> */}
        </div>
    )
}

export default PickupInstructions