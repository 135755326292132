import React, { memo, useRef } from "react";
import { StandaloneSearchBox, LoadScript , useJsApiLoader } from "@react-google-maps/api";

const PlacesInput = ({ setLocation , label }) => {
    const inputRef = useRef();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['places'],
    });

    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            setLocation({
                lat:  place.geometry.location.lat(),
                lng:  place.geometry.location.lng() ,
                address:  place.formatted_address
            })
        } 
    }

    const handleInput = (event) => {
        if (event.target.value === "") {
            setLocation({
                lat : '' ,
                lng : ''
            });
        }
    };


    return (
        // <LoadScript 
        // googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} 
        // libraries={["places"]}
        // >
        isLoaded &&
            <StandaloneSearchBox
                onLoad={ref => inputRef.current = ref}
                onPlacesChanged={handlePlaceChanged}
            >
                <div className='flex flex-col gap-1.5 flex-1 w-full'>
                    {
                        label && 
                        <label className='font-semibold text-gray-600 '>
                            {label}
                        </label>
                    }
                    <input
                        type="text"
                        className="input w-full "
                        placeholder="Enter Location"
                        onInput={handleInput}
                    />
                </div>
            </StandaloneSearchBox>
        // </LoadScript>
    );
};

export default memo(PlacesInput);
