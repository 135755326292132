import Breadcrumbs from 'components/global/Breadcrumbs'
import React from 'react'

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Shipping And Delivery",  }
]

const ShippingAndDelivery = () => {
    return (
        <div className='flex items-center justify-center flex-col px-3 ' >
            <div className='bg-white rounded-md py-6 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-center text-gradient mt-6">
                    Shipping And Delivery
                </h1>
            </div>
            <div className='xl:w-[60%] lg:w-[70%] md:w-[80%] w-[95%] mx-auto'>
                <div className="WordSection1">
                    <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                        <u>
                            <span style={{ textDecoration: "none" }}>&nbsp;</span>
                        </u>
                        </b>
                    </p>
                    <p className="MsoNormal mb-4" style={{ textAlign: "justify" }}>
                        <b>
                        <u>
                            <span
                            lang="EN-IN"
                            style={{
                                fontSize: "14.0pt",
                                lineHeight: "25px",
                                fontFamily: 'poppins'
                            }}
                            >
                            LEGAL DISCLAIMER
                            </span>
                        </u>
                        </b>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        :{" "}
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY
                        ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE AMENDED PROVISIONS
                        PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
                        INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A
                        COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                        <br />
                        THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
                        OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT
                        REQUIRE PUBLISHING THE RULES AND REGULATIONS, TERMS OF SERVICES, PRIVACY
                        POLICY AND USER AGREEMENT FOR ACCESS OR TERMS OF USAGE OF{" "}
                        <b>
                            <u>www.Rent N Drive.co.in</u>
                        </b>
                        (HEREINAFTER REFERRED TO AS THE “SITE”).
                        <br />
                        YOUR USE OF THIS SITE, OR YOUR PROVISION TO USE OF ANY PERSONAL
                        INFORMATION CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND ANY SUBSEQUENT
                        CHANGES TO THESE TERMS; DO NOT USE THE SITE OR PROVIDE INFORMATION IF YOU
                        DO NOT AGREE WITH ALL OF THE TERMS.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        &nbsp;
                        </span>
                    </p>
                    <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                        <u>
                            <span
                            lang="EN-IN"
                            style={{
                                fontSize: "14.0pt",
                                lineHeight: "25px",
                                fontFamily: 'poppins'
                            }}
                            >
                            Obligations of the User
                            </span>
                        </u>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        By accepting the Terms as set out herein, the User hereby authorizes Rent
                        N Drive to obtain the User's driving records from the jurisdiction in
                        which the User is licensed.
                        <br />
                        <br />
                        User shall be responsible for providing and maintaining their account
                        information with Rent N Drive including details such as their current
                        email, mobile number, address, and such other information as may be
                        necessary for the purposes of this agreement.
                        <br />
                        <br />
                        Users shall be responsible for keeping any Rent N Drive-related password
                        and/or PIN numbers in strict confidence and to not make passwords or PINs
                        available to third-parties,. Should a PIN or password be reduced to
                        written or electronic form, the User will be responsible for any
                        associated costs and/or damages in connection with unauthorized use by
                        third-parties. If a User has reason to believe that a third-party may have
                        obtained unauthorized knowledge of a password and/or PIN, User agrees to
                        change the compromised password and/or PIN as per the procedure provided
                        on the Website.
                        <br />
                        <br />
                        When using a vehicle, every User must follow the owner's manual
                        instructions provided in the vehicle’s glove compartment. If a problem
                        arises that prevents or limits the use of the vehicle or that may
                        compromise people's safety, every User must immediately notify Rent N
                        Drive and follow the instructions provided by Rent N Drive.
                        <br />
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Controls exercised by Rent N Drive during the duration of vehicle rental:
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        It may be noted that during the period of vehicle rental, the effective
                        control of vehicle shall remain with Rent N Drive. The control may be
                        exercised in the event of violation of any of the terms and conditions set
                        out in this agreement. Further, it may be noted that all vehicles of Rent
                        N Drive are fitted with GPS trackers, speed trackers and can be remotely
                        accessed.
                        <br />
                        <br />
                        Therefore, in the event of any breach of terms and conditions of this
                        agreement, Rent N Drive can exercise the controls and shut down the
                        vehicle by way of remote access.
                        <br />
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Vehicle Pick-Up and Return
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Prior to taking possession of a Rent N Drive Vehicle, it shall be the
                        responsibility of every User to do an exterior walk-around and an interior
                        look-over of the vehicle
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        User's shall be permitted to test drive the Rent N Drive Vehicle to ensure
                        that there is no damage or abnormality encountered in the operation of the
                        vehicle. In case such an abnormality or damage is found, User shall be
                        required to intimate Rent N Drive of any such damage or abnormality
                        encountered on the vehicle or in the operation of the vehicle prior to
                        taking possession of such vehicle. Users are not allowed to take test
                        drive before booking.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        It shall be the responsibility of the User to pick up the selected Rent N
                        Drive Vehicle and return it secured, clean, and in good working order, at
                        the same place, by no later than the end time of the reservation.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Fuel Charges
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Rent N Drive shall make the payment of fuel to the third party suppliers
                        (i.e. relevant supplier of fuel or petrol stations) on behalf of the User;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Rent N Drive shall charge the actual expenses incurred towards fuel cost
                        from the Users and there shall be no mark-up on the same;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Rent N Drive doesn’t holds or intend to hold any title of such fuel
                        expenses and also doesn’t intend to use it for its own purpose. Thus, the
                        same is exclusively procured on behalf of User.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Vehicle Condition
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The User is required to report to Rent N Drive immediately any condition
                        that impairs the driving functionality and/or safety of the vehicle, such
                        as performance changes, inappropriate noises, smells or driving feel,
                        including but not limited to warning lamps, indicators, poor driving feel
                        or external or internal damage that renders the vehicle unsafe, including
                        but not limited to, missing or inoperable signal or driving lighting,
                        broken or missing rear-view mirrors and windshield glass etc.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Before performing jump start on any vehicle, the User must inform Rent N
                        Drive. The User shall bear the liability for any losses arising out of a
                        jump start
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The User must leave the vehicle’s key, key fob, or other starting device
                        to the vehicle and the parking pass or toll payment pass in its proper
                        position in the vehicle at the conclusion of the reservation or hand it
                        over to the authorized representative of Rent N Drive. In the event that
                        the User fails to leave the key/fob/starting device, parking pass, or toll
                        payment pass (if applicable) in the vehicle, the same shall be informed to
                        Rent N Drive immediately. If the User fails to do so and this causes
                        inconvenience to another User, such User shall be charged the hourly rate
                        and late fees (as outlined in the Fee Policy) for the vehicle until the
                        key/key fob/starting device/parking pass/toll payment pass is returned and
                        the User will remain responsible for the vehicle during such period. The
                        User shall also be charged a fee at Rent N Drive’s sole discretion, to
                        replace any of the items missing from the vehicle.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Reserving Users are responsible for all charges and costs incurred related
                        to the Rent N Drive Vehicle for the entire period of the reservation and
                        until the vehicle is returned, secured, closed, locked and serviceable
                        (all accessories off, key out of ignition and in proper place, all
                        windows, doors, hatches, sunroofs and other openings closed, toll payment
                        pass and parking pass and other accessories in the vehicle) to its
                        designated return location. Rent N Drive vehicles must be returned no
                        later than the end time of the reservation and the User will be required
                        to terminate the reservation by placing a call to Rent N Drive’s provided
                        number to notify of such intent.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        In the event that a Rent N Drive Vehicle is returned late, the Reserving
                        User will be responsible for paying late fees, inconvenience fee and any
                        other charges applicable as per Rent N Drive’s Fee Policy, from the
                        scheduled end time of the booking till the time the vehicle is recovered
                        by Rent N Drive. The Users will be responsible for any and all costs,
                        charges, fees and expenses incurred by Rent N Drive or any third party as
                        a result of a breach of any of these Terms.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The owner is responsible for any costs that can be attributed to
                        mechanical failure due to normal usage for which the vehicle was designed
                        for and customer will be charged or obliged to get it repaired if the
                        damage is not due to normal usage.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        Stolen vehicles Information about stolen vehicles or stolen parts of the
                        Rent N Drive Vehicle must be immediately reported by the User to the
                        nearest police station with a detailed written complaint based on actual
                        facts and a copy of the acknowledged complaint should be provided by the
                        User to Rent N Drive. Users shall ensure that such information about lost
                        vehicles shall not contain any discrepancy, inconsistencies or distortions
                        from actual facts as the same would be detrimental to a valid insurance or
                        other claim by Rent N Drive. The User will be liable for any loss due to a
                        discrepant, inconsistent or distorted complaint by the User.
                        <br />
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Safety
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Seat Belts and Child Restraints - User is responsible for:
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Complying with all applicable seat belt and child restraint laws;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Protecting all children by properly using any child passenger restraint
                        system that complies with the Motor Vehicle Safety Standards in the
                        jurisdiction where the trip is initiated and where the Rent N Drive
                        vehicle is driven during such trip.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Each time the User parks a Rent N Drive Vehicle (either at the end of the
                        reservation or during the time of reservation), the User shall be
                        responsible for securing the vehicle in conditions such as closing of
                        windows and locking the car.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Breakdown or Incidents
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        All breakdowns or incidents pertaining to Rent N Drive Vehicles must be
                        reported to Rent N Drive.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        In case of an incident involving property damage or involving any third
                        party, the User must fill out an official police report form, and, if
                        possible, provide a jointly agreed-upon statement, complete Rent N Drive’s
                        incident report form, and obtain the following information:
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Date, time, and place of incident;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The license plate numbers of any other vehicles involved, their make and
                        year, their identification number (serial number), and the insurance
                        certificate's number (with name, address and phone number of the insurance
                        agent);
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The names, addresses, driver's license numbers of the persons involved in
                        the incident;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The name, address, and driver's license number of the owner of the
                        vehicle;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The name, addresses, phone number of witnesses, passengers, other involved
                        persons;
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Circumstances of the incident describing immediate surrounding environment
                        and car position prior to the incident; and
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        <br />
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Traffic Violations
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        All traffic violations by a User shall be notified to the authorized
                        representative of Rent N Drive as soon as possible within the prescribed
                        deadline for the violation.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The User shall further notify Rent N Drive of any traffic violation
                        notices found on a vehicle at the time of vehicle’s delivery to User.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        All traffic violations will be the responsibility of the User if they
                        occur during the time period during which the User is responsible for the
                        Rent N Drive vehicle. In the event of any fines or penalties imposed by
                        any Governmental Authority for traffic violations, the same shall be borne
                        by the Reserving Users.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        The User shall not leave a vehicle in a zone which has parking
                        restrictions. If the User leaves the vehicle in such a restricted zone,
                        the User must immediately notify Rent N Drive, and shall be responsible
                        for any and all violation notices or towing charges incurred by Rent N
                        Drive.
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        Roadside Assistance
                        </span>
                    </p>
                    <p className="MsoNormal" style={{ textAlign: "justify" }}>
                        <span
                        lang="EN-IN"
                        style={{
                            fontSize: "14.0pt",
                            lineHeight: "25px",
                            fontFamily: 'poppins'
                        }}
                        >
                        <br />
                        Rent N Drive will help you to get roadside assistance support as and when
                        required.
                        </span>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default ShippingAndDelivery