import React from 'react'

const CompanyGoals = () => {
    return (
        <div className="mt-16">
            <h1 className="text-center section-heading text-gradient">
                Company Goals
            </h1>
            <div className="">
                <div className="grid grid-cols-1 sm:gap-10 gap-6 mt-12  md:w-[70%] sm:w-[85%] w-full">
                    <div>
                        <h4 className='font-bold text-lg'>
                            Increase Revenue:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Achieve a specific percentage increase in total revenue over the next fiscal year.
                            </p>
                            <p>
                                <b>Strategy: </b>Implement targeted marketing campaigns, explore additional revenue streams such as partnerships with hotels or airlines, and optimize pricing strategies.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Customer Acquisition and Retention:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Increase the number of new customers by a certain percentage and improve customer retention rates.
                            </p>
                            <p>
                                <b>Strategy: </b>Offer loyalty programs, improve customer service, and conduct customer satisfaction surveys to understand and meet customer needs.

                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                            Fleet Expansion:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Expand the fleet size by a specific number or percentage.
                            </p>
                            <p>
                                <b>Strategy: </b>Regularly assess market demand, invest in new vehicles, and consider adding specialized vehicles to cater to niche markets.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                            Operational Efficiency:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Improve operational efficiency by reducing turnaround time for vehicle maintenance, cleaning, and customer service.
                            </p>
                            <p>
                                <b>Strategy: </b>Invest in technology solutions, streamline processes, and provide staff training to enhance efficiency.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Online Booking Growth:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Increase the percentage of bookings made through the company's online platform.
                            </p>
                            <p>
                                <b>Strategy: </b>Enhance the user experience on the website, invest in online marketing, and offer exclusive online booking discounts.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Safety and Compliance:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Achieve and maintain a high safety record with zero accidents and full compliance with industry regulations.
                            </p>
                            <p>
                                <b>Strategy: </b>Implement rigorous safety protocols, provide ongoing training for drivers, and stay updated on industry compliance standards.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Environmental Sustainability:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Introduce and promote a certain percentage of eco-friendly or electric vehicles in the fleet.

                            </p>
                            <p>
                                <b>Strategy: </b>Invest in green technology, promote eco-friendly driving habits, and market the company's commitment to environmental sustainability.

                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Market Penetration:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Increase market share in specific target locations or customer segments.

                            </p>
                            <p>
                                <b>Strategy: </b>Conduct market research, tailor marketing efforts to specific demographics, and consider partnerships with local businesses.

                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Customer Feedback and Improvement:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Maintain a high customer satisfaction score and actively address areas for improvement based on customer feedback.

                            </p>
                            <p>
                                <b>Strategy: </b>Regularly gather customer feedback, conduct surveys, and implement changes based on suggestions to enhance the overall customer experience.

                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                        Technology Integration:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Implement cutting-edge technology solutions to improve the booking process, vehicle tracking, and customer communication.
                            </p>
                            <p>
                                <b>Strategy: </b>Invest in advanced reservation systems, implement GPS tracking for fleet management, and explore emerging technologies in the industry.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                            Employee Satisfaction and Training:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Improve employee satisfaction and expertise through ongoing training programs.
                            </p>
                            <p>
                                <b>Strategy: </b>Provide training on customer service, safety protocols, and new technologies, and foster a positive work environment.
                            </p>
                        </div>
                    </div>
                    <div>
                        <h4 className='font-bold text-lg'>
                            Brand Visibility:
                        </h4>
                        <div className="ml-4 mt-3 flex flex-col gap-2 text-justify">
                            <p>
                                <b>Goal: </b>Increase brand visibility both online and offline.

                            </p>
                            <p>
                                <b>Strategy: </b>Utilize social media, sponsor local events, and implement effective branding strategies to raise awareness and attract more customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyGoals