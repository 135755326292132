const faqsData = [
    {
      question: "Where can I find Self Drive Cars in India?",
      answer: "You can find Self Drive Cars in India through various local rental agencies and online platforms. These services offer a range of vehicles to choose from."
    },
    {
      question: "How can I get the cheapest Self Drive car in India?",
      answer: "To find the cheapest Self Drive car in India, compare rates from different rental agencies, look for discounts, and consider booking in advance."
    },
    {
      question: "Can I rent a car with a driver in India?",
      answer: "Yes, many rental services in India offer cars with drivers for a more comfortable and stress-free experience."
    },
    {
      question: "Is it possible to rent a luxury car in India?",
      answer: "Absolutely. Luxury car rentals are available in India, offering a range of high-end vehicles for those seeking a premium driving experience."
    },
    {
      question: "How do I find the cheapest car rental options in India?",
      answer: "To find the cheapest car rental options in India, compare prices from different agencies, look for special offers, and consider booking online to secure the best rates."
    }
];

module.exports = faqsData;
  