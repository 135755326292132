import React from 'react'

const FlySvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.0001 5L24.1668 35C24.0937 35.1596 23.9763 35.2948 23.8285 35.3896C23.6808 35.4844 23.509 35.5348 23.3335 35.5348C23.1579 35.5348 22.9861 35.4844 22.8384 35.3896C22.6906 35.2948 22.5732 35.1596 22.5001 35L16.6668 23.3333L5.00012 17.5C4.84055 17.4269 4.70533 17.3095 4.61053 17.1618C4.51573 17.014 4.46533 16.8422 4.46533 16.6667C4.46533 16.4911 4.51573 16.3193 4.61053 16.1716C4.70533 16.0239 4.84055 15.9065 5.00012 15.8333L35.0001 5Z" stroke="url(#paint0_linear_34_240)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<defs>
<linearGradient id="paint0_linear_34_240" x1="-0.900382" y1="13.2364" x2="35.0966" y2="13.7709" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
</defs>
</svg>

    )
}

export default FlySvg