import React from 'react'

const BulbSvg = () => {
    return (
        <div>
            <svg width="50" height="55" viewBox="0 0 59 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.6573 49.1071C22.9404 49.2863 22.4028 49.4655 21.8651 49.824C21.1482 48.7486 20.6106 47.4941 19.8937 46.598C18.6391 44.8058 17.2054 43.1928 15.9508 41.4006C13.9794 38.7122 12.3664 36.0239 11.6495 32.6187C10.0365 24.1953 14.6962 15.9511 22.7612 12.7251C33.3353 8.42375 45.5224 15.5926 47.1354 26.8836C47.8523 32.081 46.2393 36.3823 43.3717 40.5044C42.1172 42.2967 40.5042 44.0889 39.2496 46.0603C38.3535 47.3149 37.8159 48.5694 36.9198 49.824C36.5613 50.3616 36.2029 50.8993 35.6652 51.0785C31.5431 52.6915 27.421 54.1253 23.2989 55.5591C22.7612 55.7383 22.4028 55.5591 22.2236 55.2006C22.0443 54.663 22.0444 54.1253 22.582 53.9461C22.9405 53.7669 23.2989 53.5876 23.6573 53.5876C27.2417 52.3331 30.8262 51.0785 34.4106 49.6448C34.7691 49.4655 35.1275 49.1071 35.3067 48.7486C36.7405 46.0603 38.5328 43.9097 40.325 41.4006C42.1172 39.0707 43.7302 36.92 44.447 34.0525C46.2393 28.3174 44.9847 23.2992 41.0418 18.9978C36.5613 13.9796 30.8262 12.3666 24.3742 14.3381C17.9223 16.3095 14.3378 20.7901 13.0832 27.4213C12.1871 32.081 13.8001 36.0239 16.4884 39.6083C18.1014 41.759 19.7145 43.9097 21.3275 46.0603C22.4028 46.7772 22.9404 47.8525 23.6573 49.1071Z" fill="#2BB79D"/>
<path d="M22.7613 66.8499C22.5821 66.6706 22.2236 66.4914 22.2236 66.133C22.2236 65.7745 22.4029 65.4161 22.5821 65.0577C22.5821 64.8784 22.9405 64.8784 23.1197 64.8784C27.0626 63.4447 31.1847 62.0109 35.1276 60.5771C36.0237 60.2187 36.3821 60.3979 36.5614 60.9356C36.7406 61.4732 36.3821 62.0109 35.6653 62.3693C31.5432 63.8031 27.6003 65.2369 23.4782 66.8499C23.299 66.6706 23.1197 66.6706 22.7613 66.8499Z" fill="#2BB79D"/>
<path d="M22.7613 60.9358C22.5821 60.7566 22.2236 60.5774 22.2236 60.2189C22.2236 59.8605 22.4029 59.502 22.5821 59.1436C22.5821 58.9644 22.9405 58.9644 23.1197 58.9644C27.0626 57.5306 31.1847 56.0968 35.1276 54.663C36.0237 54.3046 36.3821 54.4838 36.5614 55.0215C36.7406 55.5592 36.5614 56.0968 35.6653 56.4553C31.5432 57.889 27.6003 59.502 23.4782 60.9358C23.299 60.9358 23.1197 60.9358 22.7613 60.9358Z" fill="#2BB79D"/>
<path d="M35.6652 65.9541C34.4106 70.2554 27.421 72.7645 23.1196 70.4346C27.2417 69.0009 31.3638 67.3879 35.6652 65.9541Z" fill="#2BB79D"/>
<path d="M30.2886 3.22599C30.2886 3.94288 30.2886 4.65977 30.2886 5.37665C30.2886 5.91432 29.9301 6.27276 29.3924 6.27276C28.8548 6.27276 28.4963 5.91432 28.4963 5.37665C28.4963 3.94288 28.4963 2.5091 28.4963 0.896109C28.4963 0.358443 28.8548 0 29.3924 0C29.9301 0 30.2886 0.358443 30.2886 0.896109C30.2886 1.613 30.2886 2.5091 30.2886 3.22599Z" fill="#2BB79D"/>
<path d="M3.22597 28.3174C3.94286 28.3174 4.65977 28.3174 5.37665 28.3174C6.09354 28.3174 6.45199 28.6758 6.45199 29.2135C6.45199 29.7512 6.09354 30.1096 5.37665 30.1096C3.94288 30.1096 2.50912 30.1096 1.07534 30.1096C0.358454 30.1096 0 29.7512 0 29.2135C0 28.6758 0.358454 28.3174 1.07534 28.3174C1.79223 28.3174 2.50908 28.3174 3.22597 28.3174Z" fill="#2BB79D"/>
<path d="M55.5588 30.2883C54.8419 30.2883 54.125 30.2883 53.4081 30.2883C52.8704 30.2883 52.512 29.9299 52.512 29.3922C52.512 28.8545 52.8704 28.4961 53.4081 28.4961C54.8418 28.4961 56.4548 28.4961 57.8886 28.4961C58.4263 28.4961 58.7847 28.8545 58.7847 29.3922C58.7847 29.9299 58.4263 30.2883 57.8886 30.2883C56.9925 30.2883 56.2756 30.2883 55.5588 30.2883Z" fill="#2BB79D"/>
<path d="M8.96131 7.88574C9.31975 8.06496 9.49896 8.24419 9.67819 8.42341C10.7535 9.49874 11.6496 10.3948 12.725 11.4702C13.2626 12.0078 13.2626 12.3663 12.725 12.904C12.3665 13.2624 11.8288 13.2624 11.2912 12.904C10.2158 11.8286 9.14054 10.9325 8.24443 9.85718C8.06521 9.67796 7.88598 9.14029 8.0652 8.78185C8.24442 8.24419 8.78209 8.06496 8.96131 7.88574Z" fill="#2BB79D"/>
<path d="M46.4186 45.3428C46.777 45.522 46.9562 45.7012 47.1354 45.8804C48.2108 46.7765 49.1069 47.8519 50.1822 48.748C50.7199 49.2857 50.7199 49.8233 50.3615 50.1818C50.003 50.5402 49.4653 50.5402 48.9276 50.0025C47.8523 48.9272 46.9562 48.0311 45.8809 46.9558C45.7017 46.7766 45.5225 46.2389 45.7017 45.8804C45.7017 45.7012 46.0601 45.522 46.4186 45.3428Z" fill="#2BB79D"/>
<path d="M50.7199 8.96156C50.5407 9.32 50.3615 9.49922 50.1822 9.67844C49.1069 10.7538 48.2108 11.6499 47.1354 12.7252C46.777 13.0837 46.2394 13.2629 45.7017 12.7252C45.3433 12.3668 45.3433 11.8291 45.7017 11.2914C46.777 10.2161 47.6731 9.14078 48.7484 8.24467C48.9277 8.06545 49.4653 7.88623 49.8238 8.06545C50.1822 8.24467 50.3614 8.78233 50.7199 8.96156Z" fill="#2BB79D"/>
<path d="M8.96108 50.5409C8.78186 50.3617 8.42343 50.1825 8.2442 49.8241C8.06498 49.6448 8.2442 49.1072 8.2442 48.928C9.31954 47.8526 10.3949 46.5981 11.6494 45.5227C12.0079 45.1643 12.5455 45.1643 12.9039 45.5227C13.2624 45.8812 13.2624 46.4188 12.9039 46.7773C11.8286 47.8526 10.7533 48.928 9.67796 50.0033C9.49874 50.3617 9.31952 50.5409 8.96108 50.5409Z" fill="#2BB79D"/>
</svg>

        </div>
    )
}

export default BulbSvg