import User1Img from 'assets/reviews/soham.jpeg';
import User2Img from 'assets/reviews/rohidas.jpeg';
import User3Img from 'assets/reviews/pravin.jpeg';
import User4Img from 'assets/reviews/yashu.jpeg';
import User5Img from 'assets/reviews/muskan.jpeg';
import User6Img from 'assets/reviews/swapnil.jpeg';

export const reviewsData = [
    {
        id : 1 ,
        username : 'Soham' ,
        review : 'Renting a self-drive car was a breeze! The process was seamless, and the vehicle was in top-notch condition. Highly recommend for a hassle-free experience.',
        image : User1Img
    } ,
    {
        id : 2 ,
        username : 'Rohidas Chavan' ,
        review : 'Great selection of cars for self-drive rental. The pick-up and drop-off were quick, and the car was clean and well-maintained. Will definitely use this service again!',
        image : User2Img
    } ,
    {
        id : 3 ,
        username : 'Pravin' ,
        review : 'Outstanding service! The self-drive rental made our trip so much more flexible. The car was reliable, and the entire experience exceeded our expectations.',
        image : User3Img
    } ,
    {
        id : 4 ,
        username : 'Yashu' ,
        review : 'Smooth and efficient self-drive rental process. The customer service was excellent, and the car provided a comfortable and enjoyable ride. Will be a repeat customer for sure!',
        image : User4Img
    } ,
    {
        id : 5 ,
        username : 'Muskan' ,
        review : 'I had a fantastic experience renting a self-drive car. The staff was friendly, the vehicle was clean, and the entire process was straightforward. Will be my go-to choice for future rentals.',
        image : User5Img
    } ,
    {
        id : 6 ,
        username : 'Swapnil Pigale' ,
        review : 'The self-drive rental provided us with the flexibility we needed. The car was reliable, and the entire experience was smooth. Definitely recommend for anyone looking for a convenient way to explore',
        image : User6Img
    } ,
]