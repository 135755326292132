import Faq from 'components/home/Faqs'
import GetInTouch from 'components/home/GetInTouch'
import Hero from 'components/home/Hero'
import HowItWorks from 'components/home/HowItWorks'
import PageContent from 'components/home/PageContent'
import PopularCars from 'components/home/PopularCars'
import Testimonials from 'components/home/Testimonials'
import WhyChooseUs from 'components/home/WhyChooseUs'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setHomePageContent, setPopularCars, setSettings, setSlugData } from 'redux/reducers/homeReducer'
import fetcher from 'utils/fetcher'

const Home = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { slugData } = useSelector(state => state.home)

    const { isLoading , data } = useQuery(`fetch-home-data` , () => {
        return fetcher(`/home?slug=${slug}`)
    } ,
    {
        refetchOnWindowFocus : false 
    }
    );

    useEffect(() => {
        if(data) {
            const { data : { data : { popularCars , settings , homePageContent , slugData } } } = data;
            dispatch(setPopularCars(popularCars));
            dispatch(setSettings(settings)); 
            dispatch(setHomePageContent(homePageContent));
            dispatch(setSlugData(slugData))
        }
    }, [data]);

    return (
        <div>
            <Helmet>
                {
                    slugData?.pageTitle && <title>{slugData?.pageTitle}</title>
                }
                {
                    slugData?.metaTags?.length > 0 &&
                    slugData?.metaTags?.map((tag, index) => {
                        switch (tag.type) {
                            case 'og':
                                return (
                                    <meta key={index} property={tag?.property} content={tag?.content} />
                                );
                            case 'canonical':
                                return (
                                    <link key={index} rel={tag?.rel} href={tag?.href} />
                                );
                            default:
                                return (
                                    <meta key={index} name={tag?.name} content={tag?.content} />
                                );
                        }
                    })
                }
            </Helmet>
            <div>
                <Hero />
            </div>
            <div>
                <HowItWorks />
            </div>
            <div className='my-20'>
                <PopularCars 
                isLoading={isLoading}
                />
            </div>
            <div className='my-20'>
                <WhyChooseUs />
            </div>
            <div className='my-20'>
                <PageContent />
            </div>
            <div className='my-20'>
                <Faq />
            </div>
            <div className='mt-28'>
                <Testimonials />
            </div>
            <div className='my-20'>
                <GetInTouch 
                isLoading={isLoading}
                />
            </div>
        </div>
    )
}

export default Home