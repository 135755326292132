import React from 'react'

const Cover = ({ heading , image , date }) => {
    return (
        <div 
        className='w-full lg:h-[400px] h-[250px]'
        style={{
            backgroundImage : `url(${'https://www.coxandkings.co.uk/-/media/cox-and-kings/images/tours/destinations/indian-subcontinent/india/passage-through-rajasthan/header.jpg?w=1920&h=940'})`,
            backgroundSize : 'cover' ,
            backgroundRepeat :'no-repeat' ,
            backgroundPosition : 'right'
        }}
        >
            <div className='cover-clip-path bg-black bg-opacity-80 2xl:w-[55%] lg:w-[75%] w-full h-full flex flex-col  justify-center md:items-start md:pl-40 items-center px-4'>
                <h1 className='lg:text-3xl md:text-2xl text-xl sm:font-bold font-semibold text-pure !leading-[1.5] md:text-left text-center'>
                    Journey Through Our Latest Articles,
                    <br className='md:block hidden'/>
                    Updates, and Expert Opinions
                </h1>
                <p className='mt-4 text-gray-500 sm:text-base text-sm md:text-left text-center'>
                Highlight your most important or popular articles
                </p>
            </div>
        </div>
    )
}

export default Cover