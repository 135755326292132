import SmallCarImg from 'assets/images/smallCar.png';
import LocationLink from 'components/global/LocationLink';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReviewFormPopup from './ReviewFormPopup';


const BookingItem = ({ booking }) => {
    const [showReviewPopup , setShowReviewPopup] = useState(false);


    return (
        <div className='shadow-bg p-4 hover:border-primary'>
            <div className='flex gap-6 justify-between sm:flex-row flex-col'>
                {
                    booking?.car?.images && 
                    <div className='flex-[0.3] flex items-center'>
                        <img 
                        src={baseURL + '/cars/' + booking?.car?.images[0]} 
                        alt="" 
                        className='w-[200px] object-contain rounded-md'
                        />
                    </div>
                }
                <div className='flex-[0.4] flex flex-col gap-3'>
                    <div className='flex items-center gap-4'>
                        <RequestStatus
                        status={booking?.status}
                        />
                        
                    </div>
                    <Link 
                    to={`/vehicle-details/${booking?.car?._id}`} className='text-lg font-bold'
                    >
                        {booking?.car?.name}
                    </Link>
                    <div className='flex gap-8'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-user"></i>
                                <span className='text-xs'>{booking?.car?.seats} Seats</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-suitcase-alt"></i>
                                <span className='text-xs'>
                                    {booking?.car?.doors} door
                                </span>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-auto-flash"></i>
                                <span className='text-xs capitalize'>
                                    {booking?.car?.transmissionType}
                                </span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-dashboard"></i>
                                <span className='text-xs capitalize'>
                                    {booking?.car?.mileage} mileage
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div>
                            <span className="text-gray-600 text-sm">
                                Location : &nbsp;
                            </span>
                            <LocationLink
                            location={booking?.car?.location}
                            />
                        </div>
                        <span className="text-gray-600 text-xs ">
                            Type : {booking?.car?.vehicleType}
                        </span>
                        {
                            booking?.status === 'completed' && 
                            <div 
                            className='text-sm underline cursor-pointer'
                            onClick={() => setShowReviewPopup(true)}
                            >
                                Write A Review
                            </div>
                        }
                    </div>
                </div>
                <div className='flex-[0.3] flex items-end justify-between flex-col gap-4'>
                    <h4 className='text-base font-semibold'>
                        Date : {moment(booking?.createdAt).format('DD MMM YYYY hh:mm a')} 
                    </h4>
                    <Link 
                    to={`/bookings/booking-details/${booking?._id}`} 
                    className="btn-primary py-2 text-sm px-8"
                    >
                        View Details
                    </Link>
                </div>
            </div>
            {
                showReviewPopup && <ReviewFormPopup 
                showReviewPopup={showReviewPopup}
                setShowReviewPopup={setShowReviewPopup}
                carId={booking?.car?._id}
                />
            }
        </div>
    )
}

export default BookingItem