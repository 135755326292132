import React from 'react'

const MailSvg = () => {
    return (
        <div>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5778 4.26301L10.1211 8.92344L2.66446 4.26301V2.39884L10.1211 7.05927L17.5778 2.39884M17.5778 0.534668H2.66446C1.62985 0.534668 0.800293 1.36422 0.800293 2.39884V13.5839C0.800293 14.0783 0.996696 14.5524 1.3463 14.902C1.6959 15.2516 2.17006 15.448 2.66446 15.448H17.5778C18.0722 15.448 18.5464 15.2516 18.896 14.902C19.2456 14.5524 19.442 14.0783 19.442 13.5839V2.39884C19.442 1.90443 19.2456 1.43027 18.896 1.08067C18.5464 0.731071 18.0722 0.534668 17.5778 0.534668Z" fill="url(#paint0_linear_34_436)"/>
<defs>
<linearGradient id="paint0_linear_34_436" x1="-2.47551" y1="4.55736" x2="19.4982" y2="4.96524" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
</defs>
</svg>

        </div>
    )
}

export default MailSvg