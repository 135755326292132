import Input from 'components/global/Input'
import Axios from 'config/api';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const VerifyOtp = () => {
    const navigate = useNavigate();
    const { sessionId } = useParams();
    const [searchParams] = useSearchParams();
    const { user } = useSelector(state => state.auth);
    const [otp , setOtp] = useState('');
    const [loading , setLoading] = useState(false);


    useEffect(() => {
        const step1 = localStorage.getItem('step1');
        if(!step1 || step1 !== 'completed') {
            navigate('/kyc/step1');
        }
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const body = { otp , sessionId }
            const { data : { data : { message } } } = await Axios.post(`/kyc/verify-otp` , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            localStorage.setItem('kycVerified' , true );
            toast.success(message);
            if(searchParams.get('ref') === 'book-now') {
                navigate(`/kyc/add-details?ref=${searchParams.get('ref')}&vehicle=${searchParams.get('vehicle')}` , { replace : true });
            }else {
                navigate(`/kyc/add-details` , { replace : true });
            }
            setLoading(false);
        } catch (error) {
            setOtp('')
            setLoading(false);
            toastError(error);
        }
    }


    // Timer
    const [disableRetry, setDisableRetry] = useState(true);
    const [retryTimer, setRetryTimer] = useState(60);

    useEffect(() => {
        let timer;
        if (disableRetry) {
            timer = setInterval(() => {
                setRetryTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [disableRetry]);

    useEffect(() => {
        if (retryTimer === 0) {
            setDisableRetry(false);
            setRetryTimer(60); // Reset timer to 60 seconds
        }
    }, [retryTimer]);

    const retryHandler = () => {
        if(searchParams.get('ref') === 'book-now') {
            navigate(`/kyc/step1?ref=${searchParams.get('ref')}&vehicle=${searchParams.get('vehicle')}` , { replace : true });
        }else {
            navigate(`/kyc/step1` , { replace : true });
        }
    }

    return (
        <div className='my-12 px-3 h-[40vh]'>      
            <div className="container mx-auto flex items-center justify-center">
                <div className=' md:w-[70%] sm:w-[80%] w-full'>
                    <h1 className="section-heading text-gradient">
                        Verify OTP
                    </h1>
                    <form 
                    className="mt-4 shadow-bg sm:p-4 py-4 px-2"
                    onSubmit={submitHandler}
                    >
                        <Input
                        label={'Enter OTP'}
                        placeholder={'OTP (One Time Password)'}
                        value={otp}
                        setValue={setOtp}
                        minLength={4}
                        maxLength={10}
                        required 
                        />
                        <div className="mt-4">
                            <button 
                            type="submit" 
                            className="btn-primary py-2 px-8"
                            disabled={loading || !otp}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'Verifiy'
                                }
                            </button>
                        </div>
                        <center className='mt-4'>
                            <button 
                            className="text-primary flex gap-2" 
                            disabled={disableRetry} onClick={retryHandler}
                            >
                                <span className='text-dark'>Did not get OTP? </span>
                                {
                                    disableRetry 
                                    ? 
                                        `Retry in ${retryTimer} seconds` 
                                    : 
                                        <div className='flex gap-1 hover:underline'>
                                            <span>Retry</span>
                                            <i className="uil uil-redo font-bold"></i>
                                        </div> 
                                }
                            </button>
                        </center>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp