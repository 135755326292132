import * as Yup from 'yup';

const kycValidations = Yup.object({
    licenseCardNumber :Yup.string()
    .matches(
        /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
        'Invalid driving license number'
    )
    .required('driving license number is required.') ,
    aadhaarCardFrontImage : Yup.string().required('Adhaar Card Front Image is required.') ,
    aadhaarCardBackImage : Yup.string().required('Adhaar Card Back Image is required.') ,
    licenseCardFrontImage : Yup.string().required('Driving License Front Image is required.') ,
    licenseCardBackImage : Yup.string().required('Driving License Back Image is required.') ,
    additionalDocumentType : Yup.string().required('Additional document type is required.') ,
    additionalDocumentImage : Yup.string().required('Additional Document Image is required.') ,

});

export default kycValidations;