import { baseURL } from 'config/api';
import { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';


const VehicleImagesPopup = ({ setShowImagesPopup , images }) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])

    return (
        <div className='fixed top-0 left-0 w-full bg-black bg-opacity-50 flex items-center justify-center px-3 h-screen z-50'>
            <div 
            className="popup bg-white lg:w-[50%] md:w-[65%] sm:w-[80%] w-full rounded-lg lg:px-32 md:px-28 sm:px-16 px-4 py-8 sm:pt-8 pt-10 relative"
            >
                <div 
                className='absolute top-2 right-2 text-2xl cursor-pointer font-semibold w-fit' 
                onClick={() => setShowImagesPopup(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                <Carousel
                autoPlay
                interval={2000}
                infiniteLoop
                className='w-full h-full'
                >
                    {
                        images?.map((image , index) => {
                            return <div key={index}>
                                <img 
                                src={baseURL + '/cars/' + image} 
                                alt={'vehicle' + index} 
                                className='w-auto h-auto max-h-[250px] sm:max-h-[400px] object-contain lg:max-h-[500px] rounded-md'
                                />
                            </div>
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default VehicleImagesPopup