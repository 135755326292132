import Breadcrumbs from 'components/global/Breadcrumbs'
import React from 'react'

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Privacy Policy",  }
]

const PrivacyPolicy = () => {
    return (
        <div className='flex items-center justify-center px-3 ' >
            <div className='bg-white rounded-md md:p-10 sm:p-6 p-4 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-center text-gradient pt-6">
                    Privacy Policy
                </h1>
                <div>
                    <>
                        <div>
                            <div>
                                <hr
                                    size={2}
                                    align="left"
                                    style={{
                                        width: "100%",
                                        textAlign: "justify",
                                        color: "#666666",
                                        backgroundColor: "#666666" , 
                                        marginTop : '16px'
                                    }}
                                />
                            </div>
                            <p
                                style={{
                                    marginTop: "0pt",
                                    marginBottom: "0pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px"
                                }}
                            >
                                <br />
                                <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                                    &nbsp;
                                </span>
                            </p>
                            <p
                                style={{
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC
                                    CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES
                                    MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC
                                    DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION
                                    TECHNOLOGY ACT, 2000. THIS PRIVACY POLICY DOES NOT REQUIRE ANY PHYSICAL,
                                    ELECTRONIC OR DIGITAL SIGNATURE.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "0pt",
                                    marginBottom: "0pt",
                                    textAlign: "justify",
                                    lineHeight: "normal"
                                }}
                            >
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    THE TERMS "WE/US/OUR/
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    " INDIVIDUALLY AND COLLECTIVELY REFER TO&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    INDIA PRIVATE LIMITED AND THE TERMS "YOU/YOUR/YOURSELF" REFER TO THE
                                    USERS/MEMBERS UNDER MEMBERHSIP AGREEMENT.
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    &nbsp;(BOTH TERMS DEFINED ABOVE).
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE
                                    PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES
                                    AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION) RULES, 2011
                                    UNDER INFORMATION TECHNOLOGY ACT, 2000; THAT REQUIRE PUBLISHING OF THE
                                    PRIVACY POLICY FOR COLLECTION, USE, STORAGE AND TRANSFER OF SENSITIVE
                                    PERSONAL DATA OR INFORMATION.
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THE WEBSITE, YOU
                                    INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY.
                                    IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
                                    USE THIS WEBSITE. YOU HEREBY PROVIDE YOUR UNCONDITIONAL &amp;
                                    IRREVOCALBLE CONSENT TO&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    FOR THE PURPOSES PRESCRIBED UNDER INCLUDING BUT NOT LIMITED TO
                                    PROVISIONS OF SECTIONS 43A, 72 AND SECTION 72A OF INFORMATION TECHNOLOGY
                                    ACT, 2000.
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    This Privacy Policy (the “Policy”) sets out how&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    collects, uses, protects &amp; shares any information that you give to
                                    us, when you use this website i.e. www.rentndrive.com including its
                                    m-application (the “Website”).&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    is committed to ensure that your privacy is protected to all possible,
                                    reasonable and commercial extent, as your privacy on the Internet is of
                                    the utmost importance to us. Because we gather certain types of
                                    information about You in order to provide, protect, maintain and improve
                                    our services, We feel You should fully understand the Policy surrounding
                                    the capture and use of that information and solicit Your full attention
                                    towards it.
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    By providing us your Information or by making use of the facilities
                                    provided by the Website, You hereby consent to the collection, storage,
                                    processing and transfer of any or all of Your Personal Information and
                                    Non-Personal Information by&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    , as specified under this Policy. You further represent and warrant that
                                    such collection, use, storage and transfer of Your Information shall not
                                    cause any loss or wrongful gain to you or any other person.
                                </span>
                                <br />
                                <br />
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    This Policy is a legally binding contract between You and&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        fontSize: "15px",
                                        color: "#666666",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    , whose Website, You use or access or You otherwise deal with. This
                                    Policy shall be read together with the other terms and condition of the
                                    Website viz, Membership Agreement and Fees Policy being displayed on the
                                    website
                                </span>
                                <br />
                                <a href="http://www.rentndrive.com." style={{ textDecoration: "none" }}>
                                    <u>
                                        <span
                                            style={{
                                                fontFamily: '"Segoe UI"',
                                                fontSize: "15px",
                                                color: "#0000ff",
                                                backgroundColor: "#ffffff"
                                            }}
                                        >
                                            www.rentndrive.com.
                                        </span>
                                    </u>
                                </a>
                                <br />
                                <br />
                                <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                                    &nbsp;
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Collection, Storage and Use of Personal Information:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    When You apply for or maintain an account with&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    , We collect certain personally identifiable information (“Personal
                                    Information”), such as: Your name, age, gender, photograph, contact
                                    preferences telephone number, mailing address, including but not limited
                                    to permanent and current residential addresses, e-mail address,
                                    financial information, internet protocol address, history of Your
                                    transactions ( booking and payment history), any other items of
                                    sensitive personal data or information, as such term is defined under
                                    the Information Technology (Reasonable Security Practices And Procedures
                                    And Sensitive Personal Data Of Information) Rules, 2011 enacted under
                                    the Information Technology Act, 2000, identification code of Your
                                    communication device which You use to access the Website, any other
                                    information that You provide during Your registration, use of availing
                                    of services via Website and other relevant documents viz; driving
                                    license and additional address cum identity proofs, as prescribed under
                                    the Membership Agreement of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    . By providing information to create a user account or complete Your
                                    user profile, You expressly and voluntarily accept this Policy and You
                                    shall be deemed to have voluntarily consented to authenticate Yourself
                                    with a government issued photo identity document which also contains
                                    your address, other than driver’s licence, (hereafter “Government-issued
                                    ID”) and hereby give Your voluntary consent for seeding identification
                                    details as provided in Government-issued ID to all Membership
                                    requirements and to provide Your identity information for authentication
                                    for the purpose of availing of the Services and to enforce any breach
                                    committed by you of the Memberships Agreement through police,
                                    government/enforcement authority or court of law.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    In pursuit of excellence in the services offered few other personal
                                    information that We collect includes the following:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Vehicle Use Data: Vehicles will contain hardware that gathers and
                                    transmits information about vehicle use. This is done as a security
                                    measure against accident or theft and also to provide You with valuable
                                    services and information, such as other drivers' data.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Location Tracking: To prevent theft, and to allow us to locate You in
                                    case of emergency, accident, lock-out, etc., We track the location of
                                    your vehicle. Your location information will be confined to&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    service, and we endeavour not to make your location or movements’ public
                                    unless it is required under personal, medical and legal (recovery and
                                    insurance) exigencies. As part of our service, the location of your
                                    vehicle may be released to insurance companies, the police, courts,
                                    tribunals or similar law enforcement agencies, in the course of an
                                    investigation and/or accident claim, and to provide assistance in
                                    emergencies. Information regarding the location of each vehicle on rent
                                    n drive Website is also transmitted to&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Driver and vehicle Information: As a member of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Website, you authorize us to access Your driver’s record, vehicle
                                    travelled history report pertaining to Your ride/trip from all
                                    applicable entities and authorities’. Moreover, You authorize all DMVs,
                                    RMVs, auto mechanics and all other entities and interested parties to
                                    release information to us regarding Your driving record and vehicle
                                    travel history.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    You have the right to request to&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    to discontinue the use of Your personal information. To withdraw Your
                                    consent to our collection and processing of Your information in future,
                                    You may do so by closing your account. However, the registered mobile
                                    number and transactional details shall be collected and stored in an
                                    anonymized manner data for accounting and security purposes. Should you
                                    choose to sign up your account using the existing number in our
                                    database, first time user (“FTU”) discount benefits shall not be
                                    applicable to YOU and/or on YOUR account. Please note that in case there
                                    is an outstanding payable at your end or if there is an ongoing/upcoming
                                    booking or listing on&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Platform made by you, to withdraw your consent or seek deletion of data
                                    collected or being processed by us you shall have to clear the payables
                                    or complete/cancel the booking/listing (as the case maybe).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    As you access and use our services we collect information such as, but
                                    not limited to - phone number, email address, device make-details and IP
                                    address. We may disclose to third party services like Get Simple certain
                                    information to ensure fraud prevention and PayLater check out
                                    experience. The information may also be disclosed to third party vendors
                                    like call centers and customer care vendors for the purpose of carrying
                                    out services as provided by&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    . Please refer to the third party privacy policy for more details.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Collection and Use of Non-Personal Information
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    We also collect non-personal information – data in a form that does not
                                    permit direct association with any specific individual, including
                                    browser You use, usage details and identifying technologies. We may use,
                                    transfer, collect and disclose non-personal information for any purpose.
                                    If We do combine non-personal information with personal information, the
                                    combined information will be treated as personal information for as long
                                    as it remains combined.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Aggregate Information: We may share non-personally identifiable
                                    information (such as referring/exit pages, anonymous usage data, and
                                    URLs, platform types, number of clicks, etc.) with interested third
                                    parties to help them understand the usage patterns for certain&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    services.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Third-party ad servers or ad networks may serve advertisements on the
                                    Website. These third-party ad servers or ad networks may automatically
                                    receive Your internet protocol address, when they serve ads to Your
                                    Internet browser. They may also use other technologies (such as cookies,
                                    JavaScript, or web beacons) to measure the effectiveness of their
                                    advertisements and to personalize the advertising content. However,
                                    please note that if an advertiser asks&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    to show an advertisement to a certain audience and you respond to that
                                    advertisement, the advertiser or ad-server may conclude that You fit the
                                    description of the audience they are trying to reach.&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Policy does not apply to, and we cannot control the activities of,
                                    third-party advertisers.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Cookies:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    We use various technologies, including “cookies”, to collect
                                    non-identifiable information. A cookie is a piece of data that any
                                    website can send to Your browser, which may then be stored on Your
                                    computer as an anonymous tag that identifies Your computer but not You.
                                    To enhance Our Service, some&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    pages use cookies, sent by&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    or its third party vendors, or other technologies. You may control the
                                    effect of cookies through Your browser settings, however some features
                                    of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Service may not work properly if Your use of cookies is disabled.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    We may also use Web beacon or other technologies, often in conjunction
                                    with cookies, to enhance Our Service on a number of pages of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Website. A non-identifiable notice of a visitor’s visit to a page
                                    on&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    site is generated and recorded, and which may be processed by us or by
                                    Our suppliers. To disable some of these features, You may disable
                                    cookies in Your web browser’s settings. Web beacon and other
                                    technologies will still detect visits to these pages, but the notices
                                    they generate are disregarded and cannot be associated with other
                                    non-identifiable cookie information.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Information Sharing:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    &nbsp;&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    only shares Personal Information with third parties in the following
                                    limited circumstances:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    i) To provide other businesses or persons for the purpose of processing
                                    personal information on our behalf. We require that these parties agree
                                    to process such information based on Our instructions and in compliance
                                    with this Policy and any other appropriate confidentiality and security
                                    measures.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    ii) We have a good faith belief that access, use, preservation or
                                    disclosure of such information is reasonably necessary to (a) satisfy
                                    any applicable law, regulation, legal process or enforceable
                                    governmental request including to law enforcement and in response to a
                                    court order, (b) detect, prevent, or otherwise address fraud, technical
                                    or security issues, (c) enforce applicable terms and conditions of the
                                    Website including but not limited to Membership Agreement, Privacy
                                    Policy and Fees Policy, which may be further extended to investigation
                                    of potential violations thereof, or (d) protect against harm to the
                                    rights, safety or property of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    , its Users or the public as required or permitted by law.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    iii) To protect and recover&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    assets viz; vehicles/ outstanding.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    iv) To protect Ourselves against third-party claims.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    v) We reserve the right to transfer any/all personal information We have
                                    about You in the event that We sell or transfer all or a portion of Our
                                    business or assets.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    vi) We partner with outside companies to provide You with additional
                                    services related to&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    , such as ride-sharing, referral programmes or parking benefits. When
                                    You purchase, register, or otherwise express interest in a product or
                                    service offered by a third party through&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    or sponsored on Our Website, You consent to Our sharing your personal
                                    information with those parties. Participation in rewards programs,
                                    discount offers, contests, or other programs that involve third parties,
                                    authorizes&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    to share Your personal information with those parties. A third party’s
                                    use of Your information is bound by contracts and agreements
                                    between&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    and the third party. We only share information reasonably needed to
                                    provide additional services, and such shared information may be bound by
                                    other privacy arrangements. By sharing this information, You agree
                                    that&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    is not liable or responsible for the actions or inactions of third
                                    parties.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    vii)&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    may transfer/disclose/share information to those parties who support Our
                                    business, such as providing technical infrastructure services, analysing
                                    how Our services are used, measuring the effectiveness of
                                    advertisements, providing customer / buyer services, facilitating
                                    payments, or conducting academic research and surveys.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Links to Third-Party Websites:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "0pt",
                                    marginBottom: "0pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px"
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: '"Segoe UI"',
                                        color: "#808080",
                                        backgroundColor: "#ffffff"
                                    }}
                                >
                                    This Website may contain links to third-party websites, products, and
                                    services. Information collected by third parties, which may include such
                                    things as location data or contact details, is governed by their privacy
                                    practices. We encourage You to learn about the privacy practices of
                                    those third parties. We cannot be responsible for these policies.
                                </span>
                                <br />
                                <br />
                                <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
                                    &nbsp;
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Information Security &amp; Retention:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    We take precautions – including administrative, technical and physical
                                    measures – to safeguard Your personal information against loss, misuse
                                    or theft, as well as against destruction, alteration, disclosure and
                                    unauthorized access. For this purpose,&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    adopts internal reviews of the data collection, storage and processing
                                    practices and security measures, including appropriate encryption and
                                    physical security measures to guard against unauthorized access to
                                    systems, where&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    stores Your Personal Information., provided that Your right to claim
                                    damages shall be limited to the right to claim only statutory damages
                                    under Information Technology Act, 2000 and You hereby waive and
                                    release&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    from any claim of damages under contract or under tort.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    When You use Our services or post on the Website, some of the Personal
                                    Information You share is visible to other users and can be read,
                                    collected, or used by them. You are responsible for such Personal
                                    Information You choose to submit in these instances. For example, if You
                                    list Your name and email address on the Website, that information is
                                    public. Further, You are responsible for maintaining the confidentiality
                                    of Your account and password and for restricting access to Your
                                    computer, and You agree to accept responsibility for all activities that
                                    occur under Your account or password.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    may share Your Information with third parties under a confidentiality
                                    arrangement, which inter alia provides for that such third parties not
                                    disclosing the Information further unless such disclosure is for the
                                    purposes defined under the respective confidentiality arrangements.
                                    However,&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    is not responsible for any breach of security or for any actions of any
                                    third parties that receive Your Personal Information.&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    is not liable for any loss or injury caused to You as a result of You
                                    providing Your Personal Information to third party (including any third
                                    party websites, even if links to such third party websites are provided
                                    on the Website).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Notwithstanding anything contained in this Policy or elsewhere,&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    shall not be held responsible for any loss, damage or misuse of Your
                                    Personal Information, if such loss, damage or misuse is attributable to
                                    a Force Majeure Event (as defined below).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    A "Force Majeure Event" shall mean any event that is beyond the
                                    reasonable control of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    and shall include, without limitation, sabotage, fire, flood, explosion,
                                    acts of God, civil commotion, strikes or industrial action of any kind,
                                    riots, insurrection, war, acts of government, computer hacking,
                                    unauthorised access to computer, computer system or computer network,
                                    computer crashes, breach of security and encryption (provided beyond
                                    reasonable commercial control of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    ), power or electricity failure or unavailability of adequate power or
                                    electricity.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    While We will endeavour to take all reasonable and appropriate steps to
                                    keep secure any Personal Information which We hold about You and prevent
                                    unauthorized access, You acknowledge that the internet or computer
                                    networks are not fully secure and that We cannot provide any absolute
                                    assurance regarding the security of Your Personal Information. Please
                                    exercise reasonable caution when determining what Personal Information
                                    You disclose via the Website.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    You agree that all Personal Information shall be retained till such time
                                    required for the purpose or required under applicable law, whichever is
                                    later. Non-Personal Information will be retained indefinitely.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Updates to the Policy:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Please note that this Policy may change from time to time. We will post
                                    any Policy changes on the Website and without prior notice to You.
                                    Please review the Policy each time You use the Website. By Your
                                    continued use of the Website, You impliedly consent to the terms of the
                                    revised/updated Policy, if any.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Grievance Officer:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    If You find any discrepancies or have any grievances in relation to the
                                    collection, storage, use, disclosure and transfer of Your Personal
                                    Information under this Policy or any terms of&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    Membership Agreement, Privacy Policy, Fees Policy etc. please reach out
                                    to our grievance officer at grievance.officer@rentndrive.com.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "12pt",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <strong>
                                    <span style={{ fontFamily: '"Times New Roman"', color: "#70ad4d" }}>
                                        Miscellaneous:
                                    </span>
                                </strong>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    While&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    may retain any correspondence sent by You, it does not intend to collect
                                    or save any information from children 15 years of age and younger.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: "14pt",
                                    marginBottom: "15pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    fontSize: "15px",
                                    backgroundColor: "#ffffff"
                                }}
                            >
                                <span style={{ fontFamily: '"Bookman Old Style"', fontSize: "10pt" }}>
                                    SAK RENT N DRIVE LUXURY PRIVATE LIMITED&nbsp;
                                </span>
                                <span style={{ fontFamily: '"Segoe UI"', color: "#666666" }}>
                                    reserves the right to remove or edit content, refuse service, terminate
                                    accounts, or cancel plans or orders at its sole discretion.
                                </span>
                            </p>
                            <p style={{ marginTop: "0pt", marginBottom: "8pt", textAlign: "justify" }}>
                                &nbsp;
                            </p>
                        </div>
                        <p style={{ bottom: 10, right: 10, position: "absolute" }}>
                            <a
                                href="https://wordtohtml.net"
                                target="_blank"
                                style={{ fontSize: 11, color: "#d0d0d0" }}
                            >
                                Converted to HTML with WordToHTML.net
                            </a>
                        </p>
                    </>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy