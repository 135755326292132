import { baseURL } from 'config/api';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setDocDetails } from 'redux/reducers/blogReducer';

const imgDir = '/blogs/'


const BlogCard = ({ blog }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateHandler = () => {
        dispatch(setDocDetails(blog));
        navigate(`/blogs/${blog?._id}`)
    }

    return (
        <div className='relative rounded-lg border bg-gray-100 sm:p-8 p-4 flex flex-col gap-6 hover:shadow-xl duration-200 ease-in-out group'>
            <div
            onClick={navigateHandler}
            className='cursor-pointer'
            >
                <img 
                src={baseURL + imgDir + blog?.image} 
                alt="Cover Image" 
                className='w-full h-[200px] object-cover rounded-lg' 
                />
            </div>
            <div className='flex items-center sm:gap-4 gap-2 flex-wrap'>
                {
                    blog?.tags.map(item => (
                        <div key={item} className='bg-green-200 text-green-700 py-1.5 px-2.5 rounded-md text-sm whitespace-nowrap'>
                            {item}
                        </div>
                    ))
                }
            </div>
            <h1 
            className='text-lg font-semibold text-black hover:underline cursor-pointer'
            onClick={navigateHandler}
            >
                {blog?.title}
            </h1>
            <p className='text-grayText'>
                {blog?.shortDescription}
            </p>

            <div className='flex items-center gap-8'>
                <div className='flex items-center gap-1 text-dark'>
                    <i className="uil uil-calendar-alt text-primary"></i>
                    <p>
                        {moment(blog?.createdAt).format('MMM DD YYYY')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogCard