import React from 'react'

const OurMission = () => {
    return (
        <div>
            <div className="my-16">
                <h1 className="text-center section-heading text-gradient">
                    Our Mission
                </h1>
                <div className='flex lg:flex-row flex-col-reverse gap-12 mt-12'>
                    <div className='flex-1 flex items-center justify-center'>
                        <img 
                        src={'https://southpressagency.com/wp-content/uploads/2020/07/Buying-a-Used-Car.jpg'} 
                        alt="our mission"
                        className='w-full rounded-md' 
                        />
                    </div>
                    <div className='flex-1 text-justify'>
                        At Rent N Drive, our mission is to empower seamless journeys, providing our customers with more than just a vehicle. We are dedicated to delivering exceptional experiences by offering reliable, flexible, and sustainable mobility solutions. Our commitment is to be the trusted partner in our customers' travels, consistently exceeding expectations through innovation, personalized service, and a steadfast dedication to safety and environmental responsibility. As we drive towards the future, we envision a world where every journey is an opportunity for adventure, comfort, and peace of mind, and where Rent N Drive is synonymous with excellence in the rental car industry.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurMission