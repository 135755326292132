import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import useClickOutside from 'utils/clickOutside';
import fetcher from 'utils/fetcher';
import ReactStars from "react-rating-stars-component";
import Pagination from 'components/global/pagination';

const ReviewsPopup = ({ setShowReviewsPopup , carId }) => {
    const reviewsPopupRef = useRef();
    const [reviews , setReviews] = useState([]);
    const [currentPage , setCurrentPage] = useState(1);
    const [pages , setPages] = useState(1);
    const [docsCount , setDocsCount] = useState(0);

    useClickOutside(reviewsPopupRef , () => setShowReviewsPopup(false));

    const queryKey = ['fetch-car-reviews' , currentPage , carId ]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/review/single-car-reviews/${carId}?page=${currentPage}`)
    }); 

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            setReviews(docs);
            setDocsCount(docsCount);
            setCurrentPage(page);
            setPages(pages);
        }
    } , [data]);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);


    return (
        <div className='fixed top-0 left-0 w-full bg-black bg-opacity-50 flex items-center justify-center px-3 h-screen z-50'>
            <div 
            className="authPopup bg-white lg:w-[50%] md:w-[65%] sm:w-[80%] w-full rounded-lg md:px-20 sm:px-16 px-4 py-8 relative"
            ref={reviewsPopupRef}
            >
                <div 
                className='absolute top-2 right-3 text-2xl cursor-pointer font-semibold w-fit ' 
                onClick={() => setShowReviewsPopup(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                <div>
                    <center>
                        <h1 className='text-xl font-semibold text-gradient'>
                            All Reviews
                        </h1>
                    </center>
                    <div className='flex flex-col gap-4 mt-8 md:max-h-[400px] max-h-[300px] overflow-scroll pb-8'>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        reviews?.length > 0 
                        ? 
                            <>
                                {
                                reviews?.map(item => (
                                    <div 
                                    className='bg-gray-200 rounded-md py-3 sm:px-4 px-2 flex flex-col gap-1 '
                                    key={item?._id}
                                    >
                                        <h6 className='capitalize font-medium'>
                                            {item?.user?.fullName}
                                        </h6>
                                        <ReactStars
                                        count={5}
                                        size={20}
                                        isHalf={true}
                                        value={item?.rating}
                                        // activeColor="#ffd700"
                                        activeColor="#ff6347"
                                        edit={false}
                                        />
                                        <p className='sm:text-base text-sm text-gray-600'>
                                            {item?.review}
                                        </p>
                                    </div>
                                ))
                                }
                                
                            </>
                        :
                            <ItemNotFound message='No review found.' />
                    }
                    </div>
                </div>
                {
                    reviews?.length > 0 &&
                    <Pagination
                    setPage={setCurrentPage}
                    pageCount={pages}
                    currentPage={currentPage}
                    redux={false}
                    />
                }
            </div>
        </div>
    )
}

export default ReviewsPopup