import CircleSvg from "assets/svgs/CircleSvg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import { baseURL } from "config/api";
import DefaultImg from 'assets/images/default.png';
import ReactStars from "react-rating-stars-component";


const avatar = 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&w=1000&q=80';

const Reviews = ({ reviews }) => {
    return (
        <div>
            <div className='relative '>
                <div className="absolute left-2/3 -top-12 -translate-x-2/3">
                    <CircleSvg />
                </div>
                <div>
                <Swiper
                    effect={'cards'}
                    navigation={true}
                    grabCursor={true}
                    modules={[EffectCards , Navigation]}
                    className="testimonials-swiper"
                >
                    {
                        reviews?.map((item , i) => (
                            <SwiperSlide 
                            key={item?._id}
                            className="p-6"
                            >
                                <div className="flex items-center flex-col gap-2">
                                    <img 
                                    src={item?.user?.image ? baseURL + '/users/' + item?.user?.image : DefaultImg} 
                                    alt="user"
                                    className="w-[100px] h-[100px] rounded-full object-cover" 
                                    />
                                    <h4 className="heading-sm">
                                        {item?.user?.fullName}
                                    </h4>
                                    <small className="text-grayText">
                                    <ReactStars
                                    count={5}
                                    size={24}
                                    isHalf={true}
                                    value={item?.rating}
                                    activeColor="#ffd700"
                                    edit={false}
                                    />
                                    </small>
                                </div>
                                <div className="mt-4 text-dark sm:text-base text-sm text-center md:w-[70%] md:mx-auto w-full">
                                    {item?.review}
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Reviews