import FormControl from 'components/form/FormControl';
import Loader from 'components/global/Loader';
import NameInput from 'components/global/NameInput';
import Axios from 'config/api';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import decodeBase64 from 'utils/decodeBase64';
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';
import * as Yup from 'yup';

const initValues = {
    aadhaarNumber : '' ,
    captcha : ''
};

const validations = Yup.object({
    aadhaarNumber : Yup.string()
    .min(12 , 'Aadhaar card number should have at least 12 characters.') 
    .max(12 , 'Aadhaar card number should not exceed 12 characters.') 
    .required('Aadhaar card number is required.') ,
    captcha : Yup.string().required('Captcha is required.')
})


const KycStep1 = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const [otpLoading , setOtpLoading] = useState(false);
    const [captchaLoading , setCaptchaLoading] = useState(false);
    const [sessionData , setSessionData] = useState({
        sessionId : '' ,
        captcha : ''
    });
    const [body , setBody] = useState(initValues);

    const initKyc = async () => {
        try {
            setLoading(true);
            const { data : { data : { session_id , captcha } } } = await Axios(`/kyc/init` , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setSessionData({
                sessionId : session_id,
                captcha
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    useEffect(() => {
        initKyc();
    }, []);

    const submitHandler = async formData => {
        formData.sessionId = sessionData.sessionId;
        try {
            setOtpLoading(true);
            const { data : { data : { data : { message } } } } = await Axios.post(`/kyc/generate-otp` , formData , {
                headers : {
                    Authorization :  `Bearer ${user?.token}`
                }
            });
            localStorage.setItem('aadhaarNumber' , formData.aadhaarNumber);
            localStorage.setItem('step1' , 'completed')
            toast.success(message);
            if(searchParams.get('ref') === 'book-now') {
                navigate(`/kyc/verify-otp/${sessionData?.sessionId}?ref=${searchParams.get('ref')}&vehicle=${searchParams.get('vehicle')}` , { replace : true });
            }else {
                navigate(`/kyc/verify-otp/${sessionData?.sessionId}` , { replace : true });
            }
            setOtpLoading(false);
        } catch (error) {
            setOtpLoading(false);
            toastError(error);
        }
    }

    const reloadCaptcha = async () => {
        try {
            setCaptchaLoading(true);
            const { data : { data : { captcha } } } = await Axios(`/kyc/reload-captcha/${sessionData?.sessionId}` ,  {
                headers : {
                    Authorization :  `Bearer ${user?.token}`
                }
            });
            setSessionData(prev => ({...prev , captcha }));
            setCaptchaLoading(false);
        } catch (error) {
            setCaptchaLoading(false);
            toastError(error);
        }
    }
    // 765732409206

    return (
        <div className='my-12 px-3'>   
               
            <div className="container mx-auto flex items-center justify-center">
                <div className=' md:w-[70%] sm:w-[80%] w-full'>
                    <h1 className="section-heading text-gradient">
                        Verify Aadhaar
                    </h1>
                    {
                        loading 
                        ? 
                            <Loader />
                        : 
                            <Formik
                            initialValues={initValues}
                            validationSchema={validations}
                            onSubmit={submitHandler}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <Form className='shadow-bg w-full mt-4 sm:p-4 py-4 px-2'>
                                                <div>
                                                    <FormControl
                                                    control='input'
                                                    label={'Aadhaar Card Number*'}
                                                    placeholder='Enter your aadhaar card number'
                                                    name='aadhaarNumber'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className='bg-gray-200 p-4 my-4 rounded-md flex items-center gap-4'>
                                                    <img 
                                                    src={`data:image/jpeg;base64,${sessionData.captcha}`} 
                                                    alt="captcha" 
                                                    />
                                                    <button 
                                                    className='cursor-pointer text-2xl'
                                                    type='button'
                                                    disabled={captchaLoading}
                                                    onClick={reloadCaptcha}
                                                    >
                                                        {
                                                            captchaLoading 
                                                            ? 
                                                                <ClipLoader size={20} />
                                                            :
                                                                <i className="uil uil-redo"></i>
                                                        }
                                                    </button>
                                                </div>
                                                <div>
                                                    <FormControl
                                                    control='input'
                                                    label={'Enter Captcha*'}
                                                    placeholder='Captcha'
                                                    name='captcha'
                                                    formik={formik}
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <button 
                                                    className="btn-primary py-2 px-8"
                                                    disabled={otpLoading || !formik.isValid}
                                                    >
                                                        {
                                                            otpLoading 
                                                            ? 
                                                                <ClipLoader 
                                                                size={20} 
                                                                color='white' 
                                                                />
                                                            : 
                                                                'Generate OTP'
                                                        }
                                                    </button>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }

                            </Formik>
                    }
                </div>
            </div>
        </div>
    )
}

export default KycStep1;