import moment from 'moment';
import { useState } from 'react';
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { calculateBooking } from 'redux/actions/bookingActions';

const ReturnDate = ({ bookingData , setBookingData , futureBookings , settings , carDetails , discountDetails , setDiscountDetails , ...rest }) => {

    const { id } = useParams();
    const [tooltipDate, setTooltipDate] = useState(null);
    const dispatch = useDispatch();

    const handleDateChange = (date) => {
        if (!date) {
            // Handle case when date is cleared
            setBookingData(prev => ({ ...prev, returnDate : null }));
            return;
        }

        const selectedDate = new Date(date);
      
        const pickupDate = new Date(bookingData?.pickupDate)
        if( 
            selectedDate.getDate() === pickupDate.getDate() &&
            selectedDate.getMonth() === pickupDate.getMonth() &&
            selectedDate.getFullYear() === pickupDate.getFullYear() &&
            selectedDate.getHours() === 0 &&
            selectedDate.getMinutes() === 0
        ) {
            selectedDate.setHours(pickupDate.getHours() + settings?.minRentalDuration);
            selectedDate.setMinutes(pickupDate.getMinutes());
            selectedDate.setSeconds(pickupDate.getSeconds());
        }
        // setBookingData((prev) => ({ ...prev, returnDate : selectedDate }));

        if (selectedDate && bookingData.pickupDate && bookingData.pickupDate < selectedDate  && carDetails && settings) {
            dispatch(calculateBooking({ 
                bookingData : {...bookingData , returnDate : selectedDate } ,
                discountDetails ,
                carId : id ,
                setBookingData ,
                setDiscountDetails
            }))
        } else {
            setBookingData(prev => ({...prev , returnDate : selectedDate }))
        }
    };


    const renderDayContents = (day, date) => {
        const allUnavailableDates = [
            ...futureBookings.map(({ pickupDate, returnDate }) => ({ start: new Date(pickupDate), end: new Date(returnDate) })),
            ...(Array.isArray(carDetails?.unavailableDates) ? carDetails.unavailableDates.map(({ startDate, endDate }) => ({ start: new Date(startDate), end: new Date(endDate) })) : [])
        ];

        const isExcluded = allUnavailableDates.some(({ start, end }) => {
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            return date >= start && date <= end;
        });

        const handleMouseEnter = () => {
            setTooltipDate(date);
        };

        const handleMouseLeave = () => {
            setTooltipDate(null);
        };

        if (isExcluded) {
            return (
                <div className="text-red-500" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <span className="block">{day}</span>
                    {tooltipDate && tooltipDate.getTime() === date.getTime() && (
                        <div className="absolute bg-red-500 text-sm text-white px-2 py-1 rounded-md z-10">
                            Not Available
                        </div>
                    )}
                </div>
            );
        }

        return day;
    };

    const excludeDateIntervals = futureBookings?.map(({ pickupDate, returnDate }) => {
        const start = new Date(pickupDate);
        const end = new Date(returnDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return { start, end };
    });


    const unavailableDates = Array.isArray(carDetails?.unavailableDates) ? carDetails.unavailableDates.map(({ startDate, endDate }) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return { start, end };
    }) : [];

    const allUnavailableDates = [...excludeDateIntervals, ...unavailableDates];


    return (
        <div className='w-full flex flex-col gap-1.5'>
            <label className='text-primary font-semibold'>
                Return Date & Time
            </label>
            <DatePicker
            selected={bookingData.returnDate}
            minDate={bookingData?.pickupDate}
            placeholderText='select return date & time'
            onChange={handleDateChange}
            showTimeSelect
            name='returnDate'
            value={bookingData?.returnDate}                            
            dateFormat="MMMM d, yyyy h:mm aa"
            excludeDateIntervals={allUnavailableDates}
            filterTime={(time) => {
                const pickupDate = new Date(bookingData?.pickupDate);
                const selectedDate = new Date(time);
                selectedDate.setHours(selectedDate.getHours() - 12);
                return selectedDate.getTime() > pickupDate.getTime() ;
            }}
            disabled={!bookingData?.pickupDate}
            renderDayContents={renderDayContents}
            autoComplete='off'
            {...rest}
            />
        </div>
    )
}

export default ReturnDate