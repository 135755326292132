import SmallCarImg from 'assets/images/smallCar.png';
import LocationLink from 'components/global/LocationLink';
import ReviewsPopup from 'components/popups/ReviewsPopup';
import { baseURL } from 'config/api';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import VehicleImagesPopup from './VehicleImagesPopup';

const Vehicle = ({ car }) => {
    const [showReviewsPopup , setShowReviewsPopup] = useState(false);
    const [showImagesPopup , setShowImagesPopup] = useState(false);


    return (
        <div className='border rounded-lg p-4 hover:shadow-md hover:border-primary relative'>
            <div className='flex gap-6 justify-between sm:flex-row flex-col'>
                <div className={`flex-[0.3] flex items-center justify-center flex-col gap-4`}>
                    
                    <div 
                    className='overflow-hidden rounded-md cursor-pointer' 
                    onClick={() => {
                        setShowImagesPopup(true);
                    }}
                    >
                        <img 
                        src={baseURL + '/cars/' + car?.images[0]} 
                        alt={car?.name} 
                        className='object-cover w-[250px] h-[200px] rounded-md hover:scale-[1.1] duration-200 ease-in-out'
                        />
                    </div>
                    
                </div>
                <div className='flex-[0.4] flex flex-col gap-3'>
                    <div className="flex items-center gap-2">
                        {
                            car?.hasDriver && 
                            <div className='bg-green-500 text-white px-2 py-1 rounded-md text-xs'>
                                With Driver
                            </div>
                        }
                        {
                            car?.selfDrive && 
                            <div className='bg-primary text-white px-2 py-1 rounded-md text-xs'>
                                Self Drive
                            </div>
                        }
                    </div>
                    {/* <div className='flex items-center gap-4'>
                        <div className="bg-gradient rounded-md text-xs text-pure py-1 px-2">
                            Top Pick
                        </div>
                        <div className="bg-gradient rounded-md text-xs text-pure py-1 px-2">
                            Ideal For Families
                        </div>
                    </div> */}
                    <Link
                    to={`/vehicle-details/${car?._id}`}  
                    className='text-lg font-bold underline capitalize'
                    >
                        {car?.name}
                    </Link>
                    <div className='flex gap-8'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-user"></i>
                                <span className='text-xs'>{car?.seats} Seats</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-suitcase-alt"></i>
                                <span className='text-xs'>{car?.doors} Doors</span>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-auto-flash"></i>
                                <span className='text-xs capitalize '>{car?.transmissionType}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <i className="uil uil-dashboard capitalize"></i>
                                <span className='text-xs'>{car?.mileage} Mileage</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <LocationLink location={car?.location} />
                        <span className="text-gray-600 text-[13px] font-medium capitalize">
                           Type : {car?.vehicleType}
                        </span>
                        <span className="text-gray-600 text-[13px] font-medium capitalize">
                           Fuel Type : {car?.fuelType}
                        </span>
                        <span className="text-gray-600 text-[13px] font-medium capitalize">
                           Model : {car?.model} {car?.year}
                        </span>
                    </div>
                </div>
                <div className='flex-[0.3] flex items-end justify-between sm:flex-col'>
                    <div className='flex items-center  gap-1'>
                        {/* <div className='text-sm flex items-center text-yellow-500'>
                            <span>
                                {car?.ratingsAvg} 
                            </span>
                            <p className='-translate-y-[1px] ml-[1px]'>
                                <i className="uil uil-star text-yellow-500"></i>
                            </p>
                        </div> */}
                        <div className='bg-blue-800 rounded-md py-0.5 px-1.5 text-sm text-pure'>
                            {car?.ratingsAvg}
                        </div>
                        <span 
                        className='text-[13px] underline cursor-pointer capitalize'
                        onClick={() => {
                            // if(car?.ratingsCount > 0) {
                                setShowReviewsPopup(true)
                            // }
                        }}
                        >
                            ({car?.ratingsCount} {car?.ratingsCount > 1 ? 'reviews' : 'review'})
                        </span>
                    </div>
                    <div className=''>
                        <span className='text-[13px]'>
                            Rent Per Hour
                        </span>
                        <h6 className='text-xl font-bold mt-1 mb-2'>
                            Rs {car?.rentPerHour}
                        </h6>
                        <Link 
                        to={`/bookings/book-now/${car?._id}`} 
                        className="btn-primary py-2 text-sm px-8"
                        >
                            Book Now
                        </Link>
                    </div>
                </div>
            </div>
            {
                showReviewsPopup && 
                <ReviewsPopup 
                carId={car?._id} 
                setShowReviewsPopup={setShowReviewsPopup}
                />
            }
            {
                showImagesPopup && 
                <VehicleImagesPopup 
                images={car?.images} 
                setShowImagesPopup={setShowImagesPopup}
                />
            }
        </div>
    )
}

export default Vehicle