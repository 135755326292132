import Axios from 'config/api';
import { setLoading, setUser , setUpdateLoading, setShowBlockedPopup, setResendLoading, setAccountStatus } from 'redux/reducers/authReducer';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';
import { requestForToken } from 'utils/firebase';

const saveFcmToken = async (user) => {
    const fcmToken = await requestForToken(); // => save it to database 
 
    try {
        await Axios.put(`/user/save-fcm-token` , { fcmToken } , {
            headers : {
                Authorization : `Bearer ${user?.token}`
            }
        });
    } catch (error) {
        console.log({ fcmTokenSaveError : error })
    }
}

export const register = (data , setCurrentStep  ) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message  } } } = await Axios.post('/user/register' , data );
        localStorage.setItem('email' , data?.email)
        dispatch(setLoading(false));
        setCurrentStep('otp');
        toast.success(message);
    } catch (err) {
        dispatch(setLoading(false));
        toastError(err);
    }
}

export const resendOtp = () => async (dispatch) => {
    dispatch(setResendLoading(true))
    try {
        const email = localStorage.getItem('email');
        const { data : { data : { message } } } = await Axios.post('/user/resend-email' , { email } );
        dispatch(setResendLoading(false));
        toast.success(message);
    } catch (err) {
        dispatch(setResendLoading(false));
        toastError(err);
    }
}

export const verifyOtp = (otp , setShowAuthPopup , setCurrentStep ) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message , doc , verificationType  } } } = await Axios.post('/user/verify-otp' , { otp } );
        if(verificationType === 'email' || verificationType === 'phone') {
            dispatch(setUser({...doc}));
            localStorage.setItem('user' , JSON.stringify({...doc}));
            setShowAuthPopup(false);
            localStorage.removeItem('email');
            saveFcmToken(doc);
        }else if(verificationType === 'password') {
            localStorage.setItem('password-otp' , otp);
            localStorage.removeItem('email')
            setCurrentStep('reset-password');
        }
        dispatch(setLoading(false));
        toast.success(message);
    } catch (err) {
        dispatch(setLoading(false));
        console.log('register error' , err);
        toastError(err);
    }
}

export const login = (data , setShowAuthPopup , setCurrentStep , onSubmitProps ) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message , isBlocked , doc  , verified , email } } } = await Axios.post('/user/login' , data );
        if(isBlocked) {
            setShowAuthPopup(false);
            dispatch(setShowBlockedPopup(true));
            return onSubmitProps.resetForm();
        }
        if(verified) {
            dispatch(setUser({...doc}));
            localStorage.setItem('user' , JSON.stringify({...doc}));
            setShowAuthPopup(false);
            toast.success(message);
            saveFcmToken(doc);
            onSubmitProps.resetForm();
        }else {
            localStorage.setItem('email' , email );
            setCurrentStep('otp');
        }
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('login error' , err);
        toastError(err);
    }
}

export const updateProfile = (updatedData) => async (dispatch , getState) => {
    dispatch(setUpdateLoading(true))
    const token = getState().auth.user.token;
    try {
        const { data : { data : { doc , message } } } = await Axios.put('/user/profile' , updatedData  , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        toast.success(message);
        dispatch(setUser({...doc , token }));
        localStorage.setItem('user' , JSON.stringify({...doc , token }));
        dispatch(setUpdateLoading(false))
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Profile error' , err);
        toastError(err);
    }
}

export const logout = (navigate , setShowAuthPopup = '', showToast = true) => async(dispatch) => {
    dispatch(setLoading(true));
    try {
        await Axios('/user/logout');
        dispatch(setUser(null));
        localStorage.removeItem('user');
        dispatch(setLoading(false));
        navigate('/')
        setShowAuthPopup(true);
        if(showToast){
            toast.success('Logged out successfully.')
        }
    } catch (err) {
        dispatch(setLoading(false));
        console.log('logout error' , err);
        toastError(err);
    }
}