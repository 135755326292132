import './styles.css';
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import { ClipLoader } from 'react-spinners';
import { useAuthPopupContext } from 'context/AuthPopupContext';
import BecomeHostBtn from '../BecomeHostBtn';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { user , loading } = useSelector(state => state.auth);
    const { setShowAuthPopup } = useAuthPopupContext();
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    const logoutHandler = async () => {
        await dispatch(logout(navigate , setShowAuthPopup));
        setShowDrawer(false);
    }
   
    const toggleDrawer = () => {
        setShowDrawer(prev => !prev);
    }

    return (
        <div 
        className='sidebar z-[999]'
        >
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0  -left-[200%] w-[260px]  overflow-auto pb-4 h-full z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='sidebar-overlay absolute top-0 left-0 w-full h-full'></div>
                <div className='overflow-auto relative z-[9999]'>
                    <div className='flex items-center justify-center border-b pb-6 pt-6'>
                        <Link
                        onClick={toggleDrawer} 
                        to='/dashboard' className='text-2xl font-semibold text-gradient'>
                            Rent & Drive                       
                        </Link>
                    </div>
                    <ul className='flex flex-col text-left gap-6 text-grayText mt-6 ml-6 '>
                    <li 
                    className={`${isActive('' , true) ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/'>  
                            Home 
                        </Link>
                    </li>
                    {/* <li 
                    className={`${isActive('categories') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/categories'>  
                            Categories 
                        </Link>
                    </li> */}
                    <li 
                    className={`${isActive('vehicles') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/vehicles'>  
                            Vehicles 
                        </Link>
                    </li>
                    {
                        user && 
                        <>
                            <li 
                            className={`${isActive('bookings') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                            >
                                <Link
                                onClick={toggleDrawer} 
                                to='/bookings'>  
                                    Bookings 
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('kyc') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                            >
                                <Link 
                                onClick={toggleDrawer} 
                                to={`${user?.kyc?.isVerified ? '/kyc/details' : '/kyc/step1'}`}
                                >
                                    Kyc
                                </Link>
                            </li>
                            <li 
                            className={`${isActive('products') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                            >
                                <Link
                                onClick={toggleDrawer} 
                                to='/profile'>  
                                    Profile 
                                </Link>
                            </li>

                        </>
                    }
                    <li 
                    className={`${isActive('blogs') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/blogs'>  
                            Blogs 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('about') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/about'>  
                            About us 
                        </Link>
                    </li>
                    <li 
                    className={`${isActive('contact') ? 'text-primary hover:text-primary' : 'hover:text-primaryHover'} text-grayText `}
                    >
                        <Link
                        onClick={toggleDrawer} 
                        to='/contact'>  
                            Contact us
                        </Link>
                    </li>
                </ul>
                </div>
                {
                    user && 
                        <div className="mt-8 relative z-[999]">
                            <button 
                            className="btn-primary py-2 px-6 cursor-pointer"
                            onClick={logoutHandler}
                            disabled={loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'Logout'
                                }
                            </button>
                        </div>

                }
                <div className="mt-12 relative z-10">
                    <BecomeHostBtn />
                </div>
            </div>
        </div>
    )
}

export default Sidebar