import BulbSvg from 'assets/svgs/BulbSvg'
import React from 'react'

const AboutUs = () => {
    return (
        <div>
            <div className='mt-10'>
                    <h1 className='text-center section-heading text-gradient'>About Us</h1>
                    {/* <p className='text-sm text-grayText mt-1'>Rent N Drive Rental Cars</p> */}
                </div>
                <div className='flex items-center justify-between mt-6 sm:gap-10 gap-6 md:flex-row flex-col '>
                    <div className='flex-1 flex items-center gap-4 bg-white p-4 rounded-md'>
                        <div className='sm:block hidden'>
                            <BulbSvg />
                        </div>
                        <p className='text-dark'><b>Rent N Drive Rental Cars:</b> Welcome to Rent N Drive Rental Cars, where your journey is our priority. Established with a passion for delivering unparalleled mobility solutions, we take pride in being your trusted partner for all your travel needs.</p>
                    </div>
                    <div className='flex-1 flex items-center gap-4 bg-white p-4 rounded-md'>
                        <div className='sm:block hidden'>
                            <BulbSvg />
                        </div>
                        <p className='text-dark'>
                            <b>Who We Are:</b> At Rent N Drive, we are more than just a rental car company; we are facilitators of memorable journeys. With a commitment to excellence, we strive to redefine the rental car experience by blending reliability, innovation, and exceptional customer service.
                        </p>
                    </div>
                </div>
                <div className='flex items-center justify-between mt-6 sm:gap-10 gap-6 md:flex-row flex-col '>
                    <div className='flex-1 flex items-center gap-4 bg-white p-4 rounded-md'>
                        <div className='sm:block hidden'>
                            <BulbSvg />
                        </div>
                        <p className='text-dark'><b>Our Commitment:</b> Our mission is to provide seamless and enjoyable travel experiences. Whether you're embarking on a business trip, family vacation, or a weekend getaway, we are dedicated to ensuring that your journey with us is comfortable, convenient, and filled with peace of mind.</p>
                    </div>
                    <div className='flex-1 flex items-center gap-4 bg-white p-4 rounded-md'>
                        <div className='sm:block hidden'>
                            <BulbSvg />
                        </div>
                        <p className='text-dark'><b>Our Vision:</b> We envision a world where every traveller experiences the joy of the journey. As a leading player in the rental car industry, we strive to set new standards, inspire adventures, and contribute to a sustainable future.
                        </p>
                    </div>
                </div>
        </div>
    )
}

export default AboutUs