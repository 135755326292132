import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuthPopupContext } from "context/AuthPopupContext";

function ProtectedRoute({ children }) {
    const { setShowAuthPopup } = useAuthPopupContext();
    const user = useSelector((state) => state.auth.user); 

    if(!user){
        setShowAuthPopup(true)
        return <Navigate to="/" replace />
    }
    return children
}


export default ProtectedRoute;
