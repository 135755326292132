import ChooseUsCarImg from 'assets/images/chooseUsCar.png'; 
import FlySvg from 'assets/svgs/FlySvg';
import TagSvg from 'assets/svgs/TagSvg';
import UserSvg from 'assets/svgs/UserSvg';
import LazyLoad from 'react-lazyload';


const WhyChooseUs = () => {
    return (
        <section className='container mx-auto sm:px-0 px-4 '>
            <div className='flex items-center md:gap-0 gap-20 justify-between md:flex-row flex-col'>
                <div className='flex-[0.4] x-space'>
                    <h2 className="section-heading">
                        Why Choose Us?
                    </h2>
                    <div className='mt-12 flex flex-col gap-16'>
                        <div className='flex sm:gap-6 gap-4'>
                            <div className='aspect-w-1 aspect-h-1'>
                                <div className='circle-icon'>
                                    <TagSvg />
                                </div>
                            </div>
                            <div className=''>
                                <h4 className='heading-sm'>
                                    Reliability
                                </h4>
                                <p className='text-sm text-grayText md:w-[80%] w-full mt-1'>
                                    Our fleet boasts a diverse selection of well-maintained vehicles, ensuring reliability and safety on the road.
                                </p>
                            </div>
                        </div>
                        <div className='flex  sm:gap-6 gap-4'>
                            <div className='aspect-w-1 aspect-h-1'>
                                <div className='circle-icon'>
                                    <FlySvg />
                                </div>
                            </div>
                            <div>
                                <h4 className='heading-sm'>
                                    Flexibility
                                </h4>
                                <p className='text-sm text-grayText md:w-[80%] w-full mt-1'>
                                    We understand that travel plans can change. That's why we offer flexible rental options, ensuring you have the freedom to tailor your journey according to your schedule.
                                </p>
                            </div>
                        </div>
                        <div className='flex sm:gap-6 gap-4'>
                            <div className='aspect-w-1 aspect-h-1'>
                                <div className='circle-icon'>
                                    <TagSvg />
                                </div>
                            </div>
                            <div className=''>
                                <h4 className='heading-sm'>
                                    Innovation
                                </h4>
                                <p className='text-sm text-grayText md:w-[80%] w-full mt-1'>
                                    Embracing the latest technology, we provide a seamless online booking experience, along with cutting-edge features to enhance your travel convenience.
                                </p>
                            </div>
                        </div>
                        <div className='flex  sm:gap-6 gap-4'>
                            <div className='aspect-w-1 aspect-h-1'>
                                <div className='circle-icon'>
                                    <UserSvg />
                                </div>
                            </div>
                            <div>
                                <h4 className='heading-sm'>
                                    Customer-Centric Approach
                                </h4>
                                <p className='text-sm text-grayText md:w-[80%] w-full mt-1'>
                                    Your satisfaction is our top priority. Our friendly and knowledgeable team is here to assist you at every step, ensuring a hassle-free rental experience.
                                </p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className='flex-[0.6]'>
                    <LazyLoad height={200}>
                        <img 
                        src={ChooseUsCarImg} 
                        alt="choose us car" 
                        className='w-full'
                        />
                    </LazyLoad>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs