import Input from 'components/global/Input';
import Textarea from 'components/global/Textarea';
import contactIconImg from 'assets/images/contactIconImg.png';
import ContactForm from 'components/home/ContactForm';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from 'redux/reducers/homeReducer';
import Breadcrumbs from 'components/global/Breadcrumbs';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Contact Us", path: "/contact" },
]

const Contact = () => {
    const { settings } = useSelector(state => state.home);
    const dispatch = useDispatch();
    const { isLoading , data } = useQuery('fetch-settings' , () => {
        return fetcher('/setting');
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setSettings(doc)); 
        }
    }, [data]);

    return (
        <div className='flex items-center justify-center px-3 ' >
            <div className='bg-white rounded-md md:p-10 sm:p-6 p-4 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-center text-gradient my-8">
                    Contact Us
                </h1>
                <div className='flex sm:flex-row flex-col gap-12 justify-between mt-16'>
                    <div className='flex-[0.6]'>
                        <div>
                            <h3 className='text-xl text-dark font-semibold'>Rent <span className='text-gradient'>N</span> drive</h3>
                            <span className='text-grayText text-sm '>Fill the form to contact us quicker!</span>
                        </div>
                        <ContactForm />
                    </div>
                    <div className='flex-[0.4] relative flex flex-col justify-between sm:items-end items-center gap-8'>
                        <div >
                            <div className='text-xl'>
                                <h4 className='font-medium'>Hey we’re here!</h4>
                                <p className='text-2xl font-bold'>Get in touch with us</p>
                            </div>
                            <div className='mt-8 flex flex-col gap-8'>
                                <div className='flex items-center gap-3'>
                                    <div className='bg-gradient rounded-lg flex items-center justify-center text-white w-[55px] h-[50px] '>
                                        <i className="uil uil-phone text-xl"></i>
                                    </div>
                                    <div className='text-sm'>
                                        <span>Phone Number</span>
                                        <p className='font-semibold text-base'>
                                            +91-{settings?.contactNo}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className='bg-gradient rounded-lg flex items-center justify-center text-white w-[55px] h-[50px] '>
                                        <i className="uil uil-location-point text-xl"></i>
                                    </div>
                                    <div className='text-sm'>
                                        <span>Office Location</span>
                                        <p className='font-semibold text-base'>
                                            {settings?.officeAddress}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className='bg-gradient rounded-lg flex items-center justify-center text-white w-[55px] h-[50px] '>
                                        <i className="uil uil-envelope text-xl"></i>
                                    </div>
                                    <div className='text-sm'>
                                        <span>E-mail </span>
                                        <p className='font-semibold text-base'>
                                            {settings?.contactEmail}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <img 
                            src={contactIconImg} 
                            alt="Contact icon" 
                            className='w-[200px]'
                            />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Contact