import axios from "axios";
import { useAuthPopupContext } from "context/AuthPopupContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/actions/authActions";

export const baseURL = 'https://server.rentndrive.co.in';
// export const baseURL = 'http://localhost:3232';


const Axios = axios.create({
    baseURL : `${baseURL}/api` ,
});


export const useApi = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setShowAuthPopup , setCurrentStep } = useAuthPopupContext();

    Axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                dispatch(logout(navigate , setShowAuthPopup , false)); 
                localStorage.removeItem('user');
            }
            return Promise.reject(error);
        }
    );
    return Axios;
};

export default Axios;