import React from 'react'

const UserSvg = () => {
    return (
        <div>
            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6251 6.66675C22.3932 6.66675 24.0889 7.36913 25.3391 8.61937C26.5894 9.86961 27.2917 11.5653 27.2917 13.3334C27.2917 15.1015 26.5894 16.7972 25.3391 18.0475C24.0889 19.2977 22.3932 20.0001 20.6251 20.0001C18.857 20.0001 17.1613 19.2977 15.911 18.0475C14.6608 16.7972 13.9584 15.1015 13.9584 13.3334C13.9584 11.5653 14.6608 9.86961 15.911 8.61937C17.1613 7.36913 18.857 6.66675 20.6251 6.66675ZM20.6251 10.0001C19.741 10.0001 18.8932 10.3513 18.2681 10.9764C17.6429 11.6015 17.2917 12.4494 17.2917 13.3334C17.2917 14.2175 17.6429 15.0653 18.2681 15.6904C18.8932 16.3156 19.741 16.6667 20.6251 16.6667C21.5091 16.6667 22.357 16.3156 22.9821 15.6904C23.6072 15.0653 23.9584 14.2175 23.9584 13.3334C23.9584 12.4494 23.6072 11.6015 22.9821 10.9764C22.357 10.3513 21.5091 10.0001 20.6251 10.0001ZM20.6251 21.6667C25.0751 21.6667 33.9584 23.8834 33.9584 28.3334V33.3334H7.29175V28.3334C7.29175 23.8834 16.1751 21.6667 20.6251 21.6667ZM20.6251 24.8334C15.6751 24.8334 10.4584 27.2667 10.4584 28.3334V30.1667H30.7917V28.3334C30.7917 27.2667 25.5751 24.8334 20.6251 24.8334Z" fill="url(#paint0_linear_34_353)"/>
<defs>
<linearGradient id="paint0_linear_34_353" x1="2.60576" y1="13.8597" x2="34.0427" y2="14.3266" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
</defs>
</svg>

        </div>
    )
}

export default UserSvg