import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }) => {
    return (
        <nav aria-label="breadcrumb" className="sm:pl-0 pl-2">
            <ol className="flex space-x-2 text-sm text-gray-700">
                {crumbs.map((crumb, index) => {
                    const isLast = index === crumbs.length - 1;
                    return (
                        <li key={index} className="flex items-center">
                            {!isLast ? (
                                <>
                                    <Link
                                        to={crumb.path}
                                        className="hover:text-primary transition-colors"
                                    >
                                        {crumb.label}
                                    </Link>
                                    <span className="mx-2">/</span>
                                </>
                            ) : (
                                <span className="text-gray-500">{crumb.label}</span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
