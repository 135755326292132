import React from 'react'

const CircleSvg = () => {
    return (
        <svg width="600" height="600" viewBox="0 0 865 778" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_34_373)">
<path fillRule="evenodd" clipRule="evenodd" d="M421.786 100.174C503.723 97.8355 584.868 118.973 646.505 170.099C712.382 224.742 755.258 300.079 762.384 382.688C770.143 472.648 757.455 572.781 686.437 633.054C617.363 691.676 514.296 678.638 421.786 671.749C339.012 665.586 255.886 650.685 195.758 596.517C134.248 541.103 98.1166 462.998 100.924 382.688C103.651 304.669 149.826 235.733 210.229 182.55C268.26 131.455 342.546 102.435 421.786 100.174Z" fill="url(#paint0_linear_34_373)" fillOpacity="0.4"/>
</g>
<defs>
<filter id="filter0_f_34_373" x="0.771484" y="0" width="863.613" height="778" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_34_373"/>
</filter>
<linearGradient id="paint0_linear_34_373" x1="-15.8417" y1="255.908" x2="766.426" y2="269.245" gradientUnits="userSpaceOnUse">
<stop stopColor="#009CFF"/>
<stop offset="1" stopColor="#55CB52"/>
</linearGradient>
</defs>
</svg>

    )
}

export default CircleSvg