import SumantImg from 'assets/images/sumant.jpeg';
import KiranImg from 'assets/images/kiran.jpeg';

const Sumant = () => {
    return (
        <div className='mt-10'>
            <h1 className='text-center section-heading text-gradient'>Founder & CEO of SAK RENT N DRIVE LUXURY PVT.LTD.</h1>
            <div className="flex items-center justify-between gap-4 md:flex-row flex-col-reverse mt-10">
                <div className='flex-[1.5]'>
                    <h1 className='text-lg font-semibold'>
                        Visionary Leadership, Passion for Excellence
                    </h1>
                    <br />
                    <p>
                        Sumant Kumar Raj, the driving force behind SAK RENT N DRIVE LUXURY PVT.LTD. Rental Cars, is a visionary leader with an unyielding passion for excellence and a commitment to redefining the rental car experience. With a deep understanding of the evolving needs of modern travellers, Sumant Kumar Raj embarked on a journey to create a company that goes beyond merely providing vehicles — a company dedicated to making every journey exceptional.
                    </p>
                    <p className='mt-4'>
                        <b>Background and Expertise</b>: Sumant Kumar Raj brings his extensive background in hospitality Industry, infusing SAK RENT N DRIVE LUXURY PVT.LTD. with a unique blend of industry expertise and innovation. He has a proven track record of achievements or milestones, showcasing a keen understanding of the dynamics of the rental car industry. 
                    </p>
                </div>
                <div className='flex-1 flex items-center justify-center'>
                    <img src={SumantImg} alt="" className='sm:w-[250px] w-full rounded-md' />
                </div>
            </div>
            <div className='mt-6 flex flex-col gap-4'>
                <p>
                    <b>Passion for Customer Satisfaction</b>: At the heart of Sumant Kumar Raj's vision is an unwavering commitment to customer satisfaction. He believes that a journey is not just about reaching a destination; it's about the experiences along the way. This commitment is reflected in SAK RENT N DRIVE LUXURY PVT.LTD's mission to provide seamless, enjoyable, and reliable mobility solutions to its valued customers.
                </p>

                <p>
                    <b>Innovative Approach</b>: Known for his innovative approach, Sumant Kumar Raj has driven SAK RENT N DRIVE LUXURY PVT.LTD.to embrace cutting-edge technology, ensuring a user-friendly online booking experience and staying at the forefront of industry advancements. The company's commitment to innovation is a testament to Sumant Kumar Raj's forward-thinking leadership.
                </p>

                <p>
                    <b>Sustainability Advocacy</b>: Sumant Kumar Raj is not only a business leader but also an advocate for environmental sustainability. He has spearheaded initiatives within SAK RENT N DRIVE LUXURY PVT.LTD.to promote eco-friendly practices, including offering green vehicle options and actively contributing to a more sustainable future.
                </p>

                <p>
                    <b>Inspiring the Team</b>: As a leader, Sumant Kumar Raj inspires the SAK RENT N DRIVE LUXURY PVT.LTD. team to uphold the highest standards of service, reliability, and professionalism. his leadership style fosters a culture of excellence, ensuring that each team member is dedicated to making SAK RENT N DRIVE LUXURY PVT.LTD. a trusted name in the rental car industry.
                </p>

                <p>
                    <b>Driving SAK RENT N DRIVE LUXURY PVT.LTD. Forward</b>: Under Sumant Kumar Raj's guidance, SAK RENT N DRIVE LUXURY PVT.LTD.Rental Cars envisions a future where every journey is a source of joy, comfort, and peace of mind. The company's success story is a reflection of Sumant Kumar Raj's dedication to creating a brand that stands for excellence, reliability, and a commitment to enhancing the travel experience for all.
                </p>

                <p>
                    <b>Biography</b>: Sumant Raj is the visionary leader at the helm of Rent N Drive Rental Cars. With a passion for travel and a commitment to excellence, He have steered the company towards becoming a prominent player in the rental car industry.
                </p>

                <p>
                    <b>Professional Journey</b>: Drawing upon his extensive experience in [relevant industries], Sumant Raj has played a pivotal role in shaping Rent N Drive into a customer-centric and innovative organization. He have a proven track record of leadership, driving growth, and fostering a culture of continuous improvement.
                </p>

                <p>
                    <b>Visionary Leadership</b>: Sumant Raj envisions a future where Rent N Drive is synonymous with exceptional travel experiences. He are dedicated to setting new standards within the industry, inspiring adventures, and contributing to a sustainable and responsible approach to mobility.
                </p>

                <p>
                    <b>Commitment to Excellence</b>: Known for his commitment to excellence, Sumant Raj has championed initiatives that prioritize customer satisfaction, operational efficiency, and environmental responsibility. Under his leadership, the company has embraced the latest technologies and green practices, ensuring that Rent N Drive remains at the forefront of the rental car industry.
                </p>

                <p>
                    <b>Environmental Advocacy</b>: Sumant Raj's commitment to environmental responsibility is evident in the company's efforts to offer eco-friendly vehicle options and promote sustainable travel practices. This dedication aligns with his broader vision of contributing to a greener and more sustainable future.
                </p>

                <p>
                    <b>Community Engagement</b>: Beyond business, Sumant Raj is actively involved in community engagement initiatives, reflecting his belief in giving back and being a responsible corporate citizen. He understand the importance of Rent N Drive being an integral part of the communities it serves.
                </p>

                <p>
                    <b>Inspirational Leadership Style</b>: Known for his inspirational leadership style, Sumant Raj fosters a positive and collaborative work environment. He believe in empowering the team to innovate, adapt to industry changes, and deliver exceptional service to customers.
                </p>

                <p>
                    <b>Conclusion</b>: Sumant Raj is not just a leader in the rental car business; He are a driving force behind Rent N Drive's mission to empower seamless journeys, redefine travel experiences, and contribute to a brighter and more sustainable future. Under Sumant Raj's guidance, Rent N Drive Rental Cars continues to flourish, setting new benchmarks and providing customers with more than just a vehicle—it's a commitment to excellence, reliability, and the joy of the journey.
                </p>
            </div>
        </div>
    )
}

export default Sumant