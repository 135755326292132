function calculateDuration(fromDate, toDate) {
    const timeDifference = toDate - fromDate; // Difference in milliseconds

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));

    return { days, hours, minutes, totalHours };
}

export default calculateDuration;
