import Home from "pages/home";
import { Routes , Route, useLocation, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import NotFound from "pages/notFound";
import Navbar from "components/global/navbar";
import Footer from "components/global/footer";
import About from "pages/about";
import Contact from "pages/contact";
import Profile from "pages/profile";
import Kyc from "pages/profile/Kyc";
import Vehicles from "pages/vehicles";
import BookingDetails from "pages/bookings/BookingDetails";
import BookNow from "pages/bookings/BookNow";
import AuthPopup from "components/popups/authPopup";
import { useAuthPopupContext } from "context/AuthPopupContext";
import { useEffect, useState } from "react";
import VehicleDetails from "pages/vehicles/VehicleDetails";
import Bookings from "pages/bookings";
import KycStep1 from "pages/kyc/KycStep1";
import VerifyOtp from "pages/kyc/VerifyOtp";
import KycDetailsForm from "pages/kyc/KycDetailsForm";
import { useSelector } from "react-redux";
import KycDetails from "pages/kyc";
import Chopper from "pages/chopper";
import BlockedPopup from "components/popups/BlockedPopup";
import UpdatePassword from "pages/updatePassword";
import { useApi } from "config/api";
import ProtectedRoute from "ProtectedRoute";
import TermsAndConditions from "pages/termsAndConditions";
import PrivacyPolicy from "pages/privacyPolicy";
import RefundPolicy from "pages/refundPolicy";
import useNotifyPermission from "hooks/useNotifyPermission";
import NotificationToast from "components/global/NotificationToast";
import { onMessageListener } from "utils/firebase";
import Notifications from "pages/notifications";
import VendorPolicy from "pages/vendorPolicy";
import ShippingAndDelivery from "pages/shippingAndDelivery";
import ExtendBooking from "pages/bookings/ExtendBooking";
import Blogs from "pages/blogs";
import BlogDetails from "pages/blogs/BlogDetails";


function App() {
    const api = useApi();
    const notificationPermission = useNotifyPermission();
    const location = useLocation();
    const { user , showBlockedPopup } = useSelector(state => state.auth);
    const { showAuthPopup } = useAuthPopupContext();

    const [notification, setNotification] = useState({ title: "", body: "" });
    const notify = () => toast(<NotificationToast notification={notification} /> , {
        position : "bottom-right" ,
        autoClose : 5000 ,
        hideProgressBar : false
    });

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    onMessageListener()
    .then((payload) => {
        setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log("failed: ", err));
    

    useEffect(() => {       
        window.scrollTo(0,0)
    }, [location.pathname]);

    return (
        <div className="relative">
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                closeOnClick
                pauseOnHover
                hideProgressBar
            />

            <Navbar />
            <main className="mt-[70px] overflow-x-hidden">
            <Routes>
                <Route path='/' element={ <Home /> } />
                <Route path='/about' element={ <About /> } />
                <Route path='/contact' element={ <Contact /> } />
                <Route path='/profile' element={ 
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                } />
                <Route path='/update-password' element={ 
                    <ProtectedRoute>
                        <UpdatePassword />
                    </ProtectedRoute>
                } />
                <Route 
                path='/kyc/step1' 
                element={ 
                    <ProtectedRoute>
                        <KycStep1 />
                    </ProtectedRoute>
                 } 
                />
                <Route 
                path='/kyc/verify-otp/:sessionId' 
                element={ 
                    <ProtectedRoute>
                        <VerifyOtp />
                    </ProtectedRoute>
                 } 
                />
                <Route 
                path='/kyc/add-details' 
                element={ 
                    <ProtectedRoute>
                        <KycDetailsForm />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/kyc/details' 
                element={ 
                    <ProtectedRoute>
                        <KycDetails />
                    </ProtectedRoute>
                 } 
                />
                <Route path='/vehicles' element={ <Vehicles /> } />
                <Route path='/vehicle-details/:id' element={ <VehicleDetails /> } />
                <Route path='/chopper' element={ <Chopper /> } />
                <Route 
                path='/bookings/booking-details/:id' 
                element={ 
                    <ProtectedRoute>
                        <BookingDetails />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/bookings/book-now/:id' 
                element={ <BookNow /> } 
                />
                <Route 
                path='/bookings' 
                element={ 
                    <ProtectedRoute>
                        <Bookings />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/blogs' 
                element={ 
                    <Blogs />
                } 
                />
                <Route 
                path='/blogs/:id' 
                element={ 
                    <BlogDetails />
                } 
                />
                <Route 
                path='/notifications' 
                element={ 
                    <ProtectedRoute>
                        <Notifications />
                    </ProtectedRoute>
                } 
                />
                <Route path='/terms-and-conditions' element={ <TermsAndConditions /> } />
                <Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
                <Route path='/cancellation-and-refund-policy' element={ <RefundPolicy /> } />
                <Route path='/vendor-policy' element={ <VendorPolicy /> } />
                <Route path='/shipping-and-delivery' element={ <ShippingAndDelivery /> } />
                <Route path='/bookings/extend-booking/:id' element={ <ExtendBooking /> } />
                {/* <Route 
                path='/notifications'
                element={
                    <ProtectedRoute>
                        <Notifications />
                    </ProtectedRoute>
                }
                /> */}

                <Route path='/:slug' element={<Home />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            {location.pathname.split('/').includes('kyc') ? '' : <Footer />}

            { showAuthPopup && <AuthPopup /> }
            { showBlockedPopup && <BlockedPopup /> }
            </main>
        </div> 
    );
}

export default App;
