import FormControl from 'components/form/FormControl';
import { baseURL } from 'config/api';
import { useAuthPopupContext } from 'context/AuthPopupContext'
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { register } from 'redux/actions/authActions';
import registerValidations from 'validations/registerValidation';

const initValues = {
    fullName : '' ,
    email : '' ,
    phone : '',
    password : ''
}

const Register = () => {
    const dispatch = useDispatch();
    const { setCurrentStep , setShowAuthPopup } = useAuthPopupContext();
    const [isTermAccepted , setIsTermsAccepted ] = useState(false);
    const { loading } = useSelector(state => state.auth);


    const handleSubmit = async (formData) => {
        dispatch(register(
            { 
                ...formData , 
                phone : formData?.phone?.toString() , 
            } , 
            setCurrentStep 
        ));

    }
    

    return (
        <div>
            <center>
                <h1 className='text-2xl font-bold'>Sign Up</h1>
                <p className='text-sm text-grayText mt-1'>Create Your Account</p>
            </center>
            <Formik
            initialValues={initValues}
            validationSchema={registerValidations}
            onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form
                            className='mt-8'
                            >
                                <FormControl
                                control='input'
                                label='Full Name'
                                placeholder='Enter your name (as per Aadhar)'
                                name='fullName'
                                formik={formik}
                                />
                                <FormControl
                                control='input'
                                type='email'
                                label='Email'
                                placeholder='Enter your email'
                                name='email'
                                formik={formik}
                                />
                                <FormControl
                                control='input'
                                type='number'
                                label='Phone Number'
                                placeholder='Enter your phone number'
                                name='phone'
                                formik={formik}
                                />
                                <FormControl
                                control='password'
                                label='Password'
                                placeholder='Ex : ******** '
                                name='password'
                                formik={formik}
                                />
                                <div className='flex items-start gap-2 my-4'>
                                    <input 
                                    type="checkbox" 
                                    className='mt-[5px]'
                                    id='termsOfService'
                                    onChange={e => {
                                        if(e.target.checked) {
                                            setIsTermsAccepted(true)
                                        }else {
                                            setIsTermsAccepted(false)
                                        }
                                    }}
                                    required
                                    />
                                    <label 
                                    htmlFor='termsOfService'
                                    className='text-sm'
                                    >
                                        By checking this box, you are agreeing to our <a href={`${baseURL}/agreements/client.pdf`} target='_blank' className='underline text-blue-400'>terms of service</a>
                                    </label>
                                </div>
                                <div className='mt-4'>
                                    <button
                                    type='submit' 
                                    className="btn-primary py-3 sm:px-12 px-6 w-full"
                                    disabled={loading || !isTermAccepted || !formik.isValid}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Sign up'
                                        }
                                    </button>

                                    <div className='text-sm text-dark mt-6 text-center flex items-center justify-center gap-1'>
                                        <span>Already have an account ? </span> 
                                        <button 
                                        className='underline'
                                        onClick={() => setCurrentStep('login')}
                                        disabled={loading}
                                        type='button'
                                        >
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default Register