import FormControl from 'components/form/FormControl';
import Axios from 'config/api';
import { useAuthPopupContext } from 'context/AuthPopupContext';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import * as Yup from 'yup';

const initValues = { 
    newPassword : '' , 
    confirmPassword : ''
}

const validation = Yup.object({
    newPassword: Yup.string()
        .min(8, 'Password should have at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character'
        )
        .required('New Password is required'),

    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
})

const ResetPassword = () => {
    const { setCurrentStep } = useAuthPopupContext();
    const [loading , setLoading] = useState(false)


    const handleSubmit = async (formData, onSubmitProps) => {
        const { newPassword , confirmPassword } = formData;
        if(newPassword !== confirmPassword) {
            return toast.error('Passwords are not matched.')
        }
        const body = {
            ...formData ,
            otp : localStorage.getItem('password-otp') || ''
        } 

        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.put(`/user/reset-password` , body );
            toast.success(message);
            setCurrentStep('login');
            localStorage.removeItem('password-otp');
            setLoading(false);
            onSubmitProps.resetForm();
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div >
            <center>
                <h1 className='text-2xl font-bold'>Reset Password</h1>
            </center>
            <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={validation}
            >
                {
                    (formik) => {
                        return (
                            <Form>
                                <FormControl
                                control='password'
                                label='New Password'
                                placeholder='Ex : **************'
                                name='newPassword'
                                formik={formik}
                                />
                                <FormControl
                                control='password'
                                label='Confirm Password'
                                placeholder='Ex : **************'
                                name='confirmPassword'
                                formik={formik}
                                />
                                <center className='mt-8'>
                                    <button 
                                    className="btn-primary py-2 sm:px-12 px-6"
                                    disabled={loading || !formik.isValid}
                                    type='submit'
                                    >
                                        {
                                            loading 
                                            ?
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Update'
                                        }
                                    </button>
                                </center>
                            </Form>
                        )
                    }
                }
            

            </Formik>
        </div>
    );
}

export default ResetPassword;