import { createSlice } from '@reduxjs/toolkit';

const homeSlice = createSlice({
    name : 'home' ,
    initialState : {
        popularCars : [] ,
        settings : '' ,
        homePageContent : '' ,
        slugData : null 
    } ,
    reducers : {
        setPopularCars(state , action) {
            state.popularCars = action.payload;
        } , 
        setSettings(state , action){
            state.settings = action.payload;
        } ,
        setHomePageContent(state , action){
            state.homePageContent = action.payload;
        } ,
        setSlugData(state , action){
            state.slugData = action.payload;
        } 
    }
});

export const { setSettings , setPopularCars , setHomePageContent , setSlugData } = homeSlice.actions;
export default homeSlice.reducer;


