import Vehicle from 'components/vehicles/Vehicle'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import fetcher from 'utils/fetcher'
import Loader from 'components/global/Loader'
import ItemNotFound from 'components/global/ItemNotFound';
import toastError from 'utils/toastError'
import Pagination from 'components/global/pagination'
import Search from 'components/vehicles/Search'
import Filters from 'components/vehicles/Filters'
import { useLocation, useSearchParams } from 'react-router-dom'
import DebounceSearch from 'components/global/DebounceSearch'
import Breadcrumbs from 'components/global/Breadcrumbs'

const initialFilterState = {
    vehicleType: '',
    sort: '',
    hasDriver : false ,
};

const crumbs = [
    { label: "Home", path: "/" },
    { label: "All Vehicles", path: "/vehicles" },
];

const Vehicles = () => {
    
    const [cars, setCars] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [docsCount, setDocsCount] = useState(1);
    const [distance , setDistance] = useState(5);
    const [locationType, setLocationType] = useState('search');
    const [filter, setFilter] = useState(initialFilterState);
    const [keyword, setKeyword] = useState('');
    const [location , setLocation] = useState({
        lat : '' ,
        lng : ''
    })

    const queryKey = [
        'fetch-cars',
        filter?.sort,
        filter?.vehicleType,
        filter?.hasDriver ,
        currentPage,
        location ,
        keyword
    ];
    const url = `/car?vehicleType=${filter?.vehicleType}&sort=${filter?.sort}&page=${parseInt(currentPage)}&distance=${distance || 5}&lat=${location.lat}&lng=${location.lng}&hasDriver=${filter?.hasDriver}&keyword=${keyword}`;

    const { isLoading, data, refetch, isRefetching } = useQuery(queryKey, () =>
        fetcher(url)
    , { refetchOnWindowFocus : false });

    useEffect(() => {
        if (data) {
            setCars(data?.data?.data?.docs);
            setPages(data?.data?.data?.pages);
            setCurrentPage(data?.data?.data?.page);
            setDocsCount(data?.data?.data?.docCount);
        }
    }, [data]);

    const searchFetcher = (value) => {
        setKeyword(value);
    }

    return (
        <div>
            <div className='container mx-auto x-space px-4 py-6 relative'>
                <Breadcrumbs crumbs={crumbs} />
                <h1 className="section-heading text-gradient text-center mt-8">
                    All Vehicles
                </h1>
                <div className='mt-12 sm:px-0 px-4'>
                    <div>
                        <Search
                        setLocationType={setLocationType}
                        locationType={locationType}
                        setLocation={setLocation}
                        isRefetching={isRefetching}
                        isLoading={isLoading}
                        setDistance={setDistance}
                        refetch={refetch}
                        distance={distance}
                        />
                    </div>
                    <div className='flex lg:flex-row flex-col gap-6 mt-12 '> 
                        <div className={`filters flex-[0.275] shadow-bg h-fit`}>
                            <Filters 
                            filter={filter}
                            setFilter={setFilter}
                            setLocation={setLocation}
                            setDistance={setDistance}
                            
                            />
                        </div>
                        
                        <div className='flex-[0.65] shadow-bg p-4'>
                            <div className="flex items-center justify-between md:flex-row flex-col gap-6">
                                <h3 className='text-xl font-semibold'>
                                    {docsCount || 0} Vehicles Available
                                </h3>
                                <DebounceSearch
                                fetcher={searchFetcher}
                                placeholder='Search Vehicles'
                                />
                            </div>
                        {
                            isLoading 
                            ? 
                                <Loader />
                            : 
                            cars?.length > 0 
                            ?
                                <div>
                                    
                                    {/* <div>
                                        <VehicleVariants />
                                    </div> */}
                                    <div className='flex flex-col gap-6 mt-12'>
                                        {
                                            cars?.map((item , i) => (
                                                <Vehicle 
                                                key={i}
                                                car={item}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className="mt-6">
                                    <Pagination
                                    pageCount={pages}
                                    currentPage={currentPage}
                                    setPage={setCurrentPage}
                                    redux={false}
                                    />
                                    </div>
                                </div>
                            : 
                                <ItemNotFound />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vehicles