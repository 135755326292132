import Breadcrumbs from 'components/global/Breadcrumbs';
import Heading from 'components/global/Heading';
import UpdateProfile from 'components/profile/UpdateProfile';
import Axios from 'config/api';
import { useAuthPopupContext } from 'context/AuthPopupContext';
import { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { setUser } from 'redux/reducers/authReducer';
import confirmBox from 'utils/confirmBox';
import toastError from 'utils/toastError';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Profile",  }
]


const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const [deleteLoading , setDeleteLoading] = useState(false);

    const deleteAccountHandler = async () => {
        const message='Deleting your account is irreversible. Are you sure you want to proceed?';
        const onYesClick = async () => {
            setDeleteLoading(true)
            try {
                const { data : { data : { message } } } = await Axios.delete(`user/delete-my-account` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                dispatch(setUser(null));
                localStorage.removeItem('user');
                navigate('/')
                setDeleteLoading(false)
            } catch (err) {
                setDeleteLoading(false)
                toastError(err)
            }
        }

        confirmBox({ message , onYesClick });
    }


    return (
        <div className='my-6 px-3 container mx-auto'>
            <Breadcrumbs crumbs={crumbs} />      
            <div className=" flex items-center justify-center my-6">
                <div className=' md:w-[70%] sm:w-[80%] w-full'>
                    <div>
                        <UpdateProfile />
                    </div>
                    <div className='mt-8'>
                        <h1 className='font-semibold text-red-500 text-xl flex items-center gap-2'>
                            Danger Zone
                        </h1>
                        <div className="shadow-bg p-4 mt-4 flex lg:flex-row flex-col items-center justify-between gap-4 border border-red-500">
                            <div>
                                <h6 className='heading-sm'>Delete Your Account</h6>
                                <p className='text-sm'>Deleting your account will permanently erase all your data. Please be certain.</p>
                            </div>
                            <button 
                            className="btn-red py-2 px-6"
                            disabled={deleteLoading}
                            onClick={deleteAccountHandler}
                            >
                                {
                                    deleteLoading
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'Delete My Account'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile