import VehicleDetailsSlider from 'components/vehicles/VehicleDetailsSlider'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import fetcher from 'utils/fetcher'
import { useEffect, useState } from 'react'
import BackBtn from 'components/global/BackBtn'
import LocationLink from 'components/global/LocationLink'
import Reviews from 'components/vehicles/Reviews'
import Breadcrumbs from 'components/global/Breadcrumbs'

const crumbs = [
    { label: "Home", path: "/" },
    { label: "All Vehicles", path: "/vehicles" },
    { label: "Vehicle Details", },
];

const VehicleDetails = () => {
    const { id } = useParams();
    const [carDetails , setCarDetails] = useState('');
    const [reviews , setReviews] = useState('');
    const { isLoading , data  } = useQuery('fetch-car-details-with-reviews' , () => {
        return fetcher(`/car/with-reviews/${id}`);
    });

    useEffect(() => {
        if(data) {
            const { data :{ data : { car , reviews } } } = data;
            setCarDetails(car);
            setReviews(reviews);
        }
    }, [data]);



    return (
        <div className='sm:px-12 py-6 p-4 container mx-auto'>
            <Breadcrumbs crumbs={crumbs} />
            <div className='flex items-center justify-between gap-4 border-b-2 pb-2 border-secondary'>
                <h1 className="section-heading text-gradient mt-6">
                    Vehicle Details 
                </h1>
                <BackBtn />
            </div>
            {
                isLoading
                ?
                    <Loader />
                : 
                carDetails 
                ?
                    <div>
                        <div className='shadow-bg p-4 mt-8 sm:text-base text-sm'>
                            <div className=''>
                                <div>
                                    <VehicleDetailsSlider carDetails={carDetails} />
                                </div>
                                <div className='mt-4'>
                                    <div className='flex justify-between items-center'>
                                        <h1 className='text-xl font-semibold flex items-center gap-2 text-black'>
                                            <i className="uil uil-car"></i>
                                            {carDetails?.name}
                                        </h1>
                                        <p className='font-semibold text-sm text-green-500'>
                                            {carDetails?.isActive ? 'Available' : 'Not Available'}
                                        </p>
                                    </div>
                                    <div className='mt-4 text-dark flex flex-col gap-2'>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>Rent Per Hour :</h6>
                                            <p>
                                                <span>&#8377;</span>
                                                <span> {carDetails?.rentPerHour}</span>
                                            </p>
                                        </div>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>
                                                Pickup Location 
                                            </h6>
                                            <div className='text-right'>
                                                <a 
                                                href={`https://www.google.com/maps/search/?api=1&query=${carDetails?.location?.lat},${carDetails?.location?.lng}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='underline text-primary'
                                                >
                                                    {carDetails?.location?.address}
                                                </a>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>
                                                Drop off Location 
                                            </h6>
                                            <div className='text-right'>
                                                <a 
                                                href={`https://www.google.com/maps/search/?api=1&query=${carDetails?.dropOffLocation?.lat},${carDetails?.dropOffLocation?.lng}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='underline text-primary'
                                                >
                                                    {carDetails?.dropOffLocation?.address}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8">
                                <div className='flex items-center justify-between'>
                                    <h3 className='font-semibold text-xl'>Features</h3>
                                    <Link to={`/bookings/book-now/${id}`}>
                                        <button className="btn-primary py-2 px-6">
                                            Book Now
                                        </button>
                                    </Link>
                                </div>

                                <div className='mt-6 shadow-bg p-4'>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Brand</h6>
                                        <p>
                                            {carDetails?.make}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Model</h6>
                                        <p>
                                            {carDetails?.model}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Vehicle Type</h6>
                                        <p>
                                            {carDetails?.vehicleType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Transmission</h6>
                                        <p>
                                            {carDetails?.transmissionType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Fuel Type</h6>
                                        <p>
                                            {carDetails?.fuelType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>License Plate</h6>
                                        <p>
                                            {carDetails?.licensePlate}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Seats</h6>
                                        <p>
                                            {carDetails?.seats}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Doors</h6>
                                        <p>
                                            {carDetails?.doors}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>
                                            Air Conditioning
                                        </h6>
                                        <p>
                                            {
                                                carDetails?.airConditioning ? 'Yes' : 'No'
                                            }
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>
                                            Audio System
                                        </h6>
                                        <p>
                                            {carDetails?.audioSystem}
                                        </p>
                                    </div>
                                    <div className='py-4'>
                                        <h6 className='font-semibold'>
                                            Pickup Instructions
                                        </h6>
                                        <p className='text-gray-500 text-sm mt-2'>
                                            {carDetails?.pickupInstructions}
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='mt-10'>
                                <h1 className="section-heading text-gradient">
                                    Reviews
                                </h1>
                                <div>
                                    {
                                        reviews?.length > 0 
                                        ? 
                                            <Reviews
                                            reviews={reviews}
                                            />
                                        : 
                                            <ItemNotFound message='No review found.' />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                :
                    <ItemNotFound message='Car details not found.' />
            }
        </div>
    )
}

export default VehicleDetails