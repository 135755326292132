import KiranImg from 'assets/images/kiran.jpeg'

const Kiran = () => {
    return (
        <div className='mt-10'>
            <h1 className='text-center section-heading text-gradient'>Co-founder and Chief Operating Officer Profile: Kiran Bhaskar Dorkhe</h1>
            <div className="flex items-center justify-between gap-4 md:flex-row flex-col-reverse mt-10">
                <div className='flex-[1.5]'>
                    <br />
                    <p>
                        <b>Biography:</b> Kiran Bhaskar Dorkhe is a dynamic force and COO of SAK RENT N DRIVE LUXURY PVT.LTD. Rental Cars. With a deep-rooted passion for travel and a keen business acumen, he have played a pivotal role in shaping the company's foundation and growth.

                    </p>
                    <p className='mt-4'>
                        <b>Entrepreneurial Journey</b>: Bringing a wealth of experience in relevant industries, Kiran Dorkhe co-founded SAK RENT N DRIVE LUXURY PVT.LTD. with a vision to revolutionize the rental car industry. His entrepreneurial spirit and innovative mindset have been instrumental in the company's journey from inception to becoming a key player in the market.
                    </p>
                </div>
                <div className='flex-1 flex items-center justify-center'>
                    <img
                        src={KiranImg}
                        alt=""
                        className='sm:w-[250px] w-full rounded-md'

                    />
                </div>
            </div>
            <div className='mt-6 flex flex-col gap-4'>
                <p>
                    <b>Visionary Ideation</b>: Kiran Dorkhe is the driving force behind many of the innovative ideas that have shaped SAK RENT N DRIVE LUXURY PVT.LTD's commitment to excellence. His visionary approach extends beyond business operations, encompassing a dedication to customer satisfaction, technological advancement, and environmental responsibility.
                </p>

                <p>
                    <b>Leadership in Innovation</b>: As a co-founder, Kiran Dorkhe has led the charge in embracing technological advancements within the company. He understand the importance of staying ahead in a rapidly evolving industry and have spearheaded initiatives to integrate cutting-edge technologies for the benefit of both the business and its customers.
                </p>

                <p>
                    <b>Passion for Sustainability</b>: Reflecting Kiran Dorkhe's commitment to environmental responsibility, he have been instrumental in integrating sustainable practices within the company's operations. Whether it's introducing eco-friendly vehicle options or promoting green initiatives, Kiran Dorkhe embodies SAK RENT N DRIVE LUXURY PVT.LTD's dedication to a more sustainable future.
                </p>

                <p>
                    <b>Collaborative Leadership Style</b>: Known for His collaborative leadership style, Kiran Dorkhe fosters a culture of teamwork and shared vision within the company. He believe in empowering the team to contribute their best, fostering an environment where creativity and innovation thrive.
                </p>

                <p>
                    <b>Community Involvement</b>: Beyond business pursuits, Kiran Dorkhe is actively involved in community engagement efforts. His belief in corporate social responsibility is reflected in initiatives that contribute positively to the communities served by SAK RENT N DRIVE LUXURY PVT.LTD.
                </p>

                <p>
                    <b>Conclusion</b>: Kiran Bhaskar Dorkhe is not just a co-founder; he are an integral part of SAK RENT N DRIVE LUXURY PVT.LTD's DNA. His passion for travel, commitment to innovation, and dedication to sustainability have left an indelible mark on the company's identity. Under Kiran Dorkhe's guidance, SAK RENT N DRIVE LUXURY PVT.LTD. Rental Cars continues to grow, adapt, and thrive in an ever-changing industry. The collaborative spirit and visionary leadership of Kiran Dorkhe remain fundamental to the company's success and its mission to empower seamless journeys for every customer.
                </p>

                <p>
                    <b>Chief Operating Officer (Business head): Kiran Dorkhe</b>
                    As the Chief Operating Officer at SAK RENT N DRIVE LUXURY PVT.LTD Rental Cars, Kiran Dorkhe is the driving force behind the seamless operations that make our company stand out in the competitive rental car industry. With a wealth of experience and a passion for excellence, Kiran Dorkhe oversees the day-to-day activities that ensure our customers enjoy a hassle-free and enjoyable journey with us. Kiran Dorkhe is committed to implementing innovative solutions and maintaining the highest standards of operational efficiency. Under Kiran Dorkhe's leadership, SAK RENT N DRIVE LUXURY PVT.LTD Rental Cars continues to evolve, setting new benchmarks and exceeding customer expectations. Kiran Dorkhe's strategic vision aligns with our commitment to reliability, flexibility, and environmental responsibility, making SAK RENT N DRIVE LUXURY PVT.LTD a leader in delivering exceptional mobility solutions.
                </p>

            </div>

        </div>
    )
}

export default Kiran