import FormControl from 'components/form/FormControl';
import { useAuthPopupContext } from 'context/AuthPopupContext'
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { login } from 'redux/actions/authActions';
import loginValidations from 'validations/loginValidation';

const initValues = {
    identifier : '' ,
    password : '' , 
}
const Login = () => {
    const dispatch = useDispatch();
    const { setCurrentStep , setShowAuthPopup } = useAuthPopupContext();
    const { loading } = useSelector(state => state.auth);

    const handleSubmit = (formData , onSubmitProps ) => {
        dispatch(login(formData , setShowAuthPopup , setCurrentStep , onSubmitProps ))
    }

    return (
        <div>
            <center>
                <h1 className='text-2xl font-bold'>Welcome Back</h1>
                <p className='text-sm text-grayText mt-1'>Please fill your detail to access your account.</p>
            </center>
            <Formik
            initialValues={initValues}
            validationSchema={loginValidations}
            onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form
                            className='mt-8'
                            >
                                <FormControl
                                control='input'
                                label='Email or Phone'
                                placeholder='Enter your email or phone'
                                name='identifier'
                                formik={formik}
                                />
                                <FormControl
                                control='password' 
                                label='Password'
                                placeholder='Ex : ******** '
                                name='password'
                                formik={formik}
                                />
                                <div className='text-right'>
                                    <button 
                                    type='button' 
                                    className='text-gray-500 underline text-sm '
                                    onClick={() => setCurrentStep('forgot-password')}
                                    >
                                        Forgot Password
                                    </button>
                                </div>
                                <div className='mt-2'>
                                    <button
                                    type='submit' 
                                    className="btn-primary py-3 sm:px-12 px-6 w-full"
                                    disabled={loading || !formik.isValid}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <ClipLoader size={20} color='white' />
                                            : 
                                                'Sign in'
                                        }
                                    </button>
                                    <div className='text-sm text-dark mt-6 text-center flex items-center justify-center gap-1'>
                                        <span>Don’t have an account ? </span> 
                                        <button 
                                        className='text-gradient'
                                        onClick={() => setCurrentStep('register')}
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>

        </div>
    )
}

export default Login