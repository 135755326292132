import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { calculateBooking } from 'redux/actions/bookingActions';


const PickupDate = ({ bookingData, setBookingData, futureBookings , discountDetails , carDetails , setDiscountDetails , settings  , ...rest }) => {
    const { id } = useParams();
    const [tooltipDate, setTooltipDate] = useState(null);
    const dispatch = useDispatch();

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleDateChange = (date) => {
        if (!date) {
            // Handle case when date is cleared
            setBookingData(prev => ({ ...prev, pickupDate: null }));
            return;
        }

        const selectedDate = new Date(date);
        const today = new Date();
        if (
            selectedDate.getDate() === today.getDate() &&
            selectedDate.getMonth() === today.getMonth() &&
            selectedDate.getFullYear() === today.getFullYear() &&
            selectedDate.getHours() === 0 &&
            selectedDate.getMinutes() === 0
        ) {
            selectedDate.setHours(today.getHours());
            selectedDate.setMinutes(today.getMinutes());
            selectedDate.setSeconds(today.getSeconds());
        }
        if (bookingData?.returnDate && selectedDate > bookingData?.returnDate) {
            return toast.error('You are trying to select an invalid date.');
        }

        if (selectedDate && bookingData.returnDate && selectedDate < bookingData?.returnDate ) {
            dispatch(calculateBooking({ 
                bookingData : {...bookingData , pickupDate : selectedDate } ,
                discountDetails ,
                carId : id ,
                setBookingData ,
                setDiscountDetails
            }))
        } else {
            setBookingData(prev => ({...prev , pickupDate : selectedDate }))
        }
    };

    const renderDayContents = (day, date) => {
        const allUnavailableDates = [
            ...futureBookings.map(({ pickupDate, returnDate }) => ({ start: new Date(pickupDate), end: new Date(returnDate) })),
            ...(Array.isArray(carDetails?.unavailableDates) ? carDetails.unavailableDates.map(({ startDate, endDate }) => ({ start: new Date(startDate), end: new Date(endDate) })) : [])
        ];

        const isExcluded = allUnavailableDates.some(({ start, end }) => {
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            return date >= start && date <= end;
        });

        const handleMouseEnter = () => {
            setTooltipDate(date);
        };

        const handleMouseLeave = () => {
            setTooltipDate(null);
        };

        if (isExcluded) {
            return (
                <div className="text-red-500" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <span className="block">{day}</span>
                    {tooltipDate && tooltipDate.getTime() === date.getTime() && (
                        <div className="absolute bg-red-500 text-sm text-white px-2 py-1 rounded-md z-10">
                            Not Available
                        </div>
                    )}
                </div>
            );
        }

        return day;
    };

    const excludeDateIntervals = futureBookings?.map(({ pickupDate, returnDate }) => {
        const start = new Date(pickupDate);
        const end = new Date(returnDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return { start, end };
    });


    const unavailableDates = Array.isArray(carDetails?.unavailableDates) ? carDetails.unavailableDates.map(({ startDate, endDate }) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return { start, end };
    }) : [];

    const allUnavailableDates = [...excludeDateIntervals, ...unavailableDates];

    return (
        <div className="w-full flex flex-col gap-1.5">
            <label className="text-primary font-semibold">Pickup Date & Time</label>
            <DatePicker
                selected={bookingData.pickupDate}
                minDate={new Date()}
                placeholderText="Select pickup date & time"
                onChange={handleDateChange}
                showTimeSelect
                name="pickupDate"
                value={bookingData?.pickupDate}
                dateFormat="MMMM d, yyyy h:mm aa"
                excludeDateIntervals={allUnavailableDates}
                filterTime={filterPassedTime}
                renderDayContents={renderDayContents}
                autoComplete='off'
                {...rest}
            />
        </div>
    );
};

export default PickupDate;
