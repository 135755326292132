import RatingStars from 'components/global/RatingStars';
import Axios from 'config/api';
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useClickOutside from 'utils/clickOutside';
import toastError from 'utils/toastError';

const initState = {
    rating : 1 ,
    review : ''
};

const ReviewPopup = ({ showReviewPopup , setShowReviewPopup , carId }) => {
    const reviewPopupRef = useRef(null);
    const { user } = useSelector(state => state.auth);
    const [reviewData , setReviewData] = useState(initState);
    const [loading , setLoading] = useState(false);

    useClickOutside(reviewPopupRef , () => setShowReviewPopup(false));

    const submitHandler = async e => {
        e.preventDefault();
        if(!reviewData?.rating) return toast.error('Please select rating.', { autoClose : 3000 });

        const body = { ...reviewData , car : carId }
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post('/review' , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setReviewData(initState);
            setShowReviewPopup(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <div className='fixed top-0 left-0 w-full bg-black bg-opacity-50 flex items-center justify-center px-3 h-screen z-50'>
            <div 
            className="authPopup bg-white lg:w-[50%] md:w-[65%] sm:w-[80%] w-full rounded-lg md:px-20 sm:px-16 px-4 py-8 relative"
            ref={reviewPopupRef}
            >
                <div 
                className='absolute top-2 right-3 text-2xl cursor-pointer font-semibold w-fit' 
                onClick={() => setShowReviewPopup(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                <div>
                    <center>
                        <h1 className='text-xl font-semibold text-gradient'>
                            Share Your Car Experience
                        </h1>
                        <p className='text-sm text-grayText mt-1'>
                             Your feedback helps us enhance our services.
                        </p>
                    </center>
                    <form 
                    onSubmit={submitHandler} 
                    className='mt-12 flex flex-col gap-4'
                    >
                        <div className="">
                            <label className='label'>Select Rating</label>
                            <RatingStars
                            setData={setReviewData}
                            />
                        </div>
                         <div className='flex flex-col gap-2'>
                            <label className='label'>Review</label>
                            <textarea 
                            className='input w-full h-[100px] resize-none' 
                            placeholder='Write your review here...'
                            value={reviewData?.review}
                            onChange={e => setReviewData(prev => ({...prev , review : e.target.value }))}
                            maxLength={150}
                            required
                            />
                            <div className='text-right'>
                                {reviewData?.review?.length}/150
                            </div>
                        </div> 
                        <div className="mt-4">
                            <button 
                            className="btn-primary py-2 px-4 "
                            disabled={loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='white' /> 
                                    : 
                                        'SUBMIT REVIEW'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReviewPopup;