import * as Yup from 'yup';

const registerValidations = Yup.object({
    fullName: Yup.string()
        .min(3, 'Name should have at least 3 characters')
        .max(30, 'Name should not exceed 50 characters')
        .required('Name is required'),
  
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ ,
            'Invalid email address.'
        ),
  
    phone : Yup.string()
        .min(10, 'Phone should have at least 10 numbers')
        .max(10, 'Phone should not exceed 10 numbers')
        .required('Phone is required')
        .matches(
            /^[6-9]\d{9}$/, 
            'Invalid phone no.'
        ) ,
    
    password: Yup.string()
        .min(8, 'Password should have at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character'
        )
        .required('Password is required'),
});

export default registerValidations;