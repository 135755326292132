import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const PageContent = () => {
    const { slug } = useParams();
    const { homePageContent } = useSelector(state => state.home);

    let city = slug?.split('-')?.pop();
    city = city?.charAt(0).toUpperCase() + city?.slice(1);
    let content = slug ? homePageContent?.content?.replace(/India/g, city) : homePageContent?.content;

    


    return (
        <div className='container mx-auto sm:px-12 px-3 py-12 pb-20'>
            <h2 className='section-heading text-center'>
                Affordable <span className='text-gradient'>and Convenient</span> Options
            </h2> 
            <div
                className="blog-content mt-4 text-lg"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    )
}

export default PageContent