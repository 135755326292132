import LocationLink from 'components/global/LocationLink'
import VehicleImagesPopup from 'components/vehicles/VehicleImagesPopup'
import { baseURL } from 'config/api'
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

const CarInfo = ({ carDetails }) => {
    const [showImagesPopup , setShowImagesPoup] = useState(false);

    return (
        <div className='mt-6 flex gap-6 sm:flex-row flex-col'>
            <div 
            className='flex-[0.3] flex items-center cursor-pointer overflow-hidden rounded-md'
            onClick={() => setShowImagesPoup(true)}
            >
                {
                    carDetails?.images && 
                    <img 
                    src={baseURL + '/cars/' + carDetails?.images[0]} 
                    alt={carDetails?.name}
                    className='object-cover w-[250px] h-[200px] rounded-md hover:scale-[1.1]  duration-200 ease-in-out'
                    />
                }
            </div>
            {
                showImagesPopup && 
                <VehicleImagesPopup 
                setShowImagesPopup={setShowImagesPoup}
                images={carDetails?.images}
                />
            }
            <div className='flex-[0.55] flex flex-col gap-3'>
                <div className="bg-gradient rounded-md text-xs text-pure py-1 px-2 w-fit mt-4">
                    Top Pick
                </div>
                <Link
                to={`/vehicle-details/${carDetails?._id}`}  
                className='text-lg font-bold underline w-fit'>
                    {carDetails?.name}
                </Link>
                <div className='flex gap-8'>
                    <div className='flex flex-col'>
                        <div className='flex items-center gap-2'>
                            <i className="uil uil-user"></i>
                            <span className='text-xs'>
                                {carDetails?.seats} Seats
                            </span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <i className="uil uil-suitcase-alt"></i>
                            <span className='text-xs'>{carDetails?.doors} Doors</span>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex items-center gap-2'>
                            <i className="uil uil-auto-flash"></i>
                            <span className='text-xs capitalize'>
                                {carDetails?.transmissionType}
                            </span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <i className="uil uil-dashboard"></i>
                            <span className='text-xs'>
                                {carDetails?.mileage} mileage
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-center gap-1'>
                        <span className='text-sm'>Pickup Location : </span>
                        <LocationLink location={carDetails?.location} />
                    </div>
                    <div className='flex items-center gap-1'>
                        <span className='text-sm'>Drop Off Location : </span>
                        <LocationLink
                        location={carDetails?.dropOffLocation || carDetails?.location}
                        />
                    </div>
                    <span className="text-gray-600 text-[13px] font-semibold">
                        Type : {carDetails?.vehicleType}
                    </span>
                </div>
            </div>
            <div className='flex-[0.15] flex items-end justify-end flex-col'>
                <span className='text-[13px]'>Rent Per Hour</span>
                <h6 className='text-xl font-bold mt-1 mb-2'>Rs {carDetails?.rentPerHour}</h6>
            </div>
        </div>
    )
}

export default memo(CarInfo)