import { baseURL } from 'config/api';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Accordion from 'react-accordion-comp';

const DropOffPhotos = ({ booking }) => {
    const [show , setShow] = useState(false);

    useEffect(() => {
        setShow(true)
    } , []);

    return (
        <div className='shadow-bg p-4 mt-6'>
            <div 
            className={`flex items-center justify-between cursor-pointer `} 
            onClick={() => setShow(!show)}
            >
                <h3 className="text-lg font-semibold text-primary">
                    Drop Off Photos
                </h3>
                <div className='text-2xl'>
                    <i className="uil uil-angle-down"></i>
                </div>
            </div>
            <Accordion isOpen={show}>
                <div className="flex items-center justify-center">
                    <div className='mt-4 xl:w-[60%] md:w-[70%] w-full'>
                        <Carousel
                        autoPlay
                        interval={2000}
                        infiniteLoop
                        className='w-full h-full'
                        >
                            {
                                Object.entries(booking?.dropOffPhotos)?.map(([key , value]) => {
                                    return <div key={key}>
                                        <img 
                                        src={baseURL + '/bookings/' + value} 
                                        alt={key} 
                                        className='w-auto h-auto rounded-md'
                                        />
                                    </div>
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </Accordion>
        </div>

    )
}

export default DropOffPhotos;