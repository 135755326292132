import { useEffect, useState } from 'react';
import Axios from 'config/api';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toastError from 'utils/toastError';
import { ClipLoader } from 'react-spinners';
import { setUser } from 'redux/reducers/authReducer';
import { Form, Formik } from 'formik';
import FormControl from 'components/form/FormControl';
import kycValidations from 'validations/kycValidations';
import UploadSelfie from 'components/kyc/UploadSelfie';

const additionalDocumentTypes = ['Rent Agreement', 'Electricity Bill', 'Official ID Card', 'College ID Card', 'Voter ID Card'];

const KycDetailsForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user } = useSelector(state => state.auth);

    const initValues = {
        aadhaarCardNumber : localStorage.getItem('aadhaarNumber') ,
        licenseCardNumber : '' ,
        aadhaarCardFrontImage : '' ,
        aadhaarCardBackImage : '' ,
        licenseCardFrontImage : '' ,
        licenseCardBackImage : '' ,
        additionalDocumentType : additionalDocumentTypes[0] ,
        additionalDocumentImage : ''
    }

    const [loading , setLoading] = useState(false);
    const [selfie , setSelfie] = useState('');

    useEffect(() => {
        const step1 = localStorage.getItem('step1');
        const kycVerified = JSON.parse(localStorage.getItem('kycVerified'));
        // if(!step1 || step1 !== 'completed') {
        //     navigate('/kyc/step1')
        // }else if (step1 && step1 === 'completed' && !kycVerified){
        //     navigate('/kyc/verify-otp')
        // }
    }, []);

    const submitHandler = async (formData) => {
        setLoading(true);
        if(!selfie) return toast.error('Selfie is required.')
        formData.selfie = selfie;
        try {
            const { data : { data : { message , user : _user , doc } } } = await Axios.post(`/kyc` , formData  , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            localStorage.removeItem('aadhaarNumber');
            localStorage.removeItem('step1');
            localStorage.removeItem('kycVerified');
            localStorage.setItem('user' , JSON.stringify({..._user , token : user?.token }));
            dispatch(setUser({..._user , token : user?.token  }));
            toast.success(message);
            if(searchParams.get('ref') === 'book-now') {
                navigate(`/bookings/book-now/${searchParams.get('vehicle')}?ref=kyc&status=completed` , { replace : true });
            }else {
                navigate(`/kyc/details` , { replace : true });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }


    return (
        <div className='my-12 px-3'>
            <div className="container mx-auto flex items-center justify-center">
                <div className='shadow-bg  md:w-[70%] sm:w-[80%] w-full p-6'>
                    <div className='flex items-center justify-between'>
                        <h1 className='section-heading text-gradient'>
                            Add Kyc Details
                        </h1>
                    </div>
                    <Formik
                    initialValues={initValues}
                    onSubmit={submitHandler}
                    validationSchema={kycValidations}
                    >
                        {
                            formik => (
                                <Form
                                className='mt-6'
                                >
                                    <FormControl
                                    control='input'
                                    type='number'
                                    name='aadhaarCardNumber'
                                    label='Aadhaar Card Number'
                                    formik={formik}
                                    readOnly
                                    disabled
                                    />
                                    <FormControl
                                    control='input'
                                    label='Driving License'
                                    placeholder='Enter your driving license number'     
                                    name='licenseCardNumber'
                                    formik={formik}
                                    />
                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <FormControl
                                        control='file'
                                        label='Aadhaar Card Front Image' 
                                        name='aadhaarCardFrontImage'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='file'
                                        label='Aadhaar Card Back Image' 
                                        name='aadhaarCardBackImage'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <FormControl
                                        control='file'
                                        label='License Card Front Image' 
                                        name='licenseCardFrontImage'
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='file'
                                        label='License Card Back Image' 
                                        name='licenseCardBackImage'
                                        formik={formik}
                                        />
                                    </div>

                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <FormControl
                                        control='select'
                                        label='Choose Additional Document Type' 
                                        name='additionalDocumentType'
                                        options={additionalDocumentTypes.map(item => ({
                                            key : item , value : item 
                                        }))}
                                        formik={formik}
                                        />
                                        <FormControl
                                        control='file'
                                        label={formik?.values?.additionalDocumentType} 
                                        name='additionalDocumentImage'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <UploadSelfie 
                                        image={selfie}
                                        setImage={setSelfie}
                                        />
                                    </div>
                                    <div className="mt-8">
                                        <button 
                                        type='submit'
                                        className="btn-primary py-2 px-12"
                                        disabled={loading || !formik.isValid || !selfie}
                                        >
                                            {
                                                loading 
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Submit'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default KycDetailsForm;