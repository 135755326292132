import BackBtn from 'components/global/BackBtn'
import moment from 'moment'
import React from 'react'

const BlogDetailsCover = ({ heading , image , date }) => {
    return (
        <div 
        className='w-full lg:h-[400px] h-[250px]'
        style={{
            backgroundImage : `url(${image})`,
            backgroundSize : 'cover' ,
            backgroundRepeat :'no-repeat' ,
            backgroundPosition : 'right'
        }}
        >
            <div className='relative cover-clip-path bg-black bg-opacity-80 2xl:w-[55%] lg:w-[75%] w-full h-full flex flex-col  justify-center md:items-start md:pl-40 items-center px-4'>
                <div className='text-pure absolute top-4 left-4'>
                    <BackBtn />
                </div>
                <h1 className='lg:text-3xl md:text-2xl text-xl sm:font-bold font-semibold text-pure !leading-[1.5] md:text-left text-center md:w-[450px]'>
                    {heading}
                </h1>
                <p className='mt-4 text-gray-500 sm:text-base text-sm'>
                    {moment(date).format('DD MMM YYYY')}
                </p>
            </div>
        </div>
    )
}

export default BlogDetailsCover