import { useContext , createContext , useState } from "react";

const AuthPopupContext = createContext();

export const useAuthPopupContext = () => useContext(AuthPopupContext)

const AuthPopupContextProvider = ({ children }) => {
    const [showAuthPopup , setShowAuthPopup] = useState(false);
    const [currentStep , setCurrentStep] = useState('login'); 

    return (
        <AuthPopupContext.Provider 
            value={{
                showAuthPopup , setShowAuthPopup ,
                currentStep , setCurrentStep
            }}
        >
            {children}
        </AuthPopupContext.Provider>
    )
}

 
export default AuthPopupContextProvider;