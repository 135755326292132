import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'
import CallImg from 'assets/images/call.png';
import LocationSvg from 'assets/svgs/LocationSvg';
import PhoneSvg from 'assets/svgs/PhoneSvg';
import MailSvg from 'assets/MailSvg';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import ContactForm from './ContactForm';

const initState = {
    username : '' , 
    email : '' , 
    phone : '' , 
    message : '' , 
};

const GetInTouch = ({ isLoading }) => {
    const { settings } = useSelector(state => state.home);
    const [loading , setLoading] = useState(false);
    const [mailData , setMailData] = useState(initState);
    const [isValid , setIsValid] = useState(false);

    const submitHandler = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post('/home/support-email' , mailData );
            toast.success(message , {
                autoClose : 4000
            });
            setMailData(initState);
            setIsValid(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error)
        }
    }

    return (
        <section className='bg-[#eafff4] py-12' id='contact'>
            <div className='container mx-auto flex items-center justify-between md:flex-row flex-col md:gap-0 gap-12 sm:px-0 px-4'>
                <div className='flex-1 w-full x-space'>
                    <h2 className="section-heading text-gradient">
                        Get In Touch
                    </h2>
                    <ContactForm />
                </div>
                <div className="flex-1">
                    <LazyLoad className='flex items-center justify-center'>
                        <img 
                        src={CallImg} 
                        alt="call"
                        className='w-1/2 object-cover' 
                        />
                    </LazyLoad>
                    {
                        isLoading 
                        ? 
                            <ClipLoader size={20} />
                        : 
                            <div className='flex flex-col gap-4 sm:pl-40 justify-center mt-8'>
                                <div className='flex items-center gap-4'>
                                    <div className='shadow-lg  w-[40px] h-[40px] flex items-center justify-center border border-secondary rounded-full p-2'>
                                        <LocationSvg />
                                    </div>
                                    <div className='text-dark text-sm'>
                                        {settings?.officeAddress}
                                    </div>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <div className='shadow-lg  w-[40px] h-[40px] flex items-center justify-center border border-secondary rounded-full p-2'>
                                        <PhoneSvg />
                                    </div>
                                    <a href={`tel:+91${settings?.contactNo}`} className='text-dark text-sm'>
                                        +91 {settings?.contactNo}
                                    </a>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <div className='shadow-lg  w-[40px] h-[40px] flex items-center justify-center border border-secondary rounded-full p-2'>
                                        <MailSvg />
                                    </div>
                                    <a href={`mailto: ${settings?.contactEmail}`} className='text-dark text-sm'>
                                        {settings?.contactEmail}
                                    </a>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default GetInTouch