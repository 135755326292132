import faqsData from 'data/faqsData';
import { useState } from 'react';
import Accordion from 'react-accordion-comp';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';




const FaqItem = ({ item }) => {
    const [show , setShow] = useState(false);
    

    return (
        <div className='text-white border-b pb-4'>
            <div 
            className='flex items-center justify-between cursor-pointer'
            onClick={() => setShow(!show)}
            >
                <h3 className='text-lg '>
                     {item?.question}
                </h3>
                {
                    show 
                    ? 
                        <i className="uil uil-angle-up text-2xl"></i>
                    : 
                        <i className="uil uil-angle-down text-2xl"></i>
                }
            </div>
            <Accordion isOpen={show}>
                <div className="mt-4  text-gray-100 text-[15px] leading-[1.6] font-normal">
                    -- {item?.answer}
                </div>
            </Accordion>
        </div>
    )
}

const replaceCityInFaqs = (data, city) => {
    city = city?.charAt(0).toUpperCase() + city?.slice(1);
    return data.map(faq => ({
        question: faq.question.replace(/India/g, city),
        answer: faq.answer.replace(/India/g, city)
    }));
};

const Faq = () => {
    const { slug } = useParams();
    const { homePageContent } = useSelector(state => state.home);

    const city = slug?.split('-')?.pop();

    const faqsWithCity = slug ? replaceCityInFaqs(faqsData , city) : faqsData
    let content = slug ? homePageContent?.content?.replace(/India/g, city) : homePageContent?.content;

    return (
        <>
            
            <div className='bg-primary py-10' id='faqs'>
                <h2 className="section-heading text-white text-center">
                    Frequently Asked Questions
                </h2>
                <div className='lg:w-[800px] md:w-[600px] w-full px-4 mx-auto flex flex-col gap-4 mt-16'>
                    {
                        faqsWithCity?.map(item => (
                            <FaqItem key={item.question} item={item} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Faq