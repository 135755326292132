import { useEffect } from "react";

function GlobalEventListeners() {
    useEffect(() => {
        // Disable text selection
        document.addEventListener('selectstart', function (e) {
            e.preventDefault();
        });

        return () => {
            // Cleanup event listeners on unmount
            document.removeEventListener('selectstart', this);
        };
    }, []);

    return null;
}

export default GlobalEventListeners;