import './styles.css';
import { useAuthPopupContext } from 'context/AuthPopupContext'
import React, { useEffect, useRef } from 'react'
import Login from './Login';
import useClickOutside from 'utils/clickOutside';
import VerifyOtp from './VerifyOtp';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const AuthPopup = () => {
    const popupRef = useRef(null);
    const { currentStep , setShowAuthPopup , setCurrentStep } = useAuthPopupContext();

    // useClickOutside(popupRef , () => setShowAuthPopup(false));

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        
        return () => {
            document.body.style.overflow = 'auto';
            setCurrentStep('login');
        }
    }, [])

    return (
        <div className='fixed top-0 left-0 w-full bg-black bg-opacity-50 flex items-center justify-center px-3 h-screen z-50'>
            <div 
            className="authPopup bg-white lg:w-[50%] md:w-[65%] sm:w-[80%] w-full rounded-lg lg:px-32 md:px-28 sm:px-16 px-4 py-8 relative"
            // ref={popupRef}
            >
                <div 
                className='absolute top-2 right-3 text-2xl cursor-pointer font-semibold w-fit' 
                onClick={() => setShowAuthPopup(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                {
                    currentStep === 'login'
                    ? 
                        <Login />
                    :
                    currentStep === 'otp'
                    ? 
                        <VerifyOtp />
                    : 
                    currentStep === 'register'
                    ? 
                        <Register />
                    :
                    currentStep === 'forgot-password'
                    ? 
                        <ForgotPassword />
                    :
                    currentStep === 'reset-password'
                    ? 
                        <ResetPassword />
                    :
                        ''
                }
            </div>
        </div>
    )
}

export default AuthPopup