import React, { useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import Img1 from 'assets/images/img1.jpg';
import TextError from './TextError';

const FileInput = ({ label, field, form, height = '200px' , formik }) => {
    const imgRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            form.setFieldValue(field.name, reader.result);
        };
    };

    return (
        <div className='w-full'>
            <div className='w-full flex-1 flex flex-col gap-2'>
                <label className='text-dark font-semibold'>{label}</label>
                <input
                    ref={imgRef}
                    accept='image/*'
                    type='file'
                    onChange={handleFileChange}
                    hidden
                />
                <div
                    className={`
                    flex items-center justify-between border px-3 rounded-md cursor-pointer
                    ${formik.touched[field.name] && formik.errors[field.name] ? 'border-red' : null }
                    `}
                    onClick={() => imgRef?.current?.click()}
                >
                    <div>Choose File</div>
                    <div className='py-3 px-6 border-l'>Browse</div>
                </div>
            </div>
            <div className='mt-4 border-2 border-dashed w-fit'>
                <img
                    src={field.value || Img1}
                    alt=''
                    className={`min-w-[250px] w-full object-cover`}
                    style={{ height: height }}
                />
            </div>
        </div>
    );
};

const FormikFileInput = ({ label, name, height , formik , ...rest }) => (
    <div className='flex-1 flex flex-col gap-2'>
        <Field name={name}>
            {({ field , form }) => (
                <FileInput
                    label={label}
                    field={field}
                    form={form}
                    height={height ? height : '200px'}
                    formik={formik}
                    {...rest}
                />
            )}
        </Field>
        <ErrorMessage component={TextError} name={name} />
    </div>
);

export default FormikFileInput;
