import { createSlice } from "@reduxjs/toolkit";

const blogCategorySlice = createSlice({
    name : 'blogCategory' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        selectedDoc : '' ,
        loading : false , 
        docsCount : 0 ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        setSelectedDoc (state , action) {
            state.selectedDoc = action.payload
        } ,
        addDoc (state , action) {
            state.docs = [action.payload, ...state.docs];
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        removeDoc (state , action) {
            state.docs = state.docs.filter(i => i._id !== action.payload);
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
    }
});

export const { 
    setDocs , setDocDetails , setLoading , updateDoc , removeDoc , setDocsCount , addDoc , setSelectedDoc 
} = blogCategorySlice.actions;

export default blogCategorySlice.reducer;