import Breadcrumbs from 'components/global/Breadcrumbs';
import React, { useState, useEffect } from 'react';



const crumbs = [
    { label: "Home", path: "/" },
    { label: "Terms And Conditions",  }
]

const TermsAndConditions = () => {

    return (
        <div>

            <div className='flex items-center justify-center px-3 ' >
                <div className='bg-white rounded-md md:p-10 sm:p-6 py-4 lg:w-[70%] md:w-[80%] sm:w-[90%] w-full '>
                    <Breadcrumbs crumbs={crumbs} />
                    <h1 className="section-heading text-center text-gradient mt-4">
                        Terms And Conditions
                    </h1>
                </div>
            </div>
            <div className='xl:w-[60%] lg:w-[70%] md:w-[80%] w-[95%] mx-auto'>
            <>
                <p
                    style={{
                    textIndent: "36pt",
                    textAlign: "justify",
                    lineHeight: "25px",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: '"poppins"' }}>
                    This document sets out the terms and conditions for renting vehicles from
                    M/s SAK Rent N Drive Luxury Private Limited, a company incorporated under
                    the Companies Act, 2013, with CIN{" "}
                    </span>
                    <strong>
                    <span style={{ fontFamily: '"poppins"' }}>
                        U79120PN2023PTC223156
                    </span>
                    </strong>
                    <span style={{ fontFamily: '"poppins"' }}>
                    {" "}
                    (hereinafter referred to as "Rent N Drive" which expression shall unless
                    repugnant to the context or meaning include its heirs and successor).
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt", marginTop: "15px" , marginBottom : '15px' }}>
                    <strong>
                    <u>
                        <span style={{ fontFamily: '"poppins"' }}>
                        LEGAL DISCLAIMER
                        </span>
                    </u>
                    </strong>
                    <span style={{ fontFamily: '"poppins"' }}>: </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: '"poppins"' }}>
                    THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY
                    ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE AMENDED PROVISIONS
                    PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
                    INFORMATION TECHNOLOGY ACT, 2000. THIS ELECTRONIC RECORD IS GENERATED BY A
                    COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                    </span>
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
                    OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT
                    REQUIRE PUBLISHING THE RULES AND REGULATIONS, TERMS OF SERVICES, PRIVACY
                    POLICY AND USER AGREEMENT FOR ACCESS OR TERMS OF USAGE OF{" "}
                    </span>
                    <strong>
                    <u>
                        <span style={{ fontFamily: '"poppins"' }}>
                        www.Rent N Drive.co.in
                        </span>
                    </u>
                    </strong>
                    <span style={{ fontFamily: '"poppins"' }}>
                    {" "}
                    (HEREINAFTER REFERRED TO AS THE “SITE”).
                    </span>
                    <br />
                    <span style={{ fontFamily: '"poppins"' }}>
                    YOUR USE OF THIS SITE, OR YOUR PROVISION TO USE OF ANY PERSONAL
                    INFORMATION CONSTITUTES YOUR AGREEMENT TO THESE TERMS AND ANY SUBSEQUENT
                    CHANGES TO THESE TERMS; DO NOT USE THE SITE OR PROVIDE INFORMATION IF YOU
                    DO NOT AGREE WITH ALL OF THE TERMS.
                    </span>
                    <br />
                    <strong>
                    <span style={{ fontFamily: '"poppins"' }}>&nbsp;</span>
                    </strong>
                </p>
</>

                <p
                    style={{
                    marginBottom: "0pt",
                    textAlign: "justify",
                    lineHeight: "normal",
                    fontSize: "14pt"
                    }}
                >
                    <br />
                    <strong>
                    <span style={{ fontFamily: 'poppins' }}>
                        1. DEFINITIONS AND INTERPRETATION
                    </span>
                    </strong>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight: "normal",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>
                    1.2 Interpretation
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    In this document, unless the context otherwise requires, the following
                    words and expressions shall bear the meanings ascribed to them below:
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (a)“Applicable Law”means any MVT Law and other statute, law, regulation,
                    ordinance, rule, judgment, order, decree, bye-law, clearance, directive,
                    guideline, policy, requirement, or other governmental restriction or any
                    similar form of, decision of, or determination by, or any interpretation,
                    policy or administration having the force of law of any of the foregoing,
                    by any government authority having jurisdiction over the matter in
                    question, whether in effect as of the date of these Terms and Conditions
                    or thereafter.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (b)Additional Term shall have the meaning ascribed to the term under
                    Clause 4.1 or Clause 4.2 of the Agreement which is dependent on the fact
                    that Vehicle is taken by Me on Self-Drive basis
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (c)Additional Kilometers shall mean no of excess kilometers I can ply the
                    Vehicle during the Hire Period over and above the Permissible Kilometers,
                    which were availed by Me at the inception of Hire and specified in
                    Reservation Details. It is hereby clarified that this definition is not
                    applicable in case where I have opted for unlimited kilometers to ply the
                    Vehicle under Self-Drive
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (d)Booking Confirmation mean the written confirmation of booking of
                    Vehicle on Hire ( on a Self-Drive basis) sent by You to Me on My
                    registered mobile number through SMS or WhatsApp and on email confirming
                    the booking of Vehicle and other detailed terms, post accepting Your offer
                    by Me
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (e)Commencement Date means the date of handing over the Vehicle to Me
                    which is specified either in physical acknowledgement signed by Me, a copy
                    of which is handed over to Me or through Your mobile app by name ‘Rent N
                    Drive” Delivery App’ (Vehicle Delivery App) wherein I have acknowledged
                    digitally
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (f)Coordinates means Your email{" "}
                    </span>
                    <strong>
                    <span
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        id ‘
                    </span>
                    </strong>
                    <strong>
                    <u>
                        <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        www.Rent N Drive.co.in”
                        </span>
                    </u>
                    </strong>
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    &nbsp;{" "}
                    </span>
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    or Help Line Number{" "}
                    </span>
                    <strong>
                    <span
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        +918007784794
                    </span>
                    </strong>
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    {" "}
                    where all communications with regard to Hire of Vehicle or any subsequent
                    event in relation to Hire of Vehicle shall have to be made by Me
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (g)Hire means granting of right to use the Vehicle by You to Me on a
                    rental basis during Hire Period on a Self-Drive basis as specified by Me
                    in the Reservation Details
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (h)Hirer means Myself who has availed Vehicle during Hire Period from You
                    on Hire
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (i)Hire Period shall mean number of days, as mentioned in Reservation
                    Details, for which the Vehicle therein mentioned is provided on a
                    Self-Drive basis to Myself and shall commence from the Commencement Date
                    and end on the last day of Hire Period or Additional Term, as the case may
                    be;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (j)“Hire Charges”means consideration paid and/or payable Me towards the
                    usage of the Vehicle during Hire Period on Self-Drive basis which is more
                    particularly stated in Reservation Details and Booking Confirmation
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (k)“Indemnified Person”gmeans You and Your officers, directors,
                    associates, agents, partners, contractors and all such person who are
                    authorised by You to act and represent on Your behalf
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (l)“MVT Law”means collectively, the Motor Vehicles Act, 1988 and the Rules
                    made thereunder, or any other statutory modification amendment or
                    re-enactment thereof
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (m)Permissible Kilometershall mean any one of the following as selected by
                    Me and specified in Reservation Details:
                    </span>
                </p>
                <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
                    <li
                    style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        (a) Vehicle could be plied by Me in a day and cumulatively in multiple
                        thereof depending on no of days / hours (as the case may be) for which
                        the Vehicle is taken on Self-Drive basis during Hire Period; or
                    </span>
                    </li>
                    <li
                    style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        (b) Vehicle could be plied by Me in a day and cumulatively in multiple
                        thereof depending on no of days / hours (as the case may be) for which
                        the Vehicle is taken on Self-Drive basis during Hire Period; or
                    </span>
                    </li>
                    <li
                    style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        (c) Unlimited kilometers the Vehicle could be plied by Me in a day or
                        during Hire Period, as the case may be
                    </span>
                    </li>
                </ul>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (n)Purpose shallmean providing the Vehicle by You to Me on Hire basis to
                    be plied by Me in terms of Applicable Law and as per the terms of this
                    document during Hire Period, excluding right to sell, transfer, charge,
                    hypothecate or otherwise encumber the Vehicle or alter and/or modify the
                    Vehicle and/or any of its parts;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (o)“Reservation Details” means the details of the Hirer (such as Full
                    Name, Mobile Number and Email ID etc), type of Vehicle sought by the Hirer
                    on Self-Drive or Subscription basis, Hire Period (during which the Vehicle
                    is required by the Me on Self-Drive basis), package type opted which are
                    captured through the Website or Mobile App as referred above
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (p)Vehicle shall mean motor cab as defined under MVT Law which shall be
                    commercially registered and provided by You to Me on a Self-Drive basis
                    under the terms of document out of the pool of vehicles available.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    It is hereby clarified that You shall have right to substitute the
                    Vehicle, during Hire Period, with another Vehicle of same category and age
                    by giving Me prior intimation
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (q)Security Deposit shall mean the interest free refundable paid by Myself
                    to You as a security during the Hirer Period towards Vehicle taken on
                    Self-Drive basis as specified in Reservation Details.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (r)Self-Drive mean providing the Vehicle to Me, for the Purpose, during
                    the Hire Period or Additional Term, as the case may be, and more
                    particularly stated in sub-clause 1.1.(g) above and clause 4 below
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (s)“Schedule of Charges” means various other charges including damage
                    charges, in addition to Hire Charges which becomes due and payable by Me
                    upon occurrence of any of events specified in the said section of Schedule
                    of Charges and displayed on Website at
                    </span>
                    <a href="https://www.rentndrive.co.in" style={{ textDecoration: "none" }}>
                    <span
                        className="Hyperlink"
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        https://www.rentndrive.co.in
                    </span>
                    </a>
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    /
                    </span>
                </p>
                <p
                    style={{
                    marginBottom: "0pt",
                    textAlign: "justify",
                    lineHeight: "normal",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight: "normal",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>
                    1.2 Interpretation
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    Unless the context otherwise requires in the document:
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (a) words importing persons or parties shall include firms and
                    corporations and any organizations having legal capacity;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (b) words importing the singular include the plural and vice versa where
                    the context so requires;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (c) reference to any law shall include such law as from time to time
                    enacted amended, supplemented or re-enacted;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (d) reference to any gender includes a reference to all other genders;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (e) reference to the words “include” or “including” shall be construed
                    without limitation;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (f) reference to the Agreement or any other agreement, deed or other
                    instrument or document shall be construed as a reference to the Agreement
                    or to such agreement, deed or other instrument or document as the same may
                    from time to time be amended, varied supplemented or novated;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    (g) the headings and titles in the Agreement are indicative and shall not
                    be deemed part thereof or be taken into consideration in the
                    interpretation or construction of the Agreement.
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Definitions Unless the context otherwise requires, the following
                    capitalized words as used in these Terms shall have the meaning as
                    respectively assigned to such terms hereunder: “Applicable Laws” shall
                    mean and include, all applicable statutes, enactments, acts of legislature
                    or the Parliament, laws, ordinances, rules, by-laws, regulations,
                    notifications, guidelines, policies, directions, directives and orders of
                    any Governmental Authority (as defined below), tribunal, board, court or a
                    recognised stock exchange of India; “Governmental Authority” means any
                    governmental or statutory authority, government department, agency,
                    commission, board, tribunal, court or other entity, authority or body
                    authorized to exercise legislative, judicial, regulatory or administrative
                    functions of, or pertaining to, a government or any state or other
                    subdivision thereof or any municipality, district or other subdivision
                    thereof having jurisdiction pursuant to Applicable Laws; “User” shall mean
                    any person who has formally enrolled to use Rent N Drive’s vehicles and/or
                    services, pursuant to the completion of usage formalities and acceptance
                    of the terms and conditions as set out herein. “Non-Reserving User” shall
                    have the meaning as specified in; “Reserving User” shall have the meaning
                    as specified in Clause; “Rent N Drive Vehicle” shall mean a vehicle
                    provided by Rent N Drive for rental to its Users on the terms and
                    conditions as set out herein. “Scheme” shall mean the Rent N Drive
                    car-club scheme as floated by Rent N Drive pursuant to which the Rent N
                    Drive Vehicles are provided to Users for self drive purpose. “Terms” shall
                    mean these terms and conditions as may be updated, altered, modified,
                    novated, substituted or replaced from time to time; “Website” shall have
                    the meaning as set out in Recital 1 above.
                    </span>
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>
                    Interpretations In these Terms (including its Recitals, Clauses and
                    Schedules), except where the context requires otherwise, these Terms will
                    be interpreted as follows:
                    </span>
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    words denoting the singular include the plural and vice versa and words
                    denoting a particular gender include all other genders;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    the headings are inserted for convenience only, and shall not affect the
                    construction or interpretation of any provision of these Terms;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    References to Recitals, Clauses, Sub-Clauses thereof and Schedules, unless
                    a contrary intention appears, are to the recitals, clauses, sub-clauses
                    and schedules to these Terms, respectively;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    a reference to consent or approval or similar connotation, unless
                    expressly stated otherwise, shall be in writing, and references to writing
                    include any mode of reproducing words in a legible and non-transitory
                    form;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    a reference to any document (including these Terms) is to that document as
                    amended, consolidated, supplemented, renovated or replaced from time to
                    time;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    a reference to a statute or statutory provision includes, to the extent
                    applicable, at any relevant time:
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    that statute or statutory provision as from time to time consolidated,
                    modified, re-enacted or replaced by any other statute or statutory
                    provision; and
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    any subordinate legislation or regulation / rules made under the relevant
                    statute or statutory provision;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Where a word or phrase is defined, other parts of speech and grammatical
                    forms and the cognate variations of that word or phrase shall have
                    corresponding meanings;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    the rule of construction, if any, that a contract should be interpreted
                    against the party responsible for the drafting and preparation thereof,
                    shall not apply;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    the expressions “hereof”, “herein” and similar expressions shall be
                    construed as references to these Terms as a whole and not limited to the
                    particular section or provision in which the relevant expression appears;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    the words “include” and “including” will be read without limitation;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    references to rupees are lawful currency of India;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    schedules form an integral part these terms shall construed have same
                    force effect as if expressly set out in body terms.
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Upon accepting the terms and conditions as set out here in after, the User
                    agrees and acknowledges that:
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    Rent N Drive has established a website having the registered domain
                    address as www.Rent N Drive.co.in (“Website”) where the terms and
                    conditions of use of vehicles provided by Rent N Drive have been
                    displayed. It is the responsibility of the user to ask representatives of
                    Rent N Drive.co.in and obtain a soft copy of the agreement if they are
                    unable to view or access the terms &amp; conditions as set out in website/
                    Mobile applications.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    The User has read and understood the terms and conditions as set out
                    herein and agrees to abide and be bound by such terms and conditions
                    including those relating to rental of cars, fee schedule and privacy
                    policy and confidentiality terms. Rent N Drive reserves the right to
                    change the terms of this Agreement from time to time with the provision of
                    notice to the User which shall be considered given when these terms and
                    conditions are updated on the Website, IOS / Android apps. The User agrees
                    that the amended terms and conditions of this Agreement shall be effective
                    and binding on the date when such change is posted on the Website.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    The provision of vehicles to the User is subject to the acceptance by the
                    User of all the terms and conditions as set out herein. In the event that
                    the User does not accept any term and/or condition as provided herein or
                    as may be amended, the User will not be authorized to use any vehicles or
                    services provided by Rent N Drive. For the avoidance of doubt it is
                    clarified that use of any vehicles or services provided by Rent N Drive
                    would signify an acceptance by the User of all the terms and conditions
                    including as set out herein including any terms and conditions as may be
                    amended, substituted or novated from time to time.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    Moreover, this agreement doesn’t provide the exclusive right to the User
                    for use of vehicle and in all situations, Rent N Drive shall be authorized
                    to exercise required controls during the rental period.
                    </span>
                    <br />
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p
                    style={{
                    marginBottom: "0pt",
                    textAlign: "justify",
                    lineHeight: "normal",
                    fontSize: "14pt"
                    }}
                >
                    <strong>
                    <span style={{ fontFamily: 'poppins' }}>
                        2. PROVISION OF A VEHICLE ON A HIRE
                    </span>
                    </strong>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.1 Upon receipt of the booking request as specified in Reservation
                    Details from Myself by You through Website or Mobile Application or
                    through Coordinates and upon providing of Booking Confirmation o by You to
                    Me, You have agreed to provide the Vehicle on Hire basis to Me for the
                    Purpose for a day(s)/ for a month(s) mentioned in the Reservation Details
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.2 At the time of handing over of the Vehicle to Me, either I have signed
                    a physical document known as checklist or signed digitally through Vehicle
                    Delivery App acknowledging the receipt of the Vehicle alongwith Vehicle
                    details such as make, model, Registration No of the Vehicle etc. I confirm
                    that the Hire Period shall commence immediately upon handing over the
                    Vehicle to the Me by Your representatives.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.3 I hereby confirm that:
                    </span>
                </p>
                <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
                    <li
                    style={{
                        marginTop: "14pt",
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        2.3.1 I have attained 21 years of age as on Commencement Date;
                    </span>
                    </li>
                    <li
                    style={{
                        marginLeft: "27.6pt",
                        marginBottom: "0pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        2.3.2 I have driving experience of a minimum of 1 year and that I am
                        possessing valid driving license, voter ID/Passport and/or PAN and
                        Aadhaar Card which I have furnished to You.
                    </span>
                    </li>
                    <li
                    style={{
                        marginLeft: "27.6pt",
                        marginBottom: "14pt",
                        textAlign: "justify",
                        lineHeight : "25px",
                        paddingLeft: "8.4pt",
                        fontFamily: "serif",
                        fontSize: "10pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                        2.3.3 I have taken Vehicle on Hire from You at My own risk and I shall
                        be solely liable for any accident, failure or loss or damage of any kind
                        whatsoever that may be caused to or occasioned by Me in connection with
                        or incidental to the provision of the Vehicle on a Hire basis. You shall
                        not be liable for any loss, damage, costs, charges or expenses
                        whatsoever that may be caused to or occasioned by Me or any other person
                        seated in the Vehicle unless specifically mentioned in this document
                    </span>
                    </li>
                </ul>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.4 Upon end of the Hire Period or Additional Term, as the case may be, I
                    shall inspect the Vehicle to check whether I have left any of My luggage
                    and/or items at the time of handing over the Vehicle back to You. At no
                    point in time, You shall be responsible for any luggage and/or items left
                    by Myself and/or the co passengers in the Vehicle
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.5 I hereby undertakes to abide by existing legislation and Applicable
                    Law pertaining to the use of Vehicle including but not limited to
                    situation when a border is crossed. If, due to non-compliance with the
                    foregoing, the authorities temporarily or permanently impound the Vehicle,
                    all the costs, charges and expenses including fines, charges incurred / to
                    be incurred to ensure that the Vehicle is released at the earliest, shall
                    be borne by Myself.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.6 The Vehicle will be filled with fuel upto the liters as displayed in
                    the fuel gauge indicator on the dashboard or instrument panel. I shall
                    verify the fuel gauge indicator at the time of taking possession of
                    Vehicle before Commencement Date. I shall have to return the Vehicle with
                    number of liters of fuel as was available and indicated in fuel gauge
                    indicator at the time of Commencement Date. If the Vehicle is returned by
                    Me with lesser fuel than required to make the number of liters as was
                    available in the fuel gauge indicator at the time of Commencement Date,
                    then, I shall pay to You the cost of the liters of fuel required to fill
                    the fuel tank as required to bring it to the level prevalent at the time
                    of Commencement Date and indicated in fuel gauge indicator. Your decision
                    in this regard shall be final in determining the number of liters required
                    to fill the fuel tank. Besides above, I shall be required to pay charges
                    as specified in Schedule of Charges for refueling to be done by You. You
                    shall deduct the fuel cost along with service charge and other charges
                    from the Security Deposit or I shall undertake to pay the same through
                    weblink provided by You to Me, as the case may be.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.7 I shall take possession of the Vehicle from the pickup point specified
                    in the Reservation Details and hereby consent to adhere to and comply with
                    the instructions as issued by (“Instructions/Guidelines”) from time to
                    time which includes (i) Taking My photograph along with Vehicle (ii)
                    taking photograph of the Vehicle number plate (iii) for payment of
                    interstate charges when the state border is crossed. I hereby explicitly
                    allow Your representative to take picture as required under this section
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.8 I am permitted to drive the Vehicle up to the Permissible Kilometer.
                    Any usage of the Additional Kilometers shall attract the extra kilometer
                    charges as specified in the Booking Confirmation ..
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.9 The Vehicle shall have the luggage carrier provided I have opted the
                    same and specified in Reservation Details. If for any reason the luggage
                    carried is not provided by You to Me, despite opted by Me at the time of
                    booking of the Vehicle, then You shall refund the charges paid by Me
                    towards the luggage carrier
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.10 In case I have opted for trip protection plan (Insurance Cover) as
                    specified in Reservation Details then You shall provide the same. I have
                    been clarified that You have tied up with Reliance General Insurance
                    Company (“Insurer”) to provide Me with an option of Insurance Cover at the
                    My sole discretion. In the event, I have taken Insurance Cover, I
                    understand and acknowledge that You shall not be responsible for any
                    claims raised by Me with the Insurer. Any claims raised under this head
                    shall be solely governed under the terms and conditions of the Reliance
                    General Insurance Company/Insurer. Insurance policy once issued cannot be
                    cancelled and are non-refundable.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.11 I acknowledge that You reserve unilateral right to reject the booking
                    request at any subsequent stage without assigning any reason provided You
                    agree to refund the Security Deposit and advance Subscription Fees withing
                    7 to 10 working days of cancellation by you.
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.12 If third-party lays claim to or detains the Vehicle or otherwise take
                    any action in respect of the Vehicle, I shall take immediate action to
                    ensure that the Vehicle is released from such detention and / or such
                    claim is fully satisfied by Me in that regard. If I lose legal or physical
                    control of the Vehicle, I shall inform You within four hours and if
                    necessary, take appropriate measures to ensure recovering legal and
                    physical control of the Vehicle. You may take any and all actions in the
                    interests of protecting Your rights including taking action in the My
                    name. The costs incurred as a result of any action as stated herein are to
                    be borne by Me and payment for this cost shall be appropriated from the
                    Security Deposit held by You and if the Security Deposit amount fall short
                    of payments due, then the I shall be liable to pay the same upon receipt
                    of notice of demand in that behalf
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.13 I shall be liable to pay charges / penalties specified in the
                    Schedule of Charges if I fail to adhere to terms of Use of Vehicle as
                    specified herein below or any other non-compliances as observed by You
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    2.14 Permissible Kilometers once opted at the time of booking of the
                    Vehicle on Hire basis and specified in Reservation Details and thereafter
                    in Booking Confirmation then the same cannot be altered under any
                    circumstances. If I wish to alter the Permissible Kilometers originally
                    opted then I shall have to cancel the original booking and shall have to
                    cause new booking only
                    </span>
                </p>
                <p
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <h2
                    style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>
                    ROADSIDE ASSISTANCE (RSA) AND BREAK DOWN ASSISTANCE
                    </span>
                </h2>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    In case of any RSA involving the Vehicle or in case of breakdown,
                    accidents, payments relating to RSA, servicing, complaints and escalation
                    matrix in case of complaints, I shall report to given Coordinates only
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    You shall not be liable to make any refund to Me for pending Hire Period
                    if the Vehicle meets with an accident (intentionally or unintentionally).
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <h2
                    style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>
                    TRAFFIC VIOLATIONS
                    </span>
                </h2>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    I shall be responsible for any traffic violations incurred due to
                    inappropriate use of the Vehicle by Me. This includes, but is not limited
                    to, unauthorised parking, excess speeding, jumping red light signal, photo
                    enforcement, and toll violations (“Traffic Violations”). I shall be solely
                    liable for all costs, charges, expenses, penalties and fines imposed due
                    to any Traffic Violations during Hire Period or Additional Term, as the
                    case may be levied by the respective regulatory authority. I shall report
                    such Traffic Violations to Your representative, as soon as possible,
                    within the prescribed timeline for the violation (for example, if the case
                    is being taken to court). If I fail to pay charges as specified in
                    Schedule of Charges and fines, penalties and other charges as levied by
                    regulatory authorities then You will pay those amounts and I shall
                    reimburse the same to You within 7 days from the date of receipt of such
                    information from You or alternatively You may pay the same through weblink
                    provided by You to Me on My mobile number and email id
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    I shall notify You of any Traffic Violation notices found on the Vehicle
                    at the time of Vehicle pick up which is not caused by Me
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <h2
                    style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>ARBITRATION</span>
                </h2>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    If any dispute arises amongst Parties hereto during the subsistence of
                    this document or thereafter, in connection with the validity,
                    interpretation, implementation or alleged breach of any provision of the
                    Agreement or regarding a question, including the questions as to whether
                    the termination of this document has been legitimate, the Parties shall
                    endeavor to settle such dispute amicably.
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    In the case of any dispute or any difference between the Parties arising
                    out of or in relation to this document including dispute or difference as
                    to the validity of this document or interpretation or any of the provision
                    of this document, the same shall be resolved by mutual discussion. If the
                    Parties fail to settle the dispute or difference mutually within 15 days
                    after the dispute shall have arisen then the same shall be referred to
                    Arbitration or the sole arbitrator to be appointed by You and such
                    arbitration shall be governed by the Arbitration and Conciliation Act,
                    1996 or any statutory modification or re-enactment thereof for the time
                    being in force. The provisions of this clause shall survive the
                    termination of this document. Each Party shall bear its own costs; all
                    common costs shall be shared equally by both the Parties. The venue of the
                    arbitration shall be in Pune. The language of arbitration shall be English
                    or Hindi.Subject to Clause above, wherever judicial intervention is
                    possible, the parties herein agree that the Courts at Pune shall have
                    jurisdiction to the exclusion of all other Courts.
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <h2
                    style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    textAlign: "justify",
                    fontSize: "14pt"
                    }}
                >
                    <span style={{ fontFamily: 'poppins' }}>GOVERNING LAW</span>
                </h2>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    This document shall be governed by and construed and interpreted in
                    accordance with the laws of India.
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    If any term or provision of this document should be declared invalid by a
                    court of competent jurisdiction, the remaining terms and provisions of
                    this document shall remain unimpaired and in full force and effect
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <span
                    style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                    }}
                    >
                    &nbsp;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <strong>
                    <u>
                        <span style={{ fontFamily: 'poppins' }}>
                        Enrolment as User
                        </span>
                    </u>
                    </strong>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    By accepting these Terms and Conditions, the User has agreed to enroll as
                    a User under the Scheme to avail the facility for rental of Rent N Drive
                    Vehicles.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>
                    The User further acknowledges and understands that he/she shall not at any
                    time challenge any of the provisions of these Terms or the contents of the
                    Website on the premise that the terms and conditions have not been read by
                    the User or that the User is not aware of the enrolment formalities or
                    that the User has not understood any provision of these Terms or the
                    procedures, formalities, benefits rights and/or obligations as contained
                    on the Website.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>
                    There is no difference between User &amp; Customer. User is a person who
                    registered with Rent N Drive.co.in and customer is a person who hired the
                    vehicle.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <strong>
                    <u>
                        <span style={{ fontFamily: 'poppins' }}>
                        Persons eligible to enroll as Users
                        </span>
                    </u>
                    </strong>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Any person desirous of enrolling as a User with the Rent N Drive will need
                    to satisfy the following eligibility criteria prior to enrollment:
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Such person must be minimum 21 years of age.
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Such person must be an Indian citizen and must possess a valid Light Motor
                    Vehicle (Non Transport) Indian license at least 1 year old.
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Such person must have a good driving record and should not have been found
                    guilty of any offence involving moral turpitude or any offence under Motor
                    Vehicles Act for which imprisonment for more than 6 months have been
                    prescribed;
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Enrollment Formalities
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    For the purpose of enrollment, the User shall be required to provide the
                    necessary particulars, as indicated by Rent N Drive’s representatives.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'poppins' }}>
                    The User shall be required to provide the following documents to Rent N
                    Drive’s representatives:
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    A photo of a valid driving license
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Credit / Debit card details, if this happens to be the preferred mode if
                    payment
                    </span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>
                    Any other documents as may be notified by Rent N Drive from time to time.
                    </span>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <br />
                    <span style={{ fontFamily: 'poppins', fontSize: "14pt" }}>
                    Rent N Drive reserves the right to refuse acceptance of any person as a
                    User without assigning any reason thereto. It is clarified that in the
                    event that any person is rejected as a User, he/ she shall not be
                    permitted to use or rent any Rent N Drive Vehicle.
                    </span>
                    <br />
                    <br />
                    <strong>
                    <span
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        &nbsp;
                    </span>
                    </strong>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <strong>
                    <span
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        &nbsp;
                    </span>
                    </strong>
                </p>
                <p
                    className="NormalWeb"
                    style={{
                    marginTop: "14pt",
                    marginBottom: "14pt",
                    textAlign: "justify",
                    lineHeight : "25px"
                    }}
                >
                    <strong>
                    <span
                        style={{
                        fontFamily: 'poppins',
                        fontSize: "14pt",
                        letterSpacing: "0.3pt"
                        }}
                    >
                        &nbsp;
                    </span>
                    </strong>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>
                <p style={{ textAlign: "justify", lineHeight: "25px", fontSize: "14pt" }}>
                    <span style={{ fontFamily: 'poppins' }}>&nbsp;</span>
                </p>

            </div>


        </div>

    )
}

export default TermsAndConditions;




