import React from 'react'

const LocationSvg = () => {
    return (
         <div>
            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.90522 0.942383C6.76356 0.944673 4.71034 1.71699 3.19596 3.08992C1.68157 4.46286 0.829684 6.32429 0.827157 8.26591C0.824592 9.8526 1.39628 11.3962 2.45452 12.66C2.45452 12.66 2.67483 12.923 2.71082 12.961L8.90522 19.5841L15.1026 12.9576C15.1349 12.9223 15.3559 12.66 15.3559 12.66L15.3567 12.658C16.4144 11.3948 16.9858 9.85188 16.9833 8.26591C16.9808 6.32429 16.1289 4.46286 14.6145 3.08992C13.1001 1.71699 11.0469 0.944673 8.90522 0.942383ZM8.90522 10.929C8.32424 10.929 7.75631 10.7728 7.27325 10.4802C6.79018 10.1876 6.41368 9.77166 6.19135 9.28504C5.96902 8.79842 5.91084 8.26296 6.02419 7.74637C6.13753 7.22978 6.4173 6.75526 6.82811 6.38281C7.23893 6.01037 7.76233 5.75674 8.33215 5.65398C8.90196 5.55122 9.49259 5.60396 10.0293 5.80553C10.5661 6.00709 11.0249 6.34843 11.3476 6.78637C11.6704 7.22432 11.8427 7.7392 11.8427 8.26591C11.8417 8.97194 11.5319 9.6488 10.9813 10.148C10.4306 10.6473 9.68399 10.9281 8.90522 10.929Z" fill="url(#paint0_linear_34_427)"/>
<defs>
<linearGradient id="paint0_linear_34_427" x1="-2.01188" y1="5.97074" x2="17.0354" y2="6.21588" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
</defs>
</svg>

         </div>
    )
}

export default LocationSvg