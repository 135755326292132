import CarImg from 'assets/images/car2.jpg'
import HeroImg from 'assets/images/hero.jpg'
import rectImg from 'assets/images/rect.png'
import { Link } from 'react-router-dom';
import './styles.css';
import { useSelector } from 'react-redux';


const Hero = () => {
    const { user } = useSelector(state => state.auth);

    return (
        <> 
            <div className='hero relative' style={{
                
                backgroundImage : `url(${user ? HeroImg : CarImg})` ,
                backgroundSize : 'cover' ,
                backgroundPosition : 'center'
            }}>
                <div className="overlay absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full"></div>
                <div className='relative flex justify-center z-10 2xl:pt-40 md:pt-20 sm:pt-28 pt-40'>
                    <h1 className='flex flex-col  text-center text-white text-opacity-90'>
                        <span className='lg:text-[100px] md:text-[80px] sm:text-[60px] text-[40px] font-bold tracking-widest'>A Better Way</span>
                        <span className='lg:text-[40px] md:text-[35px] sm:text-[25px] text-[20px] font-semibold'>To Book Your Car</span>    
                        <div className='flex items-center sm:gap-12 gap-3 mt-8 justify-center'>
                            <Link to='/vehicles' className="btn-primary py-3 sm:px-12 px-4 sm:text-base text-sm">
                                Book Your Car
                            </Link>
                            <Link to='/chopper' className="btn-secondary py-2.5 sm:px-12 px-4 sm:text-base text-sm">
                                Contact Us
                            </Link>
                        </div>
                    </h1>    
                </div>  
                        
            </div>
            <div
                className='flex items-center justify-center'
                style={{
                    minHeight : '200px' ,
                    height : '100%' ,
                    backgroundImage : `url(${rectImg})` ,
                    backgroundSize : 'cover' ,
                    backgroundPosition : 'center'
                }}
            >
                <div className='w-full h-full flex flex-col gap-6 items-center justify-center px-4 py-10'>
                    <p className='text-white font-medium text-center sm:text-base text-sm '>Get an offer in minutes. Book your car when you're ready. Get a ride to anywhere locally, on us!</p>
                    
                </div>
            </div>
        </>
    )
}

export default Hero;