import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Input (props) {
    const { label, name , formik , ...rest } = props
    return (
        <div className='form-row'>
            {
                label && <label htmlFor={name}>{label}</label>
            }
            <Field 
            id={name} 
            name={name} 
            className={`${formik.touched[name] && formik.errors[name] ? 'border-red' : null }`}
            {...rest} 
            />
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default Input;
