import AllowPermissionImg from 'assets/svgs/permission.svg';



const HowToAllowPerPopup = ({ setShow }) => {

    // console.log({ settings })
    return (
        <div className='fixed top-0 left-0 w-full min-h-screen py-10 px-4 flex items-center justify-center bg-black bg-opacity-50 z-[9999]'>
            <div
            className='popup bg-white sm:w-[600px] w-full p-4 pb-8 rounded-lg relative'>
                <div 
                className='absolute top-1 right-3 text-2xl cursor-pointer'
                onClick={() => setShow(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                <div className='flex items-center justify-between sm:gap-8 gap-2 md:flex-row flex-col'>
                    <center>
                        <img src={AllowPermissionImg} alt="" className='sm:w-[300px] w-auto' />
                    </center>
                    <div className='mt-4'>
                        <h3 className='font-medium'>RentnDrive is blocked from using your location</h3>
                        <ul className='mt-4 text-sm flex flex-col gap-2'>
                            <li>
                                1. Click the <i className="uil uil-lock"></i> lock icon in your browser's address bar
                            </li>
                            <li>
                                2. Turn on location
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default HowToAllowPerPopup