import AllowIndicatorImg from 'assets/svgs/allow-indicator.svg';



const ClickToAllowPopup = ({ setShowClickToAllowPopup }) => {

    // console.log({ settings })
    return (
        <div className='fixed top-0 left-0 w-full min-h-screen py-10 px-4 flex items-center justify-center bg-black bg-opacity-50 z-[9999]'>
            <div
            className='popup bg-white sm:w-[600px] w-full p-4 pb-8 rounded-lg relative'>
                <div 
                className='absolute top-1 right-3 text-2xl cursor-pointer'
                onClick={() => setShowClickToAllowPopup(false)}
                >
                    <i className="uil uil-times"></i>
                </div>
                <center>
                    <img src={AllowIndicatorImg} alt="" className='sm:w-[300px] w-auto' />
                </center>
                <div className='mt-4 text-center'>
                    <h3 className='sm:text-2xl text-xl font-medium mt-1'>Click <b>Allow</b></h3>
                    <p className='text-sm text-center font-medium text-dark mt-1'>
                        You can still turn off your location anytime.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ClickToAllowPopup