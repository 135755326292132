import CircleSvg from "assets/svgs/CircleSvg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';
import { reviewsData } from "data/Reviews";
import LazyLoad from "react-lazyload";

const avatar = 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&w=1000&q=80';

const Testimonials = () => {
    return (
        <div>
            <div className='text-center'>
                <h2 className="section-heading">
                    What Our Clients Say
                </h2>
            </div>
            <div className='relative pt-20'>
                <div className="absolute left-2/3 -top-12 -translate-x-2/3">
                    <CircleSvg />
                </div>
                <div>
                <Swiper
                    effect={'cards'}
                    navigation={true}
                    grabCursor={true}
                    modules={[EffectCards , Navigation]}
                    className="testimonials-swiper"
                >
                    {
                        reviewsData?.map((item , i) => (
                            <SwiperSlide 
                            key={item?.id}
                            className="p-6"
                            >
                                <div className="flex items-center flex-col gap-2">
                                    <LazyLoad offset={100}>
                                        <img 
                                        src={item?.image} 
                                        alt={item?.username}
                                        className="w-[100px] h-[100px] rounded-full object-cover" 
                                        />
                                    </LazyLoad>
                                    <h4 className="heading-sm">
                                        {item?.username}
                                    </h4>
                                    {/* <small className="text-grayText">Lead designer</small> */}
                                </div>
                                <div className="mt-8 text-dark sm:text-base text-sm text-center md:w-[70%] md:mx-auto w-full">
                                    {item?.review}
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Testimonials