import { ClipLoader } from 'react-spinners';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import contactValidations from 'validations/contactValidations';
import FormControl from 'components/form/FormControl';

const initState = {
    username : '' , 
    email : '' , 
    phone : '' , 
    message : '' , 
};

const ContactForm = () => {
    const [loading , setLoading] = useState(false);

    const submitHandler = async (formData , submitProps) => {
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post('/home/support-email' , formData );
            toast.success(message , {
                autoClose : 4000
            });
            submitProps.resetForm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error)
        }
    }

    return (
        <div>
            <div className='mt-8 w-full'>
                <Formik
                initialValues={initState}
                validationSchema={contactValidations}
                onSubmit={submitHandler}
                >
                    {
                        (formik) => {
                            return (
                                <Form
                                className='flex flex-col gap-2 w-full'
                                >
                                    <FormControl
                                    control='input'
                                    placeholder='Enter your name'
                                    name='username'
                                    formik={formik}
                                    /> 
                                    <FormControl
                                    control='input'
                                    placeholder='Enter your email'
                                    name='email'
                                    formik={formik}
                                    /> 
                                    <FormControl
                                    control='input' 
                                    placeholder='Enter your phone no'
                                    name='phone'
                                    formik={formik}
                                    />
                                    <div>
                                        <FormControl
                                        control='textarea'  
                                        placeholder='Go ahead, We are listening...'
                                        name='message'
                                        formik={formik}
                                        />
                                        <div className='text-right'>
                                            {formik?.values?.message?.length}/500
                                        </div>
                                    </div> 
                                    <div>
                                        <button 
                                        className="btn-primary py-3 px-12 text-center"
                                        type='submit'
                                        disabled={loading || !formik.isValid}
                                        >
                                            {
                                                loading 
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Click Here To Submit'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
                {/* <form 
                className='flex flex-col gap-8 w-full'
                onSubmit={submitHandler}
                >
                    <NameInput 
                    data={mailData}
                    setData={setMailData}
                    placeholder='Enter your name'
                    name='username'
                    minLength={3}
                    maxLength={50}
                    required
                    /> 
                    <MultiTypeInput 
                    type='email' 
                    placeholder='Enter your email'
                    name='email'
                    data={mailData}
                    setData={setMailData}
                    setIsValid={setIsValid}
                    required
                    errorMsg='Please enter valid email.'
                    /> 
                    <MultiTypeInput 
                    type='number' 
                    placeholder='Enter your phone no'
                    name='phone'
                    data={mailData}
                    setData={setMailData}
                    setIsValid={setIsValid}
                    required
                    errorMsg='Please enter valid phone no.'
                    />
                    <div>
                        <textarea 
                        className='input w-full h-[100px] resize-none' 
                        placeholder='Go ahead, We are listening...'
                        value={mailData?.message}
                        onChange={e => setMailData(prev => ({...prev , message : e.target.value }))}
                        maxLength={500}
                        required
                        />
                        <div className='text-right'>
                            {mailData?.message?.length}/500
                        </div>
                    </div> 
                    <div>
                        <button 
                        className="btn-primary py-3 px-12 text-center"
                        type='submit'
                        disabled={loading || !isValid}
                        >
                            {
                                loading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Click Here To Submit'
                            }
                        </button>
                    </div>
                </form> */}
            </div>
        </div>
    )
}

export default ContactForm