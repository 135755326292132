import CalendarSvg from 'assets/svgs/CalendarSvg'
import CarSvg from 'assets/svgs/CarSvg'
import FlySvg from 'assets/svgs/FlySvg'
import React from 'react'

const HowItWorks = () => {
    return (
        <div className='container mx-auto sm:px-12 px-3 py-12 pb-20'>
            <div className='text-center'>
                <h2 className='text-2xl font-semibold'>How it Works?</h2>
                <p className='text-grayText text-sm mt-1'>Rent with Following 3 working steps</p>
            </div>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-12 gap-8'>
                <div className='flex flex-col items-center gap-4'>
                    <div className='circle-icon'>
                        <FlySvg />
                    </div>
                    <h4 className='heading-sm'>Choose Location</h4>
                    <p className='text-sm text-grayText text-center'>Choose your location and find your best car</p>
                </div>
                <div className='flex flex-col items-center gap-4'>
                    <div className='circle-icon'>
                        <CalendarSvg />
                    </div>
                    <h4 className='heading-sm'>Pickup Date</h4>
                    <p className='text-sm text-grayText text-center'>Select your pick and time to book your car</p>
                </div>
                <div className='flex flex-col items-center gap-4'>
                    <div className='circle-icon'>
                        <CarSvg />
                    </div>
                    <h4 className='heading-sm'>Book your car</h4>
                    <p className='text-sm text-grayText text-center'>Book your car and we wil directly to you!</p>
                </div>
               
            </div>
        </div>
    )
}

export default HowItWorks