import * as Yup from 'yup';

const profileValidations = Yup.object({
    fullName: Yup.string()
        .min(3, 'Full name should have at least 3 characters')
        .max(30, 'Full name should not exceed 50 characters')
        .required('Full name is required'),
    address: Yup.string()
        .min(3, 'Address should have at least 10 characters')
        .max(30, 'Address should not exceed 100 characters') ,
    city: Yup.string()
        .min(3, 'City name should have at least 3 characters')
        .max(30, 'City name should not exceed 40 characters')
});

export default profileValidations;