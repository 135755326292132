import React, { memo, useState } from 'react'
import vehicleTypes from 'data/vehicleTypes'

const Filters = ({ setFilter , filter , setLocation , setDistance }) => {
    const [checkedFilters, setCheckedFilters] = useState([]);

    const handleClearFilters = () => {
        setFilter({ vehicleType: '', sort: '' , hasDriver : false });
        const vehicleTypeRadios = document.getElementsByName('vehicleType');
        vehicleTypeRadios.forEach((radio) => {
            radio.checked = false;
        });
        const sortRadios = document.getElementsByName('sort');
        sortRadios.forEach((radio) => {
            radio.checked = false;
        });

    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter(prev => ({
            ...prev,
            [name]: value,
        }));

        if (!checkedFilters.includes(value)) {
            setCheckedFilters((prev) => [...prev, value]);
        } else {
            setCheckedFilters((prev) => prev.filter((filter) => filter !== value));
        }
    };


    return (
        <>
            <div className='p-4 border-b flex items-center justify-between'>
                <h3 className='heading-sm'>Filter</h3>
                <button
                    className='text-gradient text-sm cursor-pointer'
                    onClick={handleClearFilters}
                >
                    Clear All Filters
                </button>
            </div>
            <div className='p-4 border-b'>
                <div className='heading-sm'>Car Category</div>
                <ul className='mt-3 flex flex-col gap-2'>
                    {
                        vehicleTypes.map(item => (
                            <li 
                            key={item}
                            className='flex items-center gap-2.5 text-sm'
                            >
                                <input 
                                type="radio" 
                                id={item} 
                                value={item}
                                onChange={handleFilterChange}
                                name='vehicleType'
                                />
                                <label 
                                htmlFor={item}
                                className='capitalize'
                                >
                                    {item}
                                </label>
                            </li>
                        ))
                    }
                </ul>

            </div>
            <div className='p-4 border-b'>
                <div className='heading-sm'>Price Range</div>
                <div className='mt-3 flex flex-col gap-2'>
                    <div className='flex items-center gap-2.5 text-sm'>
                        <input
                            type='radio'
                            name='sort'
                            value='highToLow'
                            id='highToLow'
                            onChange={handleFilterChange}
                            // checked={checkedFilters.includes('highToLow')}
                        />
                        <label htmlFor='highToLow'>High To Low</label>
                    </div>
                    <div className='flex items-center gap-2.5 text-sm'>
                        <input
                            type='radio'
                            name='sort'
                            value={'lowToHigh'}
                            id='lowToHigh'
                            onChange={handleFilterChange}
                            // checked={checkedFilters.includes('lowToHigh')}
                        />
                        <label htmlFor='lowToHigh'>Low To High</label>
                    </div>
                </div>
            </div>
            <div className='px-4 pb-4 '>
                <div className='mt-3 flex flex-col gap-2'>
                    <div className='flex items-center gap-2.5 text-sm'>
                        <input
                            type='radio'
                            name='hasDriver'
                            value={filter?.hasDriver}
                            id='hasDriver'
                            onChange={e => {
                                if(e.target.checked) {
                                    setFilter(prev => ({...prev , hasDriver : true }))
                                }else {
                                    setFilter(prev => ({...prev , hasDriver : false }))
                                }
                            }}
                            checked={filter?.hasDriver}
                        />
                        <label htmlFor='hasDriver'>With Driver</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(Filters)