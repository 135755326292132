import BackBtn from 'components/global/BackBtn';
import Breadcrumbs from 'components/global/Breadcrumbs';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/notificationReducer';
import fetcher from 'utils/fetcher';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Notifications",  }
]

const Notifications = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , pages } = useSelector(state => state.notification);
    
    const queryKey = ['fetch-notifications' , currentPage]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/notification/customer?page=${currentPage}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            dispatch(setDocs(docs));
            dispatch(setDocsCount(docsCount));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
        }
    }, [data]);

    return (
        <div className='my-4 px-3'>      
            <div className="container mx-auto flex items-center justify-center">
                <div className=' md:w-[70%] sm:w-[80%] w-full'>
                    <Breadcrumbs crumbs={crumbs} />
                    <div className='flex items-center justify-between gap-4 mt-6'>
                        <Heading title='Notifications' showIcon={false} />
                        <BackBtn />
                    </div>
                    {
                        isLoading
                        ?
                            <Loader />
                        : 
                        docs?.length > 0
                        ?
                            <>
                                <div className='mt-6 flex flex-col gap-4'>
                                {
                                    docs?.map((item) => (
                                        <div 
                                        key={item?._id} 
                                        className='shadow-bg bg-pure p-3 rounded-md'
                                        >
                                            <div className='flex sm:items-center justify-between sm:flex-row flex-col'>
                                                <h3 className='text-xl text-primary font-semibold'>
                                                    {item?.title}
                                                </h3>
                                                <p className='sm:text-sm text-xs text-gray-400'>
                                                    {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                                </p>
                                            </div>
                                            <p className='text-gray-500 text-sm mt-4'>
                                                {item?.description}
                                            </p>
                                        </div>
                                    ))
                                }
                                </div>
                                <div className='mt-4 shadow-bg bg-white'>
                                    <Pagination
                                    pageCount={pages}
                                    setPage={setCurrentPage}
                                    currentPage={currentPage}
                                    />
                                </div>
                            </>
                        :
                            <ItemNotFound message='No notification found.' />
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifications