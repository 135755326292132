import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
    name : 'blog' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 , 
        keyword : '' ,
        status : '' ,
        category : '' ,
        sort : 'latest'
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } , 
        setKeyword (state , action) {
            state.keyword = action.payload;
        } , 
        setCategory (state , action) {
            state.category = action.payload;
        } ,
        setSort (state , action) {
            state.sort = action.payload;
        } ,
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCurrentPage , setPages , setDocsCount  , setKeyword , setCategory , setSort
} = blogSlice.actions;

export default blogSlice.reducer;