import { useState } from 'react';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';

const ExtendDatePicker = ({ booking , extendBookingData , setExtendBookingData , futureBookings , settings }) => {
    const [tooltipDate, setTooltipDate] = useState(null);

    const handleDateChange = (date) => {
        const selectedDate = new Date(date);
        const returnDate = new Date(booking?.returnDate)
        if( 
            selectedDate.getDate() === returnDate.getDate() &&
            selectedDate.getMonth() === returnDate.getMonth() &&
            selectedDate.getFullYear() === returnDate.getFullYear() &&
            selectedDate.getHours() === 0 &&
            selectedDate.getMinutes() === 0
        ) {
            selectedDate.setHours(returnDate.getHours() + settings?.minRentalDuration);
            selectedDate.setMinutes(returnDate.getMinutes());
            selectedDate.setSeconds(returnDate.getSeconds());
        }
        setExtendBookingData((prev) => ({ ...prev, extendDate : selectedDate }));
    };


    const renderDayContents = (day, date) => {
        const isExcluded = futureBookings?.some(
            ({ pickupDate, returnDate }) => {
                let start = new Date(pickupDate);
                let end = new Date(returnDate);
                start.setHours(0, 0, 0, 0);
                end.setHours(0, 0, 0, 0);
                return date >= start && date <= end;
            }
        );

        const handleMouseEnter = () => {
            setTooltipDate(date);
        };

        const handleMouseLeave = () => {
            setTooltipDate(null);
        };

        if (isExcluded) {
            return (
                <div className="text-red-500" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <span className="block">{day}</span>
                    {tooltipDate && tooltipDate.getTime() === date.getTime() && (
                        <div className="absolute text-sm bg-red-500 text-white px-2 py-1 rounded-md z-10">
                            Not Available
                        </div>
                    )}
                </div>
            );
        }

        return day;
    };



    return (
        <div className='w-full flex flex-col gap-1.5'>
            <label className='text-primary font-semibold'>
                Select Extend Date
            </label>
            <DatePicker
            selected={extendBookingData?.extendDate}
            minDate={new Date(booking?.returnDate)}
            placeholderText='select extend date & time'
            onChange={handleDateChange}
            showTimeSelect
            name='returnDate'
            value={extendBookingData?.extendDate}                            
            dateFormat="MMMM d, yyyy h:mm aa"
            excludeDateIntervals={futureBookings?.map(({ pickupDate, returnDate }) => {
                let start = new Date(pickupDate);
                let end = new Date(returnDate);
                start.setHours(0, 0, 0, 0);
                end.setHours(0, 0, 0, 0);
                return { start , end };
            })}
            filterTime={(time) => {
                const pickupDate = new Date(booking?.returnDate);
                const selectedDate = new Date(time);
                selectedDate.setHours(selectedDate.getHours() - 12);
                return selectedDate.getTime() > pickupDate.getTime() ;
            }}
            renderDayContents={renderDayContents}
            />
        </div>
    )
}

export default ExtendDatePicker