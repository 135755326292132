import React, { useEffect, useRef, useState } from 'react';
import Webcam from "react-webcam";

function Cam({ setImage  , setShowCam  }) {
    const webcamRef = React.useRef(null);
    const [imageData, setImageData] = useState(null);

    const captureImage = React.useCallback(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          setImageData(imageSrc)
        },
        [webcamRef]
    );

    const doneHandler = () => {
        setImage(imageData);
        setShowCam(false);
        setImageData(null)
    }

    const cancelHandler = () => {
        setImageData('');
    }

    return (
        <div className='bg-slate-700 fixed z-50 top-0 left-0 w-full h-screen'>
            <div className='flex items-center justify-center w-full h-full flex-col gap-4 relative'>
                {
                    imageData 
                    ? 
                        <img 
                        src={imageData}  
                        className='w-full h-full object-contain'
                        />
                    :
                        <div className='w-full h-full overflow-hidden flex items-center justify-center'>
                            <Webcam
                                audio={false}
                                height={'100%'}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={'100%'}
                                videoConstraints={{
                                    width: '100%' ,
                                    height : '100%' ,
                                    facingMode : 'user'
                                }}
                            />
                        </div>
                }

                <div className='absolute left-1/2 bottom-4 -translate-x-1/2'>
                    {
                        imageData 
                        ?
                            <div className='flex items-center gap-4'>
                                <button 
                                className="bg-green-500 hover:bg-green-600 rounded-full w-[50px] h-[50px] text-white flex items-center justify-center py-2 px-4 text-2xl" 
                                onClick={doneHandler}
                                type='button'
                                >
                                    <i className="uil uil-check"></i>
                                </button>
                                <button 
                                className='bg-red-500 hover:bg-red-600 rounded-full w-[50px] h-[50px] text-white flex items-center justify-center py-2 px-4 text-xl'
                                onClick={cancelHandler}
                                type='button'
                                >
                                    <i className="uil uil-trash"></i>
                                </button>
                            </div>
                        : 
                            <div className='flex items-center gap-4'>
                                <button 
                                className='bg-primary rounded-full w-[50px] h-[50px] text-white flex items-center justify-center py-2 px-4 text-xl' 
                                onClick={captureImage}
                                type='button'
                                >
                                    <i className="uil uil-camera"></i>
                                </button>
                                <button 
                                className='bg-red-500 hover:bg-red-600 rounded-full w-[50px] h-[50px] text-white flex items-center justify-center py-2 px-4 text-xl'
                                onClick={() => setShowCam(false)}
                                type='button'
                                >
                                    <i className="uil uil-times"></i>
                                </button>
                            </div>
                    }
                </div>

            </div>
        </div>
    );
}

export default Cam;
