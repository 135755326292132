import React from 'react'

const CalendarSvg = () => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_34_248)">
<path d="M37.5 3.74561L27.4912 3.74564V1.25439C27.4912 0.56377 26.9319 0.00439453 26.2412 0.00439453C25.5506 0.00439453 24.9912 0.56377 24.9912 1.25439V3.74502H14.9913V1.25439C14.9913 0.56377 14.4319 0.00439453 13.7413 0.00439453C13.0506 0.00439453 12.4913 0.56377 12.4913 1.25439V3.74502H2.5C1.11937 3.74502 0 4.86439 0 6.24502V37.495C0 38.8756 1.11937 39.995 2.5 39.995H37.5C38.8806 39.995 40 38.8756 40 37.495V6.24502C40 4.86498 38.8806 3.74561 37.5 3.74561ZM37.5 37.495H2.5V6.24502H12.4913V7.5044C12.4913 8.19498 13.0506 8.7544 13.7413 8.7544C14.4319 8.7544 14.9913 8.19498 14.9913 7.5044V6.24565H24.9912V7.50502C24.9912 8.19565 25.5506 8.75502 26.2412 8.75502C26.9319 8.75502 27.4912 8.19565 27.4912 7.50502V6.24565H37.5V37.495ZM28.75 19.9956H31.25C31.94 19.9956 32.5 19.4356 32.5 18.7456V16.2456C32.5 15.5556 31.94 14.9956 31.25 14.9956H28.75C28.06 14.9956 27.5 15.5556 27.5 16.2456V18.7456C27.5 19.4356 28.06 19.9956 28.75 19.9956ZM28.75 29.995H31.25C31.94 29.995 32.5 29.4356 32.5 28.745V26.245C32.5 25.555 31.94 24.995 31.25 24.995H28.75C28.06 24.995 27.5 25.555 27.5 26.245V28.745C27.5 29.4362 28.06 29.995 28.75 29.995ZM21.25 24.995H18.75C18.06 24.995 17.5 25.555 17.5 26.245V28.745C17.5 29.4356 18.06 29.995 18.75 29.995H21.25C21.94 29.995 22.5 29.4356 22.5 28.745V26.245C22.5 25.5556 21.94 24.995 21.25 24.995ZM21.25 14.9956H18.75C18.06 14.9956 17.5 15.5556 17.5 16.2456V18.7456C17.5 19.4356 18.06 19.9956 18.75 19.9956H21.25C21.94 19.9956 22.5 19.4356 22.5 18.7456V16.2456C22.5 15.555 21.94 14.9956 21.25 14.9956ZM11.25 14.9956H8.75C8.06 14.9956 7.5 15.5556 7.5 16.2456V18.7456C7.5 19.4356 8.06 19.9956 8.75 19.9956H11.25C11.94 19.9956 12.5 19.4356 12.5 18.7456V16.2456C12.5 15.555 11.94 14.9956 11.25 14.9956ZM11.25 24.995H8.75C8.06 24.995 7.5 25.555 7.5 26.245V28.745C7.5 29.4356 8.06 29.995 8.75 29.995H11.25C11.94 29.995 12.5 29.4356 12.5 28.745V26.245C12.5 25.5556 11.94 24.995 11.25 24.995Z" fill="url(#paint0_linear_34_248)"/>
</g>
<defs>
<linearGradient id="paint0_linear_34_248" x1="-7.02899" y1="10.7913" x2="40.1264" y2="11.4918" gradientUnits="userSpaceOnUse">
<stop stopColor="#1E88E5"/>
<stop offset="1" stopColor="#C9E51E"/>
</linearGradient>
<clipPath id="clip0_34_248">
<rect width="40" height="40" rx="5" fill="white"/>
</clipPath>
</defs>
</svg>

        </div>
    )
}

export default CalendarSvg