import * as Yup from 'yup';

const loginValidations = Yup.object({
    identifier: Yup.string()
    .matches(
        /^(?:[6-9]\d{9}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        'Please enter a valid email or phone number.'
    )
    .required('Email or phone is required.'),
    
    password: Yup.string()
        .min(8, 'Password should have at least 8 characters')
        .required('Password is required'),
});

export default loginValidations;