import Breadcrumbs from 'components/global/Breadcrumbs';
import Input from 'components/global/Input';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { setUser } from 'redux/reducers/authReducer';
import fetcher from 'utils/fetcher';


const KycDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [kycData , setKycData] = useState({});

    const { isLoading , data } = useQuery('fetch-my-kyc' , () => {
        return fetcher(`/kyc/user/${user?._id}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setUser(({...user , kyc : doc })))
            setKycData({...doc});
            if(!doc) {
                navigate('/kyc/step1')
            }
        }
    } , [data])

    return (
        <div className='my-4 px-3'>
            <div className="container mx-auto flex items-center justify-center my-6">
                <div className='shadow-bg  md:w-[70%] sm:w-[80%] w-full p-6'>
                    <div className='flex items-center justify-between'>
                        <h1 className='section-heading text-gradient'>
                            Kyc Details
                        </h1>
                        <RequestStatus status={kycData?.status} />
                    </div>
                    {
                        isLoading
                        ? 
                            <Loader />
                        : 
                        kycData?.isVerified 
                        ?
                            <div>
                                <div 
                                className='flex flex-col gap-4 mt-6'
                                >
                                    <Input 
                                    type='number'
                                    label='Aadhaar Card Number'
                                    value={kycData?.aadhaarCardNumber}
                                    readOnly
                                    disabled
                                    />
                                    <Input 
                                    label='Driving License '
                                    value={kycData?.licenseCardNumber}
                                    readOnly
                                    disabled
                                    />
                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <div className="input-group">
                                            <label>Aadhaar Card Front Image</label>
                                            <img 
                                            src={baseURL + '/aadhaarCards/' + kycData.aadhaarCardFrontImage} 
                                            alt="aadhaar card" 
                                            className='rounded-md border'
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>Aadhaar Card Back Image</label>
                                            <img 
                                            src={baseURL + '/aadhaarCards/' + kycData.aadhaarCardBackImage} 
                                            alt="aadhaar card" 
                                            className='rounded-md border'
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <div className="input-group">
                                            <label>License Card Front Image</label>
                                            <img 
                                            src={baseURL + '/licenseCards/' + kycData.licenseCardFrontImage} 
                                            alt="License card" 
                                            className='rounded-md border'
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>License Card Front Image</label>
                                            <img 
                                            src={baseURL + '/licenseCards/' + kycData.licenseCardBackImage} 
                                            alt="License card" 
                                            className='rounded-md border'
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-6 md:flex-row flex-col mt-4'>
                                        <div className="input-group">
                                            <label>
                                                {kycData?.additionalDocumentType}
                                            </label>
                                            <img 
                                            src={baseURL + '/additionalDocuments/' + kycData?.additionalDocumentImage} 
                                            alt="License card" 
                                            className='rounded-md border'
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>
                                                Selfie
                                            </label>
                                            <img 
                                            src={baseURL + '/selfies/' + kycData?.selfie} 
                                            alt="License card" 
                                            className='rounded-md border'
                                            />
                                        </div>
    
                                    </div>
                                
                                </div>
                            </div>
                        : 
                            <div className='p-4 h-[200px] flex flex-col items-center justify-center gap-4'>
                                <p className='text-red-500 text-lg font-medium'>Kyc Details Not Found.</p>
                                <Link to='/kyc/step1' className='btn-primary py-2 px-6'>
                                    Add Kyc
                                </Link>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default KycDetails