import BookingItem from 'components/bookings/BookingItem'
import Breadcrumbs from 'components/global/Breadcrumbs';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import fetcher from 'utils/fetcher';

const statuses = ['pending' , 'confirmed' , 'completed' , 'cancelled'];

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Bookings", path: "/bookings" },
]

const Bookings = () => {
    const [bookings , setBookings] = useState([]);
    const [currentPage , setCurrentPage] = useState(1);
    const [pages , setPages] = useState(1);
    const [docsCount , setDocsCount] = useState(1);
    const [status , setStatus] = useState('');

    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-bookings' , status , currentPage ]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/booking/customer-bookings/${user?._id}?page=${currentPage}&status=${status}` , user)
    });


    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , pages , page }}} = data;
            setBookings(docs);
            setPages(pages);
            setCurrentPage(page)
            setDocsCount(docsCount)
        }
    }, [data]);

    return (
        <div>
            <div className='container mx-auto sm:px-0 px-4 py-4'>
                <div className=' lg:w-[70%] md:w-[80%] sm:w-[90%] w-full mx-auto'>
                    <Breadcrumbs crumbs={crumbs} />
                    <div className="flex items-center justify-between py-4">
                        <h1 className="text-center section-heading text-gradient">
                            Booking History
                        </h1>
                        <select
                        className='select-box'
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                        >
                            <option value=''>All</option>
                            {
                                statuses.map(item => (
                                    <option key={item} value={item}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        bookings?.length > 0 
                        ? 
                            <div className='flex flex-col gap-6 mt-8'>
                                {
                                    bookings?.map((booking ,i) => (
                                        <BookingItem 
                                        key={booking?._id} 
                                        booking={booking}
                                        />
                                    ))
                                }
                                <Pagination
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                pageCount={pages}
                                redux={false}
                                />
                            </div>
                        : 
                            <div className='shadow-bg p-4 h-[200px] flex flex-col items-center justify-center gap-4 mt-8'>
                                <p className='text-lg font-semibold'>
                                    No Booking Found 😣
                                </p>
                                <Link to='/vehicles' >
                                    <button className="btn-primary py-2 px-8">
                                        Book Car
                                    </button>
                                </Link>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Bookings