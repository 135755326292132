import CarInfo from 'components/bookings/CarInfo';
import ExtendDatePicker from 'components/bookings/ExtendDatePicker';
import DatePicker from 'react-datepicker';
import FormControl from 'components/form/FormControl';
import BackBtn from 'components/global/BackBtn'
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetcher from 'utils/fetcher';
import moment from 'moment';
import calculateDuration from 'utils/calculateDuration';
import formatAmount from 'utils/formatAmount';
import { ClipLoader } from 'react-spinners';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import confirmBox from 'utils/confirmBox';
import useRazorpay from "react-razorpay";

const initState = {
    extendDate : '', 
    duration : {
        days : 0,
        hours : 0,
        minutes : 0 ,
        totalHours : 0
    } , 
    totalPrice : 0,
    GST : 0 ,
    driverRent : 0 ,
    vehicleRent : 0 ,
};

const ExtendBooking = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const [Razorpay] = useRazorpay();

    const [booking , setBooking] = useState('');
    const [bookingLoading , setBookingLoading] = useState(false);
    const [futureBookings , setFutureBookings] = useState('');
    const [extendBookingData , setExtendBookingData] = useState(initState);
    const [isTermsAccepted , setIsTermsAccepted] = useState(false);
    const [settings , setSettings] = useState('');
    const [rent , setRent] = useState(0);

    const queryKey = ['booking-details-with-future-bookings' , id]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/booking/details-with-future-bookings/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { booking , settings , futureBookings } } } = data;
            setBooking(booking);
            setFutureBookings(futureBookings);
            setSettings(settings);
        }
    } , [data]);

    useEffect(() => {
        if(extendBookingData?.extendDate) {
            const duration = calculateDuration(moment(booking?.returnDate), moment(extendBookingData?.extendDate));
            const totalPrice =  Number(booking?.car?.rentPerHour) * Number(duration?.totalHours);
            let vehicleRent = totalPrice;
            const GST = (totalPrice/100) * settings?.GST;
            let driverRent = 0;
            if(booking?.withDriver) {
                driverRent = Math.ceil((duration.totalHours / 24) * booking?.car?.driverRentPerDay)
            }
            setExtendBookingData(prev => ({
                ...prev, 
                duration ,
                totalPrice : totalPrice + GST + driverRent,
                GST ,
                driverRent , 
                vehicleRent 
            }))
        }
    }, [extendBookingData?.extendDate]);


    const updateBookingHandler = async (e) => {
        e.preventDefault();
        const message='Are you sure? You want to extend this booking?';
        const onYesClick = async () => {
            setBookingLoading(true);
            const { totalPrice , GST , extendDate , vehicleRent } = extendBookingData;
            
            const body = {
                totalPrice , 
                GST , 
                extendDate ,
                vehicleRent
            };
            if(booking.withDriver) {
                body.driverRent = extendBookingData.driverRent;
            }

            try {
                const { data : { data : { order } } } = await Axios.post(`/booking/init-extend-booking/${id}` , body , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
            
                var options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                    amount : extendBookingData?.totalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency : "INR",
                    name: "SAK RENT N DRIVE", //your business name
                    description: "Payment for SAK RENT N DRIVE booking",
                    image: "",
                    order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    handler: async function (response) { 
                        const reqBody = {
                            ...response,
                            ...body
                        };                  
                        try {
                            const { data : { data : { message } } } = await Axios.put(`/booking/extend/${id}` , reqBody , {
                                headers : {
                                    Authorization : `Bearer ${user?.token}`
                                }
                            });
                            toast.success(message);
                            navigate(`/bookings/booking-details/${id}`);
                            setBookingLoading(false);
                        } catch (error) {
                            setBookingLoading(false);
                            toastError(error);
                        }    
                    },
                    prefill: {
                        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                        name: user?.fullName, //your customer's name
                        email: user?.email,
                        contact: user?.phone, //Provide the customer's phone number for better conversion rates
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };
            
                const rzp1 = new Razorpay(options);
                rzp1.on("payment.failed", function (response) {
                    toast.error(response.error.reason);
                });
                rzp1.open();
            } catch (error) {
                setBookingLoading(false);
                toastError(error);
            }
        }

        confirmBox({ message , onYesClick });
    }


    return (
        <div>
            <div className='container mx-auto py-12 sm:px-0 px-4'>
                <div className='flex items-center justify-between gap-4 border-b-2 pb-2 border-secondary'>
                    <h1 className="section-heading text-gradient">
                        Extend Booking 
                    </h1>
                    <BackBtn />
                </div>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    booking 
                    ? 
                        <div className='mt-12 flex gap-6 md:flex-row flex-col'>
                            <div className='flex-[0.7] shadow-bg p-4 flex flex-col justify-between'>
                                <div>
                                    <CarInfo 
                                    carDetails={booking?.car} 
                                    />
                                </div>
                            </div>
                            <div className='flex-[0.3] shadow-bg p-3 h-fit flex flex-col gap-4'>
                                <div className='flex flex-col gap-2'>
                                    <div className="flex flex-col gap-1.5">
                                        <label className='text-primary font-semibold'>Pickup Date</label>
                                        <DatePicker
                                        selected={new Date(booking?.pickupDate)}
                                        showTimeSelect
                                        value={new Date(booking?.pickupDate)}                            
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        disabled
                                        readOnly
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1.5">
                                        <label className='text-primary font-semibold'>Return Date</label>
                                        <DatePicker
                                        selected={new Date(booking?.returnDate)}
                                        showTimeSelect
                                        value={new Date(booking?.returnDate)}                            
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        disabled
                                        readOnly
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <ExtendDatePicker
                                        booking={booking}
                                        extendBookingData={extendBookingData}
                                        setExtendBookingData={setExtendBookingData}
                                        settings={settings}
                                        futureBookings={futureBookings?.filter(item => item?._id !== booking?._id)}
                                        />
                                    </div>
                                </div>
                               
                                <p className='text-sm -mt-2 text-red-500'>Minimum Extend Booking duration Allowed {settings?.minRentalDuration} hours</p>

                                {
                                    booking?.withDriver && 
                                    <div className='flex items-center gap-2 text-sm'>
                                        <input 
                                        type="checkbox" 
                                        checked={booking?.withDriver}
                                        />
                                        <span>With driver booking</span>
                                    </div>
                                }

                                <div className='flex justify-between items-center'>
                                    <h6 className='font-medium'>
                                        Extended Duration
                                    </h6>
                                    <div className='flex gap-2'>
                                        <span>
                                            {extendBookingData?.duration?.days}
                                            {
                                                extendBookingData?.duration?.days > 1 ? ' days' : ' day'
                                            }    
                                        </span>
                                        <span>
                                            {extendBookingData?.duration?.hours}
                                            {
                                                extendBookingData?.duration?.hours > 1 ? ' hours' : ' hour'
                                            }    
                                        </span>
                                        <span>
                                            {extendBookingData?.duration?.minutes}
                                            {
                                                extendBookingData?.duration?.minutes > 1 ? ' mins' : ' min'
                                            }    
                                        </span> 
                                    </div>
                                </div>
                                
                                <div className='flex justify-between items-center'>
                                    <h6 className='font-medium'>
                                        Total Hours : 
                                    </h6>
                                    <p>
                                        {extendBookingData?.duration?.totalHours} hrs
                                    </p>
                                </div>

                                <div className='flex justify-between items-center mt-4'>
                                    <h6 className='font-medium'>
                                        Vehicle Rent : 
                                    </h6>
                                    <p className='text-lg font-semibold'>
                                        ₹ &nbsp; 
                                        {formatAmount(extendBookingData?.vehicleRent)}
                                    </p>
                                </div>
                                {
                                    booking?.withDriver && 
                                    <div className='flex justify-between items-center'>
                                        <h6 className='font-medium'>
                                            Driver Fee : 
                                        </h6>
                                        <p className='text-lg font-semibold'>
                                            ₹ &nbsp; 
                                            {formatAmount(extendBookingData?.driverRent)}
                                        </p>
                                    </div>
                                }
                                <div className='flex justify-between items-center'>
                                    <h6 className='font-medium'>
                                        GST ({settings?.GST}%): 
                                    </h6>
                                    <p className='text-lg font-semibold'>
                                        ₹ &nbsp; 
                                        {extendBookingData?.GST}
                                    </p>
                                </div>
                                
                                <div className='flex justify-between items-center text-lg'>
                                    <h6 className='font-semibold'>
                                        Grand Total : 
                                    </h6>
                                    <p className='text-lg font-bold'>
                                        ₹ &nbsp; 
                                        {formatAmount(extendBookingData?.totalPrice)}
                                    </p>
                                </div>
                                <div className='flex items-start gap-2 mt-3'>
                                    <input 
                                    type="checkbox" 
                                    className='mt-[5px]'
                                    id='termsOfService'
                                    onChange={e => {
                                        if(e.target.checked) {
                                            setIsTermsAccepted(true)
                                        }else {
                                            setIsTermsAccepted(false)
                                        }
                                    }}
                                    required
                                    />
                                    <label 
                                    htmlFor='termsOfService'
                                    className='text-sm'
                                    >
                                        By checking this box, you are agreeing to our <Link to="/terms-and-conditions" className='underline text-blue-400'>terms of service</Link>
                                    </label>
                                </div>
                                <div className='mt-8 mb-4 flex items-end justify-end'>
                                    <button 
                                    className="btn-primary py-3 px-4 text-sm"
                                    disabled={!extendBookingData?.extendDate || bookingLoading || !isTermsAccepted}
                                    onClick={updateBookingHandler}
                                    type='submit'
                                    >
                                        {
                                            bookingLoading
                                            ?
                                                <ClipLoader 
                                                size={20} 
                                                color='white' 
                                                />
                                            : 
                                                'Extend Booking'
                                        }
                                    </button>
                                </div> 
                            </div>

                                
                        </div>
                    : 
                        <ItemNotFound message='Booking details not found' />
                }
            </div>
        </div>
    )
}

export default ExtendBooking