import { useAuthPopupContext } from 'context/AuthPopupContext';
import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { resendOtp, verifyOtp } from 'redux/actions/authActions';

const VerifyOtp = () => {
    const dispatch = useDispatch();
    const { setShowAuthPopup , setCurrentStep } = useAuthPopupContext();
    const { loading , resendLoading , user } = useSelector(state => state.auth);
    const [otp, setOtp] = useState('');
    const [disableResend, setDisableResend] = useState(true);
    const [resendTimer, setResendTimer] = useState(60);

    useEffect(() => {
        let timer;
        if (disableResend) {
            timer = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [disableResend]);

    useEffect(() => {
        if (resendTimer === 0) {
            setDisableResend(false);
            setResendTimer(60); // Reset timer to 60 seconds
        }
    }, [resendTimer]);

    const submitHandler = async (e) => {
        e.preventDefault();
        dispatch(verifyOtp(otp , setShowAuthPopup , setCurrentStep))
    }

    const resendHandler = () => {
        dispatch(resendOtp());
        setDisableResend(true);
    }

    return (
        <div>
            <center>
                <h1 className='sm:text-2xl text-xl font-bold'>Verify One Time Password (OTP)</h1>
                <p className='text-gray-500 sm:text-sm text-xs mt-1 sm:px-0 px-12'>One Time Password (OTP) sended to your email</p>
            </center>
            <form className='mt-8' onSubmit={submitHandler}>
                <div className='flex items-center justify-center'>
                    <OtpInput
                    inputType='number'
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} className='h-[40px] rounded-md border outline-none focus:border-primary text-black sm:mr-4 mr-2'/>}
                    inputStyle={{
                        width: 40,
                        height: 40
                    }}
                    />
                </div>
                <center className='mt-8'>
                    <button 
                    className="btn-primary py-2 sm:px-12 px-6" 
                    disabled={loading || resendLoading}
                    >
                        { loading ? <ClipLoader size={20} color='white' /> : 'Confirm' }
                    </button>
                    <div className='flex items-center justify-center gap-1 text-sm text-grayText mt-4'>
                        <span>Did not get OTP? </span>
                        <button 
                        className="text-dark" 
                        disabled={disableResend || resendLoading} onClick={resendHandler}
                        >
                            { resendLoading ? 'Sending...' : disableResend ? `Resend in ${resendTimer} seconds` : 'Resend' }
                        </button>
                    </div>
                </center>
            </form>
        </div>
    );
}

export default VerifyOtp;
