import BlogDetailsCover from 'components/blogs/BlogDetailsCover';
import Loader from 'components/global/Loader';
import { baseURL } from 'config/api';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { blog_getDetails } from 'redux/actions/blogActions';
import { Helmet } from 'react-helmet';
import Breadcrumbs from 'components/global/Breadcrumbs';

const imgDir = '/blogs/' 

const BlogDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { docDetails: blog, loading } = useSelector(state => state.blog);

    console.log({ blog })

    useEffect(() => {
        if (!blog) {
            dispatch(blog_getDetails(id));
        }
    }, [blog, dispatch, id]);

    return (
        <div>
            {
                loading
                    ?
                    <Loader />
                    :
                    <div>
                        <Helmet>
                            {
                                blog?.pageTitle && (<title>{blog?.pageTitle}</title>)
                            }
                            {
                                blog?.metaTags?.length > 0 &&
                                blog?.metaTags?.map((tag, index) => {
                                    switch (tag.type) {
                                        case 'og':
                                            return (
                                                <meta key={index} property={tag?.property} content={tag?.content} />
                                            );
                                        case 'canonical':
                                            return (
                                                <link key={index} rel={tag?.rel} href={tag?.href} />
                                            );
                                        default:
                                            return (
                                                <meta key={index} name={tag?.name} content={tag?.content} />
                                            );
                                    }
                                })
                            }
                        </Helmet>
                        <BlogDetailsCover
                            heading={blog?.title}
                            image={baseURL + imgDir + blog?.image}
                            date={blog?.createdAt}
                        />
                        <div className='container mx-auto sm:px-0 px-4 py-6'>
                            <Breadcrumbs crumbs={[
                                { label: "Home", path: "/" },
                                { label: "Blogs", path: "/blogs" },
                                { label: blog?.title?.slice(0,20) + '...',  },
                            ]} />
                            <div className='md:w-[80%] sm:w-[90%] w-full mx-auto mt-8'>

                                <div
                                    className="blog-content mt-4 text-lg"
                                    dangerouslySetInnerHTML={{ __html: blog?.content }}
                                />
                            </div>
                        </div>
                    </div>

            }
        </div>
    )
}

export default BlogDetails