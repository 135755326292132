import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import BecomeHostBtn from "../BecomeHostBtn";

const Footer = () => {
    const { user } = useSelector(state => state.auth);
    const { settings } = useSelector(state => state.home);

    return (
        <footer className='bg-[#eafff4] py-10 text-white '>
            <div className='container mx-auto sm:px-8 px-4 flex md:flex-row flex-col gap-8 justify-between'>
                <div>
                    <h3 className='text-2xl font-semibold text-gradient'>
                        Rent And Drive
                    </h3>
                    <p className='text-grayText text-sm my-6'>We help you find your dream car</p>
                    <div className='flex items-center gap-4 text-dark'>
                        <a 
                        className='rounded-full border border-dark flex items-center justify-center text-lg w-[40px] h-[40px]  cursor-pointer hover:-translate-y-2 duration-300'
                        href={settings?.facebookLink}
                        target="_blank"
                        >
                            <i className="uil uil-facebook-f"></i>
                        </a>
                        <a 
                        className='rounded-full border border-dark flex items-center justify-center text-lg w-[40px] h-[40px] cursor-pointer hover:-translate-y-2 duration-300'
                        href={settings?.twitterLink}
                        target="_blank"
                        >
                            <i className="uil uil-twitter"></i>
                        </a>
                        <a 
                        className='rounded-full border border-dark flex items-center justify-center text-lg w-[40px] h-[40px] cursor-pointer hover:-translate-y-2 duration-300'
                        href={settings?.instagramLink}
                        target="_blank"
                        >
                            <i className="uil uil-instagram"></i>
                        </a>
                        <a 
                        className='rounded-full border border-dark flex items-center justify-center text-lg w-[40px] h-[40px] cursor-pointer hover:-translate-y-2 duration-300'
                        href={settings?.youtubeLink}
                        target="_blank"
                        >
                            <i className="uil uil-youtube"></i>
                        </a>

                    </div>
                    <div className="mt-12">
                        <BecomeHostBtn />
                    </div>
                </div>
                <div>
                    <div className='flex flex-wrap  gap-12'>
                        <div className='text-sm'>
                            <h6 className='text-black font-semibold'>Information</h6>
                            <div className='flex flex-col gap-4 mt-4 text-grayText'>
                                <Link 
                                to='/about' 
                                className='hover:text-dark cursor-pointer'
                                >
                                    About
                                </Link>
                                <Link 
                                to='/vehicles' 
                                className='hover:text-dark cursor-pointer'
                                >
                                    Vehicles
                                </Link>
                                <Link 
                                to='/contact' 
                                className='hover:text-dark cursor-pointer'
                                >
                                    Contact Us
                                </Link>
                                {
                                    user && 
                                    <>
                                        <Link
                                        to={`/bookings`} 
                                        className='hover:text-dark cursor-pointer'
                                        >
                                            Bookings
                                        </Link>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='text-sm'>
                            <h6 className='text-black font-semibold'>
                                Customer Page
                            </h6>
                            <div className='flex flex-col gap-4 mt-4 text-grayText'>
                                <Link to='/terms-and-conditions' className='hover:text-dark cursor-pointer'>
                                    Terms and conditions
                                </Link>
                                <Link to='/privacy-policy' className='hover:text-dark cursor-pointer'>
                                    Privacy Policy
                                </Link>
                                <Link to='/cancellation-and-refund-policy' className='hover:text-dark cursor-pointer'>
                                    Cancellation and Refund Policy
                                </Link>
                                <Link to='/vendor-policy' className='hover:text-dark cursor-pointer'>
                                    Vendor Policy    
                                </Link>
                                <Link to='/shipping-and-delivery' className='hover:text-dark cursor-pointer'>
                                    Shipping and delivery   
                                </Link>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className='container mx-auto mt-20 sm:px-8  px-4 flex items-center justify-between sm:flex-row flex-col-reverse gap-6'>
                <p className=' text-dark text-sm sm:text-left text-center'>
                    2024 all Right Reserved Term of use RENT-N-DRIVE
                </p>
            </div>
        </footer>
    )
}

export default Footer