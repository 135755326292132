import ReactStars from "react-rating-stars-component";
import React, { memo } from "react";

const RatingStars = ({ setData , count = 5 }) => {
    const ratingChanged = (newRating) => {
        setData(prev => ({...prev , 'rating' : newRating }));
    };

    return (
        <ReactStars
        count={count}
        onChange={ratingChanged}
        size={34}
        isHalf={true}
        activeColor="#ffd700"
        />
    )
}

export default memo(RatingStars)
