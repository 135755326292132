import { Swiper, SwiperSlide } from 'swiper/react';
import CarImg from 'assets/images/smallCar.png';
import './styles.css';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import { baseURL } from 'config/api';
import { Link } from 'react-router-dom';


const PopularCars = ({ isLoading }) => {
    const { popularCars } = useSelector(state => state.home);

    return (
        <div className='container mx-auto'>
            <div className='text-center'>
                <h2 className='section-heading'>
                    Our <span className='text-gradient'>Popular Rental</span> Car
                </h2>
            </div>
            {
                isLoading
                ? 
                    <Loader />
                : 
                popularCars?.length > 0 
                ? 
                    <div className='mt-12 px-4'>
                        <Swiper
                            navigation={true}
                            slidesPerView={3}
                            spaceBetween={30}
                            pagination={{
                            clickable: true,
                            }}
                            modules={[Pagination , Navigation]}
                            className="popular-cars-swiper"
                            breakpoints={{
                                950 : {
                                    slidesPerView : 3
                                } ,
                                500 : {
                                    slidesPerView : 2
                                } ,
                                200 : {
                                    slidesPerView : 1
                                }
                            }}
                        >
                            {
                                popularCars?.map((item , i) => (
                                    <SwiperSlide key={item?._id}>
                                        <div className='border-gradient py-4 px-4'>
                                            <LazyLoad 
                                            height={200} 
                                            className='flex items-center justify-center'
                                            >
                                                <img 
                                                src={baseURL + '/cars/' + (item?.images[0] || item?.images[1])} 
                                                alt="car"
                                                className='sm:w-[80%] w-full h-[140px]  object-contain' 
                                                />
                                            </LazyLoad>
                                            <div className="flex justify-between">
                                                <div className='flex flex-col gap-2 mt-4 '>
                                                    <h6 className='font-semibold text-sm'>{item?.name}</h6>
                                                    <div className='flex items-center gap-2 text-xs font-semibold'>
                                                        <i className="uil uil-user text-lg"></i>
                                                        <p>
                                                            {`${item?.make} , ${item?.model} , ${item?.transmissionType}`}
                                                        </p>
                                                    </div>
                                                    <p className=' font-medium'>Rent Per Hour : ₹ {item?.rentPerHour}</p>
                                                </div>
                                                <div className='flex items-end justify-end'>
                                                <Link 
                                                to={`/bookings/book-now/${item?._id}`} 
                                                className='btn-primary py-2 px-4 text-sm relative z-50'
                                                >
                                                    Book Now
                                                </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                : 
                    <ItemNotFound message='No popular car found.' />
            }
        </div>
    )
}

export default PopularCars